import { useQuery } from '@tanstack/react-query';


function useReactQuery(cacheName, queryFn, staleTime = Infinity) {
  const response = useQuery({
    queryKey: [cacheName],
    queryFn: queryFn,
    staleTime,
  })
  return response
}

export default useReactQuery
import { useQuery } from "@tanstack/react-query";

import { usePatient } from "../context/PatientContext";

const useLiveVitalQuery = (cacheName, queryFn) => {
  const { patient } = usePatient();

  const response = useQuery({
    queryKey: [cacheName, patient?.id],
    queryFn: () => queryFn(patient?.id),
    enabled: !!patient?.id,
    refetchOnWindowFocus: false,
    // Issue 0003 - More config of react query
  });

  return response;
};

export default useLiveVitalQuery;
import ApiReturnJson from "../api/ApiReturnJson";
import * as EP from "./Api";
import { signOutUser } from "./ApiService";



// Line Graphs ============================================

export const fetchSleepGraphData = async (recipientId, fromDate, toDate) =>
  fetchGraphDataFromApi(EP.fetchSleepGraphDataEndpoint, recipientId, fromDate, toDate);

export const fetchBloodPressureGraphData = async (recipientId, fromDate, toDate) =>
  fetchGraphDataFromApi(EP.fetchBloodPressureGraphDataEndpoint, recipientId, fromDate, toDate);

export const fetchOxygenGraphData = async (recipientId, fromDate, toDate) =>
  fetchGraphDataFromApi(EP.fetchOxygenGraphDataEndpoint, recipientId, fromDate, toDate);

export const fetchTemperatureGraphData = async (recipientId, fromDate, toDate) =>
  fetchGraphDataFromApi(EP.fetchTemperatureGraphDataEndpoint, recipientId, fromDate, toDate);


// Bar/Range-Bar Graphs ===================================

export const fetchHeartrateGraphData = async (recipientId, fromDate, toDate) =>
  fetchGraphDataFromApi(EP.fetchHeartrateGraphDataEndpoint, recipientId, fromDate, toDate);

export const fetchRespiratoryRateGraphData = async (recipientId, fromDate, toDate) =>
  fetchGraphDataFromApi(EP.fetchRespiratoryRateGraphDataEndpoint, recipientId, fromDate, toDate);

export const fetchGlucoseGraphData = async (recipientId, fromDate, toDate) =>
  fetchGraphDataFromApi(EP.fetchGlucoseGraphDataEndpoint, recipientId, fromDate, toDate);

export const fetchWeigthGraphData = async (recipientId, fromDate, toDate) =>
  fetchGraphDataFromApi(EP.fetchWeigthGraphDataEndpoint, recipientId, fromDate, toDate);


// Warning!! This function is getting used in this file.
async function fetchGraphDataFromApi(endpoint, recipientId, formDate, toDate) {
  try {
    const [response] = await Promise.all([
      ApiReturnJson("GET", endpoint + `?id=${recipientId}&fromDate=${formDate}&toDate=${toDate}`),
    ]);

    if (response.isSuccess) {
      return response.data;
    } else {
      console.error(response);
      return Promise.reject(response);
    }

  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      signOutUser();
    }
  }
}
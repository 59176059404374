import React from "react";
import { Link } from "react-router-dom";
import { usePatient } from "../context/PatientContext";
import { Avatar, AvatarGroup } from "@mui/material";
import { ImageBaseUrl } from "../HTTPS/Api";
import { useEffect } from "react";
import { fetchPatientList } from "../HTTPS/ApiService";
import PatientName from "../components/PatientName";
import { stringAvatar } from "../hooks/convertAvatarInitital";
import MessengerService from "../servies/MessengerService";
import MainAreaWrapper from "../layout/MainAreaWrapper";


const PatientProfile = () => {
  const {
    patient,
    patientList,
    setPatient,
    setPatientList,
  } = usePatient();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const list = await fetchPatientList();
        if (list && list.length > 0) {
          const storedPatient = localStorage.getItem("selectedPatient");
          if (storedPatient) {
            setPatient(JSON.parse(storedPatient));
          } else {
            MessengerService.setSelectedPatient(list[0].id);
            setPatient(list[0]);
            localStorage.setItem("selectedPatient", JSON.stringify(list[0]));
            setPatientList(list);
          }
        }
        setPatientList(list);
      } catch (error) {
        console.error("Error fetching patient details:", error);
      }
    };

    fetchData();
    // patientSelectHandler();
  }, [setPatient, setPatientList, patient?.id]);

  const currentIndex = patientList.findIndex((item) => item.id === patient.id);

  // Separate the current patient from the rest
  const currentPatient = currentIndex !== -1 ? patientList[currentIndex] : null;
  const otherPatients = patientList.filter((item) => item.id !== patient.id);
  return (
    <MainAreaWrapper>
      <main className="mt-28 lg:mt-10 h-full">
        <div className="h-full rounded-t-3xl bg-white">
          <div className="mx-auto p-4 md:p-6 2xl:p-10">
            <div className="mb-6 flex flex-col gap-3 sm:flex-row sm:justify-between sm:items-center">
              <div className="">
                <h2 className="text-3xl font-bold text-[#1D3E7D]">
                  Profile
                </h2>
                {/* <p className="text-gray-600">Manage your information</p> */}
              </div>

              <div>
                <Link
                  to="/editPatientProfile"
                  className="rounded-lg bg-[#ED7117] border border-black px-4 py-1.5 text-xl text-white"
                >
                  Edit
                </Link>
              </div>
            </div>

            {/* <!-- Content  --> */}
            <div className="">
              <h2 className="font-bold text-xl text-[#1D3E7D]">
                Your care recepients
              </h2>

              <div className="flex items-center gap-3">
                <AvatarGroup max={10} className="my-3">
                  {currentPatient && (
                    <Avatar
                      key={currentPatient.id}
                      {...stringAvatar(
                        `${currentPatient.fname} ${currentPatient.lname}`
                      )}
                      className="!text-base uppercase"
                      src={
                        currentPatient.profileImage
                          ? `${ImageBaseUrl}/${currentPatient.profileImage}`
                          : ""
                      }
                    />
                  )}

                  {/* Render the rest of the patients */}
                  {otherPatients.map((item) => (
                    <Avatar
                      key={item.id}
                      {...stringAvatar(`${item.fname} ${item.lname}`)}
                      className="!text-base uppercase"
                      src={
                        item.profileImage
                          ? `${ImageBaseUrl}/${item.profileImage}`
                          : ""
                      }
                    />
                  ))}
                </AvatarGroup>
              </div>
            </div>

            {/* <!-- Details  --> */}
            <div className="pt-5">
              <h1 className="font-bold text-[#4A4A68]">Name</h1>
              <p className="" id="Profile_name">
                <PatientName patient={patient} />
              </p>
            </div>

            <div className="pt-5">
              <h1 className="font-bold text-[#4A4A68]">Email</h1>
              <p className="" id="Profile_mail">
                {patient?.email || "NA"}
              </p>
            </div>

            <div className="pt-5">
              <h1 className="font-bold text-[#4A4A68]">Phone</h1>
              <p className="" id="Profile_number">
                {patient?.phone || "NA"}
              </p>
            </div>

            {/* <div className="pt-5">
                  <h1 className="font-bold text-[#4A4A68]">Gender</h1>
                  <p className="" id="Profile_gender">
                    {patient?.gender}
                  </p>
                </div>

                <div className="pt-5">
                  <h1 className="font-bold text-[#4A4A68]">Address 1</h1>
                  <p className="" id="Profile_location">
                    {patient?.address1 || "NA"}
                  </p>
                </div>

                <div className="pt-5">
                  <h1 className="font-bold text-[#4A4A68]">Address 2</h1>
                  <p className="" id="Profile_location">
                    {patient?.address2 || "NA"}
                  </p>
                </div>

                <div className="pt-5">
                  <h1 className="font-bold text-[#4A4A68]">City</h1>
                  <p className="" id="Profile_location">
                    {patient?.city || "NA"}
                  </p>
                </div>

                <div className="pt-5">
                  <h1 className="font-bold text-[#4A4A68]">State</h1>
                  <p className="" id="Profile_location">
                    {patient?.state || "NA"}
                  </p>
                </div>

                <div className="pt-5">
                  <h1 className="font-bold text-[#4A4A68]">Zip Code</h1>
                  <p className="" id="Profile_location">
                    {patient?.zipcode || "NA"}
                  </p>
                </div>

                <div className="pt-5">
                  <h1 className="font-bold text-[#4A4A68]">Country</h1>
                  <p className="" id="Profile_location">
                    {patient?.country || "NA"}
                  </p>
                </div>

                <div className="pt-5">
                  <h1 className="font-bold text-[#4A4A68]">Notes</h1>
                  <p className="" id="Profile_location">
                    {patient?.notes || "NA"}
                  </p>
                </div>

                <div className="pt-5">
                  <h1 className="font-bold text-[#4A4A68]">Age</h1>
                  <p className="" id="Profile_age">
                    {patient?.age}
                  </p>
                </div> */}
          </div>
        </div>
      </main>
      {/* <!-- ===== Main Content End ===== --> */}
    </MainAreaWrapper >
  );
};
export default PatientProfile;

import React from "react";
import { useRecoilValue } from "recoil";
import { lineChartDataState } from "../../recoil/vitalTables/lineChartDataState";
import moment from "moment";
function SleepChartTable() {
  const formattedChartData = useRecoilValue(lineChartDataState);

  return (
    <div>
      <div className="bg-white py-2.5 px-3 mb-2 rounded-lg flex justify-between items-center w-72 xs:w-96 mx-auto gap-x-10 xl:gap-y-0 flex-wrap">
        {formattedChartData.map((item, i) => {
          return (
            <div className="w-full flex justify-between items-center border rounded p-2 my-1">
              <div className="text-customBlue w-40 text-xs xs:text-base">
                {moment(item[0]).format("DD MMM, YYYY")}
              </div>
              <div className="flex gap-x-2 text-xs xs:text-base">
                <span className="font-bold text-customBlue">{item[1]}</span>
                <span className="font-bold text-customBlue">{item[2]}</span>
                <span className="font-bold text-customBlue">{item[3]}</span>
                <span className="font-bold text-customBlue">{item[4]}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SleepChartTable;

import React, { useEffect } from 'react'
import { DateCalendar } from '@mui/x-date-pickers'
import moment from 'moment'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { endDateWithTimeSelector, startDateState, startDateWithTimeSelector } from '../../../recoil/report/dateRangeState'

function DayPicker() {
  const startDate = useRecoilValue(startDateState);
  const setStartDateTime = useSetRecoilState(startDateWithTimeSelector)
  const setEndDateTime = useSetRecoilState(endDateWithTimeSelector)

  useEffect(() => {
    setStartDateTime(startDate)
    setEndDateTime(startDate)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DateCalendar views={["day"]}
      value={moment(startDate)}
      onChange={val => {
        setStartDateTime(val.format("YYYY-MM-DD"))
        setEndDateTime(val.format("YYYY-MM-DD"))
      }}
    />
  )
}

export default DayPicker
import React from 'react';
import ReactApexChart from "react-apexcharts";

import { extractChartItemsWithMatchingEventDatesForRangeBarGraphs } from '../../utils/chartUtils';
import { aggregateChartDataInBarGraphFormat, localisingTimestamp } from '../../utils/reuseableFunc';
import { fetchRespiratoryRateGraphData } from '../../HTTPS/GraphApis';
import { rangeBarChartDataState } from '../../recoil/vitalTables/rangeBarChartDataState';
import { useSetRecoilState } from 'recoil';
import useVitalChartQuery from '../../hooks/useVitalChartQuery';
import { usePatient } from '../../context/PatientContext';


function BreathRateChart({ events }) {
  const numberOfDaysToFetch = 35;
  const numberOfDaysToShow = 7;
  const { patient } = usePatient();

  const id = "breath-rate";
  const chartName = patient.fname + "_" + id
  
  const setChartData = useSetRecoilState(rangeBarChartDataState);

  // calling chart data
  const { data: chartData, isFetched } = useVitalChartQuery(
    "respiratoryRateGraph",
    fetchRespiratoryRateGraphData,
    numberOfDaysToFetch
  );

  // Check if chartData is available before rendering the chart
  if (!isFetched || !(chartData?.length > 0)) {
    return <div className="w-full p-1 bg-black/40 rounded-lg"></div>;
  }
  
  const localizedChartData = localisingTimestamp(chartData)

  const originalCopy = aggregateChartDataInBarGraphFormat(localizedChartData, numberOfDaysToShow)
  setChartData(originalCopy)

  // Create a deep copy of the original data to ensure it is not referentially equal in memory
  // apexChart is modifying the object which is causing issue with recoil
  const formattedChartData = JSON.parse(JSON.stringify(originalCopy)); 

  return (
    <>
      <div className="w-full mt-10 border-8 border-black/40 rounded-lg">
        <ReactApexChart
          type="rangeBar"
          series={[
            {
              name: 'breathRate',
              type: 'rangeBar',
              data: formattedChartData,
            },
            {
              name: 'Activities',
              type: 'scatter',
              data: extractChartItemsWithMatchingEventDatesForRangeBarGraphs(events, formattedChartData),
            },
          ]}
          width="100%"
          height="380"
          options={{
            chart: {
              id,
              parentHeightOffset: 0,
              toolbar: {
                show: true,
                export: {
                csv: {
                  filename: chartName,
                  headerCategory: "Date",
                },
                svg: {
                  filename: chartName
                },
                png: {
                  filename: chartName
                }
              }
              },
              foreColor: "#FAFCFE", // color of the legend's text
              background: 'rgba(0, 0, 0, 0.4)',
            },
            colors: ["#00FFC2"],  // color of the bars
            dataLabels: {
              enabled: false,
            },
            markers: { // this is for dot on the graph
              size: 8,
              strokeWidth: 0,
              colors: '#ED7117ff', // adding alpha values to disable default opacity
            },
            tooltip: { // tooltip setting when we hover over the charts dots
              enabled: false,
            },
            legend: { // this is for name of the graphs at the buttom
              show: false
            },
            fill: {
              opacity: 1, // opacity of chart bars
            },
            grid: { // bg lines of the graph
              borderColor: "rgba(236, 241, 244, 0.4)",
            },
            plotOptions: {
              bar: {
                columnWidth: "70%",
                horizontal: false,
                borderRadius: "4",
              },
            },
            xaxis: {
              type: "category",
              axisTicks: { show: false },
              labels: {
                offsetY: -1,
                style: {
                  colors: "#FAFCFE",
                  fontFamily: "Poppins, Helvetica, Arial, sans-serif",
                  fontWeight: 500,
                },
              },
            },
            yaxis: {
              type: "numeric",
              labels: {
                style: {
                  colors: "#FAFCFE",
                  fontFamily: "Poppins, Helvetica, Arial, sans-serif",
                  fontWeight: 500,
                },
              },
            },
          }}
        />
      </div>
    </>
  );
}

export default BreathRateChart;
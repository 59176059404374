import { v4 as uuidv4 } from 'uuid';

export const buildBloodPressureVitalPayload = (user_id, kare_giver_id, systolic, diastolic, timestamp = new Date(), timezone_offset = null, id = uuidv4(), type = "created") => ({
  data: {
    data: [
      {
        systolic,
        diastolic,
        timestamp,
        timezone_offset,
        unit: "mmHg",
        id,
        [type === "created" ? "createdBy" : "modifiedBy"]: kare_giver_id
      }
    ],
    provider: {
      name: "Manual",
    },
    user_id,
  },
  event_type: `daily.data.blood_pressure.${type}`
});


export const buildTemperatureVitalPayload = (user_id, kare_giver_id, temperature, date = new Date(), timezone_offset = null, id = uuidv4(), type = "created") => ({
  data: {
    date,
    timezone_offset,
    id,
    source: {
      name: "Manual",
    },
    temperature,
    user_id,
    [type === "created" ? "createdBy" : "modifiedBy"]: kare_giver_id
  },
  event_type: `daily.data.body.${type}`
});

export const buildWeigthVitalPayload = (user_id, kare_giver_id, weigth, date = new Date(), timezone_offset = null, id = uuidv4(), type = "created") => ({
  data: {
    date,
    timezone_offset,
    id,
    source: {
      name: "Manual",
    },
    weight: parseFloat(weigth),
    user_id,
    [type === "created" ? "createdBy" : "modifiedBy"]: kare_giver_id
  },
  event_type: `daily.data.body.${type}`
});
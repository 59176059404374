import Ably from "ably/promises";
import {
  getCommunityChannel,
  getMessages,
  getUnreadCountGroupMessages,
  readAllGroupMessages,
  sendMessage,
} from "../api/messaging-api";
import { CachedUserDetails } from "../utils/UserData";
import { fetchCommunity } from "../HTTPS/ApiService";

const client = new Ably.Realtime(
  "132tjw.wBSWFA:GZRtApalfRgemPiY55x-cBf-jhnSKI1H3MvkG_eQkmw"
);

class Messenger {
  currentChannel;
  selectedPatientId;
  channelName;
  channels = [];
  messages = [];
  constructor() {
    this.fetchPatientFromStorage();
    this.getGroupMesageCountRevamp();
    // this.getGroupMesageCount();
    this.initIndicator();
  }

  async readAllGroupMessagesUpdate() {
    const patient = JSON.parse(localStorage.getItem("selectedPatient"));
    if (patient == null) return;
    const channel = await getCommunityChannel(patient.id);
    await readAllGroupMessages(channel.channelName, CachedUserDetails()?.id);
    // this.toggleGroupIndicator(false);
  }

  toggleGroupIndicator(show) {
    // let ind1 = document.getElementById("msg-ind-1");
    // let ind2 = document.getElementById("msg-ind-2");
    // [ind1, ind2].forEach((ele) => {
    //   try {
    //     ele?.classList.remove(show ? "hide" : "show");
    //   } catch (e) { }
    //   try {
    //     ele?.classList.add(show ? "show" : "hide");
    //   } catch (e) { }
    // });
  }

  async getGroupMesageCountRevamp() {
    const patient = JSON.parse(localStorage.getItem("selectedPatient"));
    if (patient == null) return;
    const channel = await getCommunityChannel(patient.id);
    let unreadCount = await getUnreadCountGroupMessages(channel.channelName, CachedUserDetails()?.id);
    return unreadCount;
  }

  async getGroupMesageCount() {
    // if (window.location.hash === '#/message') {
    //   this.readAllGroupMessagesUpdate();
    // }
    // const patient = JSON.parse(localStorage.getItem("selectedPatient"));
    // if (patient == null) return;
    // const channel = await getCommunityChannel(patient.id);

    // let unreadCount = await getUnreadCountGroupMessages(channel.channelName, CachedUserDetails()?.id);
    // if (unreadCount?.count) {
      // this.toggleGroupIndicator(true);
    // } else {
      // this.toggleGroupIndicator(false);
    // }
  }

  async initIndicator() {
    // if (window.location.hash === '#/message') {
    //   this.readAllGroupMessagesUpdate();
    //   setTimeout(() => {
    //     this.initIndicator();
    //   }, 300000);
    //   return;
    // }
    // this.getGroupMesageCount();

    // setTimeout(() => {
    //   this.initIndicator();
    // }, 300000);
  }

  async fetchPatientFromStorage(isCommunityMessage) {
    localStorage.setItem("subscribedChannel", "");
    if (
      localStorage.getItem("privateMessage") === "true" &&
      window.location.hash.includes("#/privatemessage") &&
      !isCommunityMessage
    ) {
      this.setChannelName(localStorage.getItem("privateMessageChannelName"));
    } else {
      const patient = JSON.parse(localStorage.getItem("selectedPatient"));
      if (patient == null) return;
      this.setSelectedPatient(patient?.id);
    }
  }

  async setSelectedPatient(patientId) {
    if (patientId) {
      this.selectedPatientId = patientId;
      const channel = await getCommunityChannel(this.selectedPatientId);
      this.channelName = channel.channelName;
      localStorage.setItem("privateMessage", "false");

      //refresh patient's community 
      const patientCommunity = await fetchCommunity(this.selectedPatientId); 
      localStorage.setItem("communityMembers", JSON.stringify(patientCommunity));

      this.init();
    }
  }

  setChannelName(name) {
    this.channelName = name;
    localStorage.setItem("privateMessage", "true");
    localStorage.setItem("privateMessageChannelName", name);
    this.init();
  }

  setCommunityChannel() {

  }

  async init() {
    let subscribedChannel = localStorage.getItem("subscribedChannel");
    if (subscribedChannel === this.channelName) return;
    await this.dispose();
    this.currentChannel = client.channels.get(this.channelName);
    localStorage.setItem("subscribedChannel", this.channelName);
    this.messages = [];
    const pastMessages = await getMessages(this.channelName);
    pastMessages.forEach((message) => {
      this.messages.push({
        text: message.message,
        name: message.name,
        profileImage: "",
        id: message.senderId,
        time: message.createdDate,
      });
    });

    await this.currentChannel.subscribe((message) => {
      this.messages.push(message.data);
      if (
        message.name === "newSubscription" &&
        message.data.receiver === "myId"
      ) {
        this.subscribe(message.data.channelName);
        this.channels.push({
          channelName: message.data.channelName,
          messages: [],
        });
      }
    });
  }

  update() { }

  async subscribe(channelName) {
    let channel = client.channels.get(channelName);
    this.channels.push(channel);
    await channel.subscribe((message) => {
      let messagingChannelName = channelName;
      let messagingChannel = this.channels.filter(
        (ch) => ch.channelName === messagingChannelName
      );
      messagingChannel.messages.push(message);
    });
  }

  async dispose() {
    try {
      this.currentChannel.unsubscribe();
    } catch (e) { }
  }

  sendCommunityAlert(channelName, senderId, receiverId) {
    this.currentChannel.publish({
      name: "newSubscription",
      date: {
        sender: senderId,
        receiver: receiverId,
        channelName: channelName,
      },
    });
  }

  async sendMessage(id, message) {
    let recepientId;
    if (window.location.hash.includes("#/privatemessage")) {
      recepientId = localStorage.getItem("recepientId");
    }
    await sendMessage(message, this.channelName, null, recepientId, id, []);

    this.currentChannel.publish({
      name: "myEventName",
      data: { id: id, text: message, time: new Date() },
    });
  }
}

const MessengerService = new Messenger();

export default MessengerService;

// import React, { useEffect, useState } from "react";
// import Ably from "ably/promises";
// import messagingApiService from "./MessagingApiService";
// import {
//   getCommunityChannel,
//   getMessages,
//   sendMessage,
// } from "../api/messaging-api";

// const client = new Ably.Realtime(
//   "132tjw.wBSWFA:GZRtApalfRgemPiY55x-cBf-jhnSKI1H3MvkG_eQkmw"
// );

// const Messenger = () => {
//   const [currentChannel, setCurrentChannel] = useState(null);
//   const [selectedPatientId, setSelectedPatientId] = useState(null);
//   const [channelName, setChannelName] = useState(null);
//   const [channels, setChannels] = useState([]);
//   const [messages, setMessages] = useState([]);

//   useEffect(() => {
//     fetchPatientFromStorage();
//   }, []);

//   const fetchPatientFromStorage = async (isCommunityMessage) => {
//     localStorage.setItem("subscribedChannel", "");

//     if (
//       localStorage.getItem("privateMessage") === "true" &&
//       window.location.hash === "#/privatemessage" &&
//       !isCommunityMessage
//     ) {
//       setChannelName(localStorage.getItem("privateMessageChannelName"));
//     } else {
//       const patient = JSON.parse(localStorage.getItem("selectedPatient"));
//       setSelectedPatientId(patient?.id);
//     }
//   };

//   const setSelectedPatient = async (patientId) => {
//     if (patientId) {
//       setSelectedPatientId(patientId);
//       const channel = await getCommunityChannel(selectedPatientId);
//       setChannelName(channel.channelName);
//       localStorage.setItem("privateMessage", false);
//       init();
//     }
//   };

//   const init = async () => {
//     let subscribedChannel = localStorage.getItem("subscribedChannel");
//     if (subscribedChannel === channelName) return;
//     await dispose();
//     setCurrentChannel(client.channels.get(channelName));
//     localStorage.setItem("subscribedChannel", channelName);
//     setMessages([]);

//     const pastMessages = await getMessages(channelName);
//     const updatedMessages = pastMessages.map((message) => ({
//       text: message.message,
//       name: message.name,
//       profileImage: "",
//       id: message.senderId,
//       time: message.createdDate,
//     }));

//     setMessages(updatedMessages);

//     await currentChannel.subscribe((message) => {
//       setMessages((prevMessages) => [...prevMessages, message.data]);

//       if (
//         message.name === "newSubscription" &&
//         message.data.receiver === "myId"
//       ) {
//         subscribe(message.data.channelName);
//         setChannels((prevChannels) => [
//           ...prevChannels,
//           { channelName: message.data.channelName, messages: [] },
//         ]);
//       }
//     });
//   };

//   const subscribe = async (channelName) => {
//     let channel = client.channels.get(channelName);
//     setChannels((prevChannels) => [...prevChannels, channel]);

//     await channel.subscribe((message) => {
//       setChannels((prevChannels) =>
//         prevChannels.map((ch) =>
//           ch.channelName === channelName
//             ? { ...ch, messages: [...ch.messages, message] }
//             : ch
//         )
//       );
//     });
//   };

//   const createNewChannel = (receiverId) => {
//     let name = `channel-${"senderId"}-${receiverId}`;
//     messagingApiService.createChannel(name, "senderId", receiverId);
//     subscribe(name);
//     sendCommunityAlert(name, "senderId", receiverId);
//     setChannels((prevChannels) => [
//       ...prevChannels,
//       { channelName: name, messages: [] },
//     ]);
//   };

//   const dispose = async () => {
//     try {
//       currentChannel.unsubscribe();
//     } catch (e) {}
//   };

//   const sendCommunityAlert = (channelName, senderId, receiverId) => {
//     currentChannel.publish({
//       name: "newSubscription",
//       date: {
//         sender: senderId,
//         receiver: receiverId,
//         channelName: channelName,
//       },
//     });
//   };

//   const sendMessage = async (name, id, message) => {
//     await sendMessage(name, message, channelName, null, null, id);

//     currentChannel.publish({
//       name: "myEventName",
//       data: { id: id, name: name, text: message, time: new Date() },
//     });
//   };

//   return {
//     sendMessage,
//     sendCommunityAlert,
//     createNewChannel,
//     setSelectedPatient,
//   }; // Replace this with your actual JSX if needed
// };

// export default Messenger;

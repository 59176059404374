import React from "react";
import DashboardCards from "../components/Blocks/DashboardCards";
import { usePatient } from "../context/PatientContext";
import MainAreaWrapper from "../layout/MainAreaWrapper";

const HealthData = () => {
  const { patient } = usePatient();

  return (
    <MainAreaWrapper>
      <main className="mt-28 lg:mt-10 bg-gradient-to-b from-[#FAFCFEBF]/70 via-[#FAFCFEBF]/50 to-transparent rounded-t-3xl">
        {/* <Breadcrumb /> */}
        <div className="mx-auto p-2 md:p-6 2xl:p-10">
          {/* <!-- ===== Content Area Start ===== --> */}
          <div className="mb-6 px-2 py-3">
            {/* <UserProfileMenuBar /> */}

            <div className="">
              <span className="text-[#1D3E7D] text-4xl font-bold">
                Health Data
              </span>
            </div>
          </div>

          <div className="flex flex-wrap items-center justify-center gap-5 sm:justify-start">
            {/* <!-- <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5"> --> */}

            <div className="flex flex-wrap justify-center md:justify-start gap-5">
              {patient?.id ? <DashboardCards /> : "loading..."}
            </div>
          </div>
        </div>
      </main>
    </MainAreaWrapper >
  );
};

export default HealthData;

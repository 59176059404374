import { useRecoilValue, useResetRecoilState } from "recoil";
import NextButton from "./NextButton";
import PrevButton from "./PrevButton";
import useStep from "../../../hooks/useStep";
import { stepState } from "../../../recoil/report/stepState";
import { endDateState, startDateState } from "../../../recoil/report/dateRangeState";
import { activeButtonState } from "../../../recoil/report/activeButtonState";
import { checkListState } from "../../../recoil/report/checkListState";
import { reportDataState } from "../../../recoil/report/reportDataState";

const NavigationButtons = () => {
  const { nextStep, prevStep, goToStep } = useStep();

  // Define states to be reset
  const statesToResetOnExit = [startDateState, endDateState, activeButtonState, checkListState, reportDataState]
    .map(useResetRecoilState);

  const statesToResetOnStepOne = [startDateState, endDateState, activeButtonState, reportDataState]
    .map(useResetRecoilState);

  const resetMultipleStates = (resetFunctions) => resetFunctions.forEach(fn => fn());

  const currentStep = useRecoilValue(stepState);

  return (
    <div className="flex justify-between mt-6">
      {currentStep > 1 && <PrevButton prevStep={() => {
        if (currentStep === 2) {
          resetMultipleStates(statesToResetOnStepOne);
        }
        prevStep();
      }} />}
      {currentStep < 3 && (
        <NextButton
          title={currentStep === 2 && "View Report"}
          nextStep={nextStep}
        />
      )}
      {currentStep === 3 && <PrevButton prevStep={() => {
        goToStep(1);
        resetMultipleStates(statesToResetOnExit);
      }} text="Exit" />}
    </div>
  );
};

export default NavigationButtons;

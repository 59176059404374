import axios from "axios";
import { CachedUser } from "../utils/UserData";

const baseURL = process.env.REACT_APP_MK_MSG_API;

export const sendMessage = async (
  message,
  spaceId,
  channelId,
  recepientId,
  senderId,
  readers
) => {
  const msg = {
    createdDate: new Date(),
    message,
    status: "send",
    spaceId: spaceId,
    channelId: channelId,
    isDeletedFromSender: false,
    isEditedMessage: false,
    mentionedMessageId: null,
    recepientId: recepientId,
    senderId: senderId,
    media: [],
    recipients: [],
    readers: readers,
  };
  const response = await axios.post(`${baseURL}/message`, msg, {
    headers: getHeaders()
  });
  return response;
};

export const getMessages = async (spaceId) => {
  const response = await axios.get(`${baseURL}/message/space/${spaceId}`, {
    headers: getHeaders()
  });
  return response.data;
};

export const getOneToOneChannel = async (senderId, recipientId, patientId) => {
  const response = await axios.post(`${baseURL}/one-to-one`, {
    sender: senderId,
    receiver: recipientId,
    patientId: patientId
  }, {
    headers: getHeaders()
  });
  return response.data;
}

export const getCommunityChannel = async (patientId) => {
  const response = await axios?.post(`${baseURL}/patient-community`, {
    id: patientId,
  }, {
    headers: getHeaders()
  });
  return response.data;
};

export const getUnreadMessages = async (channelId, userId) => {
  const response = await axios?.post(`${baseURL}/message/unread`, {
    channelId: channelId,
    userId: userId,
  }, {
    headers: getHeaders()
  });
  return response.data;
};

export const readAllMessages = async (channelId, userId) => {
  const response = await axios?.post(`${baseURL}/message/read-all`, {
    channelId: channelId,
    recepientId: userId,
  }, {
    headers: getHeaders()
  });
  return response.data;
};

// read api
export const readAllGroupMessages = async (channelId, userId) => {
  const response = await axios?.post(`${baseURL}/message/read-all-group`, {
    channelId: channelId,
    userId: userId,
  }, {
    headers: getHeaders()
  });
  return response.data;
};

// unread count api
export const getUnreadCountGroupMessages = async (channelId, userId) => {
  const response = await axios?.post(`${baseURL}/message/newmessages-group`, {
    channelId: channelId,
    userId: userId,
  }, {
    headers: getHeaders()
  });
  return response.data;
};


function getHeaders() {
  return {
    Authorization: `Bearer ${CachedUser()?.accessToken}`,
    "Content-Type": "application/json",
  };
}

import React, { useState } from "react";
import { Avatar, Tooltip } from "@mui/material";
import MoveDownIcon from "@mui/icons-material/MoveDown";

import {
  generateFormattedTimeStamp,
  getCorrectDateTimeString,
  getDayFromDate,
  getMonthFromDate,
  getTimeFromDate,
  isFutureDateTime,
} from "../../utils/chartUtils";
import { stringAvatar } from "../../hooks/convertAvatarInitital";
import { ImageBaseUrl } from "../../HTTPS/Api";
import ReuseableBlurModel from "../Modals/ReuseableBlurModel";
import CreateEventFromReminderForm from "../Forms/CreateEventFromReminderForm";
import { useUser } from "../../context/UserContext";

function CalendarReminder({ schedule }) {
  const { user } = useUser();
  const [clamp, setClamp] = useState(false);
  const [open, setOpen] = useState(false);

  const ifReminderIsForFuture = isFutureDateTime(getCorrectDateTimeString(schedule?.evDate, schedule?.evTime));

  return (
    <>
      <div className="flex space-x-2 sm:space-x-4 w-full h-full bg-[#001640] mt-2 rounded-xl">
        <div className="bg-[#0492C2] flex flex-col p-2 rounded-xl justify-center items-center min-w-[5rem]">
          <span className="text-white text-xl sm:text-2xl font-bold">
            {getDayFromDate(
              getCorrectDateTimeString(schedule?.evDate, schedule?.evTime)
            )}
          </span>
          <span className="text-white text-sm">
            {getMonthFromDate(
              getCorrectDateTimeString(schedule?.evDate, schedule?.evTime)
            )}
          </span>
          <span className="text-white text-[13px]">
            {getTimeFromDate(
              getCorrectDateTimeString(schedule?.evDate, schedule?.evTime)
            )}
          </span>
        </div>

        <div className="flex flex-wrap sm:flex-nowrap justify-between items-center w-full">
          {/* User Event Text And Image  */}
          <div className="py-3 w-full">
            <div className="flex items-center space-x-2 pt-3 pb-2 ">
              {/* Render the rest of the patients */}

              <Avatar
                {...stringAvatar(schedule?.user)}
                src={
                  schedule?.profileImage
                    ? `${ImageBaseUrl}/${schedule?.profileImage}`
                    : ""
                }
                className="uppercase"
              />

              <div className="">
                <span className="text-white text-xs">{schedule?.user}</span>

                {/* Event Desc  */}
                <span
                  className={`text-start text-[13px] sm:text-base font-bold line-clamp-2 text-white max-w-[5rem] xs:max-w-[10rem] sm:max-w-xl md:max-w-full after:text-red-500 ${clamp ? "line-clamp-none" : ""
                    }`}
                  onClick={() => setClamp((prev) => !prev)}
                >
                  {schedule?.text}
                </span>
              </div>
              {ifReminderIsForFuture && (
                <Tooltip title="Convert to Activity">
                  <button
                    onClick={() => {
                      setOpen(true);
                    }}
                    disabled={user?.role === "Viewer"}
                    className={`!ml-auto !mr-6 text-white ${user?.role === "Viewer" ? "!opacity-30" : ""
                      }`}
                  >
                    <MoveDownIcon />
                  </button>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Date */}
      <div className="flex items-center space-x-2 my-1 ">
        {schedule?.assignees && (
          <span className="flex items-center ml-2">
            {JSON?.parse(schedule?.assignees)?.Assignees?.map((item, index) => (
              <Tooltip
                title={item?.user?.name}
                placement="bottom-end"
                className=""
                key={index}
              >
                <Avatar
                  {...stringAvatar(item?.user?.name)}
                  src={
                    item?.user?.profileImage
                      ? `${ImageBaseUrl}/${item?.user?.profileImage}`
                      : ""
                  }
                  sx={{
                    width: "24px !important",
                    height: "24px !important",
                    fontSize: 10,
                    border: "1px solid white !important",
                    marginLeft: "-8px !important",
                  }}
                  className="uppercase"
                />
              </Tooltip>
            ))}
          </span>
        )}

        {schedule?.createdDate && (
          <span className="text-[12px] text-gray-500">
            {generateFormattedTimeStamp(schedule?.createdDate)}
          </span>
        )}
      </div>

      <ReuseableBlurModel open={open} setOpen={setOpen}>
        <CreateEventFromReminderForm schedule={schedule} setOpen={setOpen} />
      </ReuseableBlurModel>
    </>
  );
}

export default CalendarReminder;

import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import VitalTableCard from "../UI/VitalTableCard";
import { usePatient } from "../../context/PatientContext";
import { listTemperatureVital } from "../../HTTPS/VitalApis";
import ReuseableModel from "../Modals/ReuseableModel";
import TempEditVitalForm from "../Forms/TempEditVitalForm";
import { formatDate, formatTime } from "../../utils/chartUtils";

function TemperatureTable() {
  const [open, setOpen] = useState(false);
  const [entry, setEntry] = useState({});
  const { patient } = usePatient();

  const { data } = useQuery({
    queryKey: ["TempVitalList", patient?.id],
    queryFn: () => listTemperatureVital(patient?.providerUserId),
    enabled: !!patient?.id,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      {data?.map((i) => (
        <VitalTableCard
          valOne={i.temperature}
          date={formatDate(i?.date)}
          time={formatTime(i?.date)}
          onClick={() => {
            setOpen(true);
            setEntry(i);
          }}
          createdBy={i?.createdBy}
        />
      ))}
      <ReuseableModel open={open} setOpen={setOpen}>
        <TempEditVitalForm setOpen={setOpen} entry={entry} />
      </ReuseableModel>
    </>
  );
}

export default TemperatureTable;

import React from 'react';
import GradeIcon from "@mui/icons-material/Grade";
import GradeOutlinedIcon from "@mui/icons-material/GradeOutlined";
import { Avatar } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { stringAvatar } from '../../hooks/convertAvatarInitital';
import { ImageBaseUrl } from '../../HTTPS/Api';
import { formattedTime, getCorrectDateTimeString, getDayFromDate, getMonthFromDate, getTimeFromDate } from '../../utils/chartUtils';
import EditIcon from '../Icons/EditIcon';
import { DeleteNoteApi, toggleNote } from '../../HTTPS/ApiService';
import { usePatient } from '../../context/PatientContext';
import useToast from '../../hooks/useToast';
import HandBagIcon from '../Icons/HandBagIcon';
import { useUser } from '../../context/UserContext';


function Observation({ note, setEditObservationOpen, setObservationToBeEdited }) {
  const { patient } = usePatient();
  const queryClient = useQueryClient();
  const { user, userDetails } = useUser();

  const { setOpenSuccessToast, setToastMessage } = useToast();

  const { mutate: onImpToggle } = useMutation({
    mutationFn: async ({ id, isImp }) => await toggleNote(id, !isImp),
    onSuccess: () => {
      queryClient.invalidateQueries(["Observation", patient?.id]);
    },
    onError: () => {
    },
  });

  const { mutate: onDelete } = useMutation({
    mutationFn: async (id) => {
      const userConfirmed = window.confirm(
        "Are you sure you want to delete the Observation?"
      );
      // Check if the user clicked OK
      if (userConfirmed) {
        // Call the onDelete function passed from the parent component
        return await DeleteNoteApi(id);
      }
      return Promise.reject();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["Observation", patient?.id]);
      setToastMessage("Observation Deleted successfully");
      setOpenSuccessToast(true);
    },
    onError: () => {

    },
  });

  return (
    <div className="flex gap-3 items-end my-5">
      {/* Search Bar  */}

      {/* User profile Image  */}
      <div className="flex items-center gap-4">
        <span className="h-10 w-10 rounded-full overflow-hidden -translate-y-5">
          <Avatar
            src={note?.profileImage ? `${ImageBaseUrl}/${note?.profileImage}` : ""}
            {...stringAvatar(note?.user)}
            className="uppercase !text-base"
          />
        </span>
      </div>

      <div className="w-full">
        {/* Note Box */}

        <div className="flex">
          <div className="bg-[#0492C2] flex flex-col p-2 rounded-ss-xl justify-center items-center min-w-[5rem]">
            <span className="text-white text-xl sm:text-2xl font-bold">
              {getDayFromDate(
                getCorrectDateTimeString(note?.ntDate, note?.ntTime)
              )}
            </span>
            <span className="text-white text-sm">
              {getMonthFromDate(
                getCorrectDateTimeString(note?.ntDate, note?.ntTime)
              )}
            </span>
            <span className="text-white text-[13px]">
              {getTimeFromDate(
                getCorrectDateTimeString(note?.ntDate, note?.ntTime)
              )}
            </span>
          </div>

          <div className="bg-gray-200 flex flex-col gap-1 rounded-e-xl h-auto p-3 px-5 sm:p-5 w-full max-w-[8rem] xxs:max-w-[10rem] xs:max-w-[18rem] sm:max-w-full space-y-2 z-0">
            {/* Description and Image  */}

            {note?.attachment
              ? (<div className="">
                <img
                  src={note?.attachment ? `${ImageBaseUrl}/${note?.attachment}` : ""}
                  alt={note?.text}
                  className="object-contain w-64 h-auto rounded-2xl z-[999999]"
                  onError={(e) => {
                    e.target.style.display = "none"; // Hide the image if it fails to load
                  }}
                />
              </div>)
              : null}


            <div className="flex flex-wrap flex-col-reverse sm:flex-row sm:flex-nowrap justify-between items-start">
              <span
                className={`max-w-5xl mt-3 sm:mt-0 sm:mb-3 w-full`}
              >
                <span className="max-h-32" >
                  {note?.text.split("\n").map((line, index) => (
                    <div
                      key={index}
                      className="break-words max-w-[18rem] sm:max-w-[20rem] md:!max-w-[28rem] lg:!max-w-[30rem] xl:!max-w-[44rem]"
                    >
                      {line}
                      {index < note?.text.split("\n").length - 1 && <br />}{" "}
                      {/* Add <br> after each line except the last one */}
                    </div>
                  ))}
                </span>
              </span>

              <div className="space-x-3 flex sm:-translate-y-0 justify-end items-end sm:justify-start sm:items-start w-full sm:w-auto z-0">
                <button
                  onClick={() => {
                    setEditObservationOpen(true);
                    setObservationToBeEdited(note);
                  }}
                  disabled={
                    (userDetails?.id !== note?.userId &&
                      user?.role !== "Kare Admin")
                  }
                  className={` ${(userDetails?.id !== note?.userId &&
                    user?.role !== "Kare Admin")
                    ? "opacity-30"
                    : ""
                    }`}
                >
                  {/* Edit Button */}
                  <EditIcon />
                </button>

                {/* Delete Note Button  */}
                <button
                  onClick={() => onDelete(note?.id)}
                  disabled={
                    (userDetails?.id !== note?.userId &&
                      user?.role !== "Kare Admin")
                  }
                  className={` ${(userDetails?.id !== note?.userId &&
                    user?.role !== "Kare Admin")
                    ? "opacity-30"
                    : ""
                    }`}
                >
                  <HandBagIcon />
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Date */}
        <div className="flex items-center space-x-2 mt-1">
          <span
            onClick={() => onImpToggle({ id: note?.id, isImp: note?.isImp })}
            className="text-gray-500 flex justify-center items-center space-x-2 cursor-pointer"
          >
            {note?.isImp ? (
              <GradeIcon className="text-[#ED7117]" />
            ) : (
              <GradeOutlinedIcon className="text-[#ED7117]" />
            )}
          </span>
          <span className="text-black text-[12px]">{note?.user}</span>
          <span className="text-gray-500">•</span>
          <span className="text-[12px] text-gray-500">
            {formattedTime(note?.createdDate)}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Observation;
import { Avatar, Box, Modal } from '@mui/material';
import React from 'react';
import PatientName from '../PatientName';
import { useNavigate } from 'react-router-dom';
import { usePatient } from '../../context/PatientContext';
import { ImageBaseUrl } from '../../HTTPS/Api';

function SwitchPatientModal({ open, setOpen, patient, routeToRoot = true }) {
  const navigate = useNavigate();
  const { updateSelectedPatient } = usePatient();

  const patientSelectHandler = async (selectedPatient) => {
    try {
      await updateSelectedPatient(selectedPatient);
      routeToRoot && navigate("/");
    } catch (error) {
      console.error("Error updating default patient:", error);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        className="p-5 flex h-full justify-center items-center"
      >
        <Box className="bg-white p-5 rounded-3xl w-[50rem]">
          <h2 className="text-3xl font-bold text-[#1D3E7D] mb-6">
            Switch Care Recipient
          </h2>

          <p
            id="parent-modal-description"
            className="text-center text-lg text-[#1D3E7D] font-semibold"
          >
            Are you sure you want to switch to this care recipient?
          </p>

          <div className="my-5 flex flex-col justify-center items-center">
            <Avatar
              src={
                patient?.profileImage
                  ? `${ImageBaseUrl}/${patient?.profileImage}`
                  : ""
              }
              className="rounded-full object-contain uppercase !text-base"
              sx={{
                width: 128,
                height: 128,
              }}
            />

            <h2 className="text-xl font-bold text-[#1D3E7D] mb-6">
              <PatientName patient={patient} />
            </h2>
          </div>

          <div className="flex space-x-4 w-full justify-center items-center">
            <button
              onClick={() => setOpen(false)}
              className="border border-[#1D3E7D] text-lg text-[#1D3E7D] rounded-md p-2 px-10"
            >
              No
            </button>
            <button
              onClick={() => {
                patientSelectHandler(patient);
                setOpen(false);
              }}
              className="bg-[#ED7117] text-lg text-white rounded-md p-2 px-10"
            >
              Yes
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default SwitchPatientModal;
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { usePatient } from "../../context/PatientContext";
import { listBloodPressureVital } from "../../HTTPS/VitalApis";
import VitalTableCard from "../UI/VitalTableCard";
import { formatDate, formatTime } from "../../utils/chartUtils";
import ReuseableModel from "../Modals/ReuseableModel";
import BpEditVitalForm from "../Forms/BpEditVitalForm";

function BloodPressureTable() {
  const [open, setOpen] = useState(false);
  const [entry, setEntry] = useState({});
  const { patient } = usePatient();

  const { data } = useQuery({
    queryKey: ["BPVitalList", patient?.id],
    queryFn: () => listBloodPressureVital(patient?.providerUserId),
    enabled: !!patient?.id,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      {data?.map((i) => (
        <VitalTableCard
          twoValue={true}
          valOne={i?.systolic}
          valTwo={i?.diastolic}
          date={formatDate(i?.timestamp)}
          time={formatTime(i?.timestamp)}
          onClick={() => {
            setOpen(true);
            setEntry(i);
          }}
          createdBy={i?.createdBy}
        />
      ))}
      <ReuseableModel open={open} setOpen={setOpen}>
        <BpEditVitalForm setOpen={setOpen} entry={entry} />
      </ReuseableModel>
    </>
  );
}

export default BloodPressureTable;

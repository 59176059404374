import React, { useState } from 'react'

import { useUser } from '../../context/UserContext';
import AddEventPlusIconBtn from '../UI/AddEventPlusIconBtn';
import VitalEventFunc from '../UI/VitalEventFunc';
import ReuseableBlurModel from '../Modals/ReuseableBlurModel';
import VitalEventModelContent from './VitalEventModelContent';
import CreateVitalEventForm from '../Forms/CreateVitalEventForm';
import EditVitalEventForm from '../Forms/EditVitalEventForm';

function VitalEventSection({ events, vitalTypeId, vitalTitle }) {
  const [createActivityOpen, setCreateActivityOpen] = useState(false);
  const [editActivityOpen, setEditActivityOpen] = useState(false);
  const [activityToBeEdited, setActivityToBeEdited] = useState({});

  const { user } = useUser();

  return (
    <>
      <div className="bg-white p-5 mt-12 rounded-t-2xl overflow-hidden">
        {/* Chart screen footer */}
        <div className="mt-12 mb-10 flex justify-between items-center">
          <div className="">
            <h1 className="text-[#1D3E7D] font-bold text-4xl">
              Activities
            </h1>
            <h5 className="text-[#1D3E7D] font-bold">{vitalTitle}</h5>
          </div>

          <button
            onClick={() => setCreateActivityOpen(true)}
            disabled={user?.role === "Viewer"}
            className={`flex items-center gap-2 fixed bottom-10 right-10 ${user?.role === "Viewer" ? "opacity-50" : ""
              }`}
          >
            <AddEventPlusIconBtn />
          </button>
        </div>
        <div className="items-center ">
          {events
            ?.sort((a, b) => {
              const dateComparison =
                new Date(b.evDate) - new Date(a.evDate);
              if (dateComparison === 0) {
                // If dates are the same, compare times
                const timeA = new Date(`1970-01-01T${a.evTime}`);
                const timeB = new Date(`1970-01-01T${b.evTime}`);
                return timeB - timeA;
              }
              return dateComparison;
            })
            ?.map((item, i) => (
              <VitalEventFunc
                key={i}
                item={item}
                setEditActivityOpen={setEditActivityOpen}
                setActivityToBeEdited={setActivityToBeEdited}
              />
            ))}
        </div>
      </div>
      <ReuseableBlurModel open={createActivityOpen} setOpen={setCreateActivityOpen} >
        <VitalEventModelContent vitalTitle={vitalTitle}>
          <CreateVitalEventForm
            vitalTypeId={vitalTypeId} // 6 = Temperature
            setOpen={setCreateActivityOpen}
          />
        </VitalEventModelContent>
      </ReuseableBlurModel>

      <ReuseableBlurModel open={editActivityOpen} setOpen={setEditActivityOpen} >
        <VitalEventModelContent vitalTitle={vitalTitle}>
          <EditVitalEventForm
            vitalTypeId={vitalTypeId} // 6 = Temperature
            setOpen={setEditActivityOpen}
            activity={activityToBeEdited}
          />
        </VitalEventModelContent>
      </ReuseableBlurModel>
    </>
  )
}

export default VitalEventSection
import { getDateTimeFromDate, roundToOneDecimal } from './chartUtils';

const moment = require('moment');



/**
 * Process the grouped data by date and return it in a format suitable for graph presentation.
 * 
 * @param {Object} groupedByDate - The data grouped by date.
 * @param {string} vital - Name of the vital key
 * @param {string} operation - add or avg to be performed
 * @returns {Array} The processed and formatted data for graph presentation.
 */
export function processGroupedDataForLineGraph(groupedByDate, vital = 'value', days = 15, operation = 'avg') {
  const sleepVitals = ['light', 'deep', 'rem', 'awake']
  const isSleep = sleepVitals.includes(vital)
  
  const result = [];
  for (const [date, values] of Object.entries(groupedByDate)) {
    const processedValue = processData(values, vital, operation);
    const graphFormat = [
      moment.utc(date).valueOf(), // Ignoring the time for proper graph presentation
      roundToOneDecimal(isSleep ? (processedValue / (60 * 60)) : processedValue)
    ];
    result.push(graphFormat);
  }
  return result
    .slice(0, days)
    .reverse();
}



/**
 * Processes grouped data to create an array of objects formatted for a graph.
 * Each object contains the formatted date, rounded weight, and year.
 * 
 * @param {Object} groupedByDate - An object where keys are dates and values are arrays of data objects.
 * @param {string} vital - Name of the vital key
 * @returns {Array} processedData - An array of processed data objects for graphing.
 */
export function processGroupedDataForWeightChart(groupedByDate, vital = 'weight', days = 7) {
  const result = [];

  for (const [date, values] of Object.entries(groupedByDate)) {
    values.forEach(element => {
      result.push({
        x: formatDate(date), // Format the date as 'DD MMM'
        y: roundToOneDecimal(element[vital]), // Round the weight to one decimal place
        z: date.split('-').at(0), // Extract the year from the date
      });
    });
  }

  return result
    .slice(0, days)
    .reverse();
}



/**
 * Process and aggregate chart data by date.
 * @param {Array} localizedChartData - The array of chart data with localized timestamps.
 * @returns {Object} The processed chart data aggregated by date.
 */
export function aggregateChartDataInBarGraphFormat(localizedChartData, days = 7) {
  // Transform the aggregated data into the desired format.
  const result = [];

  // Reduce the chart data to an object keyed by date with high and low values.
  const dateWiseChartData = localizedChartData?.reduce((acc, cur) => {
    const date = cur.localizedTimestamp.split('T')[0];
    if (!acc[date]) {
      acc[date] = { high: cur.value, low: cur.value };
    } else {
      if (!(acc[date]?.high > cur.value)) {
        acc[date].high = cur.value;
      }
      if (!(acc[date]?.low < cur.value)) {
        acc[date].low = cur.value;
      }
    }
    return acc;
  }, {});

  if (dateWiseChartData) {
    for (const [date, values] of Object.entries(dateWiseChartData)) {
      const x = formatDate(date);
      const y = [values.low, values.high];
      const z = date.split("-")[0];
      result.push({ x, y, z });
    }
  }
  return result
    .slice(0, days)
    .reverse();
}





// ======================================
// SUPPORTING FUNCTIONS
// ======================================

/**
 * Localize the timestamps in chart data.
 * @param {Array} chartData - The array of chart data with timestamps.
 * @param {string} timestamp - Key name where we get the date-time value.
 * @returns {Array} The chart data with localized timestamps.
 */
export function localisingTimestamp(chartData, timestamp = "timestamp") {
  return chartData?.map(item => {
    const localizedTimestamp = getDateTimeFromDate(item[timestamp]);
    return { localizedTimestamp, ...item };
  });
}


/**
 * Group an array of objects by the date part of their localizedTimestamp.
 * 
 * @param {Array} localizedChartData - The array of chart data with localized timestamps.
 * @returns {Object} An object where each key is a date and the value is an array of objects with that date.
 */
export function groupByDateForLineGraph(localizedChartData) {
  return localizedChartData?.reduce((acc, cur) => {
    const date = cur.localizedTimestamp.split('T')[0];

    if (!acc[date]) {
      acc[date] = [cur];
    } else {
      acc[date].push(cur);
    }
    return acc;
  }, {});
}


// ======================================
// NON EXPORTED
// ======================================

/**
 * YYYY-MM-DD => DD MMM
 * 
 * local => local
 * @param {string} dateString - The date string in "YYYY-MM-DD" format.
 * @returns {string} The formatted date string in "DD MMM" format.
 */
function formatDate(dateString) {
  return moment(dateString).format('DD MMM');
}


/**
* Process an array of objects to perform the specified operation (add or avg) on the "value" field.
* 
* @param {Array} dataArray - The array of data objects.
* @param {string} operation - The operation to perform ("add" or "avg").
* @returns {number} The result of the specified operation on the "value" field.
* @throws {Error} Throws an error if the operation is not supported.
*/
function processData(dataArray, vital, operation) {
  // Ensure the dataArray is valid and not empty
  if (!dataArray || dataArray.length === 0) {
    throw new Error('Data array is empty or not provided.');
  }

  // Perform the specified operation
  let result;
  switch (operation) {
    case 'add':
      result = dataArray.reduce((sum, item) => sum + item[vital], 0);
      break;
    case 'avg':
      const total = dataArray.reduce((sum, item) => sum + item[vital], 0);
      result = total / dataArray.length;
      break;
    default:
      throw new Error('Unsupported operation. Use "add" or "avg".');
  }

  return result;
}



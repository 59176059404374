import { useState } from "react";
import { usePatient } from "../../context/PatientContext";
import { useQuery } from "@tanstack/react-query";
import VitalTableCard from "../UI/VitalTableCard";
import ReuseableModel from "../Modals/ReuseableModel";
import { formatDate, formatTime } from "../../utils/chartUtils";
import { listWeightVital } from "../../HTTPS/VitalApis";
import WeightEditVitalForm from "../Forms/WeightEditVitalForm";

function WeightTable() {
  const [open, setOpen] = useState(false);
  const [entry, setEntry] = useState({});
  const { patient } = usePatient();

  const { data } = useQuery({
    queryKey: ["weigthVitalList", patient?.id],
    queryFn: () => listWeightVital(patient?.providerUserId),
    enabled: !!patient?.id,
    refetchOnWindowFocus: false,
  });
  
  return (
    <>
      {data?.map(i => (
        <VitalTableCard
          valOne={i.weight}
          date={formatDate(i?.date)}
          time={formatTime(i?.date)}
          onClick={() => {
            setOpen(true);
            setEntry(i);
          }}
          createdBy={i?.createdBy}
        />
      ))}
      <ReuseableModel
        open={open}
        setOpen={setOpen}
      >
        <WeightEditVitalForm setOpen={setOpen} entry={entry} />
      </ReuseableModel>
    </>
  );
}

export default WeightTable
import React, { forwardRef } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';

import { convertToLocalDate, convertToLocalTime, generateTimestampAndOffset, getCurrentLocalDate } from '../../utils/chartUtils';
import { bpVitalValidation } from '../../utils/validations';
import { useToastContext } from '../../context/ToastContext';
import { usePatient } from '../../context/PatientContext';
import { editManualBloodPressureVital } from '../../HTTPS/VitalApis';
import { useUser } from '../../context/UserContext';

function BpEditVitalForm({ setOpen, entry }, ref) {
  const { patient } = usePatient();
  const { userDetails } = useUser();
  const queryClient = useQueryClient();

  const { setOpenSuccessToast, setOpenErrorSuccessToast, setToastMessage } = useToastContext();

  const { mutate: handleAddVital, isPending } = useMutation({
    mutationFn: async ({ systolic, diastolic, defaultDate, defaultTime }) =>
      await editManualBloodPressureVital(
        patient?.providerUserId,
        userDetails?.id,
        systolic,
        diastolic,
        ...generateTimestampAndOffset(defaultDate, defaultTime),
        entry.id
      ),
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries(["BPVitalList", patient?.id]);
        queryClient.invalidateQueries(["bloodPressureVital", patient?.id]);
      }, 1000);
      setToastMessage("Updated");
      setOpenSuccessToast(true);
      setOpen(false);
    },
    onError: () => {
      setToastMessage("Update failed");
      setOpenErrorSuccessToast(true);
    },
  });

  const formik = useFormik({
    initialValues: {
      systolic: entry?.systolic,
      diastolic: entry?.diastolic,
      defaultDate: convertToLocalDate(entry?.timestamp),
      defaultTime: convertToLocalTime(entry?.timestamp),
    },
    validationSchema: bpVitalValidation,
    onSubmit: (values) => {
      handleAddVital(values, {
        onSuccess: () => { },
        onError: () => { },
      });
    },
  });

  return (
    <>
      <form className="bg-white z-50 p-5 rounded-2xl">
        <h1 className="text-2xl mb-3">Edit Data</h1>
        <div className="flex flex-col space-y-2">
          <div className="flex flex-col space-y-1">
            <label htmlFor="sys">Systolic</label>
            <input
              type="text"
              {...formik.getFieldProps("systolic")} // name, value, onChange, onBlur
              placeholder="Systolic"
              className="border w-72 p-2 rounded-xl"
            />
            {formik.touched.systolic && formik.errors.systolic ? (
              <span className="text-red-500 text-sm">
                {formik.errors.systolic}
              </span>
            ) : null}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="dys">Diastolic</label>
            <input
              type="text"
              {...formik.getFieldProps("diastolic")} // name, value, onChange, onBlur
              placeholder="Diastolic"
              className="border w-72 p-2 rounded-xl"
            />
            {formik.touched.diastolic && formik.errors.diastolic ? (
              <span className="text-red-500 text-sm">
                {formik.errors.diastolic}
              </span>
            ) : null}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="">Date</label>
            <input
              type="date"
              {...formik.getFieldProps("defaultDate")} // name, value, onChange, onBlur
              max={getCurrentLocalDate()}
              placeholder="Date"
              className="border w-72 p-2 rounded-xl"
            />
            {formik.touched.defaultDate && formik.errors.defaultDate ? (
              <span className="text-red-500 text-sm">
                {formik.errors.defaultDate}
              </span>
            ) : null}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="">Time</label>
            <input
              type="time"
              {...formik.getFieldProps("defaultTime")} // name, value, onChange, onBlur
              placeholder="Time"
              className="border w-72 p-2 rounded-xl"
            />
            {formik.touched.defaultTime && formik.errors.defaultTime ? (
              <span className="text-red-500 text-sm">
                {formik.errors.defaultTime}
              </span>
            ) : null}
          </div>

          <div className="flex">
            <button
              type="submit"
              onClick={formik.handleSubmit}
              disabled={isPending}
              className="bg-[#ED7117] rounded-xl p-3 px-7 text-white"
            >
              Save
            </button>
            <button
              onClick={() => setOpen(false)}
              className="rounded-xl p-3 px-7 text-black"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default forwardRef(BpEditVitalForm);
import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useRecoilState } from "recoil";

import { activeButtonState } from "../../recoil/report/activeButtonState";

export default function ButtonGroup() {
  const [activeButton, setActiveButton] = useRecoilState(activeButtonState);
  
  const buttonList = ["day", "week", "month", "range"];

  const handleButtonGroupChange = (event, newActiveButton) => {
    if (newActiveButton !== null) {
      setActiveButton(newActiveButton);
    }
  };
  
  return (
    <ToggleButtonGroup
      color="primary"
      value={activeButton}
      exclusive
      onChange={handleButtonGroupChange}
      aria-label="Platform"
      className="flex justify-center items-center w-full sm:gap-x-4 "
    >
      {buttonList.map((item, i) => {
        return (
          <ToggleButton
            key={i}
            className={`!font-bold !text-[#1F456E] !border-b-4 sm:!border-b-8 !w-1/2 !rounded-t-xl sm:!rounded-t-2xl text-sm sm:!text-lg ${
              // eslint-disable-next-line eqeqeq
              activeButton == item
                ? "!border-b-[#1F456E] !border-s-0 !border-e-0 !border-t-0 !bg-[#ECF1F4] px-2"
                : "!border-transparent"
            }`}
            value={item}
          >
            {item}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}

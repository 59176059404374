export const CachedUser = () => {
  return JSON.parse(localStorage.getItem("user"));
}

export const CachedUserDetails = () => {
  return JSON.parse(localStorage.getItem("userDetails"));
}

export const CachedPatientDetails = () => {
  return JSON.parse(localStorage.getItem("patientDetails"));
}
import MainAreaWrapper from "../layout/MainAreaWrapper";
import NavigationButtons from "../components/Report/Buttons/NavigationButtons";
import DownloadButton from "../components/Report/Buttons/DownloadButton";
import RenderSteps from "../components/Report/StepsInterface/RenderSteps";
import DownloadAsPdf from "../components/Report/Buttons/DownloadAsPdf";
import { useEffect } from "react";
import { useResetRecoilState } from "recoil";
import { stepState } from "../recoil/report/stepState";
import { endDateState, startDateState } from "../recoil/report/dateRangeState";
import { activeButtonState } from "../recoil/report/activeButtonState";
import { checkListState } from "../recoil/report/checkListState";
import { reportDataState } from "../recoil/report/reportDataState";

const Report = () => {
  const statesToReset = [startDateState, endDateState, activeButtonState, checkListState, reportDataState, stepState];
  const resetFunctions = statesToReset.map(useResetRecoilState);
  const resetMultipleStates = () => resetFunctions.forEach(fn => fn());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(resetMultipleStates, []);

  return (
    <MainAreaWrapper>
      <main className="bg-white mt-28 lg:mt-10 rounded-t-3xl pb-10 lg:pb-0 -z-0">
        <div className="mx-auto p-4 md:p-6 2xl:p-10">
          <div className="mx-auto">
            <div className="flex !flex-wrap justify-between items-center">
              <h2 className="text-3xl sm:text-5xl font-bold text-[#1D3E7D]">
                Reports
              </h2>

              <div className="flex gap-x-4">
                <DownloadAsPdf />
                <DownloadButton />
              </div>
            </div>

            {/* UI For 1, 2, 3 Steps */}
            <RenderSteps />
            {/* Buttons to go back and forth Steps */}
            <NavigationButtons />
          </div>
        </div>
      </main>
    </MainAreaWrapper>
  );
};

export default Report;

// ToastContext.js
import React, { createContext, useContext, useState } from "react";
import Toast from "../components/Toasts/Toast";

const ToastContext = createContext();

export const useToastContext = () => useContext(ToastContext);

export const ToastProvider = ({ children }) => {
  const [openSuccessToast, setOpenSuccessToast] = useState(false);
  const [openErrorSuccessToast, setOpenErrorSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  return (
    <ToastContext.Provider
      value={{
        openSuccessToast,
        openErrorSuccessToast,
        setOpenSuccessToast,
        setOpenErrorSuccessToast,
        toastMessage,
        setToastMessage,
      }}
    >
      {children}
      <Toast open={openSuccessToast} setOpen={setOpenSuccessToast} message={toastMessage} severity="success" />
      <Toast open={openErrorSuccessToast} setOpen={setOpenErrorSuccessToast} message={toastMessage} severity="error" />
    </ToastContext.Provider>
  );
};

import React from "react";
import { useState, useEffect } from "react";
import { fetchContacts } from "../HTTPS/ApiService";
import { usePatient } from "../context/PatientContext";
import MainAreaWrapper from "../layout/MainAreaWrapper";
import ContactCard from "../components/UI/ContactCard";

const Contact = () => {
  const { patient, setPatient } = usePatient();
  const type = [
    "Medical",
    "Family",
    "Friends",
    "School",
    "Work",
    "Other",
  ];

  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  const [contacts, setContacts] = useState([]);

  const nextCategory = () => {
    setCurrentCategoryIndex((prevIndex) => (prevIndex + 1) % type.length);
  };

  const prevCategory = () => {
    setCurrentCategoryIndex((prevIndex) =>
      prevIndex === 0 ? type.length - 1 : prevIndex - 1
    );
  };

  // Filter contacts based on the current category
  const filteredContacts = contacts.filter(
    (item) => item.type === type[currentCategoryIndex]
  );

  useEffect(() => {
    if (!patient) {
      const storedPatient = localStorage.getItem("selectedPatient");
      if (storedPatient) {
        setPatient(JSON.parse(storedPatient));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);

  
  useEffect(() => {
    if (patient) {
      fetchContacts(patient?.id).then((data) => {
        setContacts(data);
      });

      // Fetch relationships data
      // fetchRelationships(patient?.id).then((data) => {
      //   // Assuming the relationships data has a field 'relationships' that contains an array
      //   setRelationships(data);
      //   // console.log(relationship[1].text);
      // });
    }
  }, [patient]);

  return (
    <MainAreaWrapper>
      <main className="bg-gradient-to-b from-[#FAFCFEBF]/70 via-[#FAFCFEBF]/70 to-transparent h-screen mt-28 lg:mt-10 rounded-t-3xl">
        <div className="mx-auto  p-4 md:p-6 2xl:p-10">
          <div className="mx-auto">
            {/* <!-- Breadcrumb Start --> */}
            <div className="mb-6 flex flex-row items-center justify-between gap-3">
              <h2 className="text-4xl sm:text-5xl font-bold text-[#1D3E7D]">
                Contacts
              </h2>
            </div>

            <div className="flex justify-between my-5 items-center">
              <button className="" onClick={prevCategory}>
                {/* Goes to previous category */}
                <img src="/icons/hand-left.svg" alt="" />
              </button>
              <div className="">
                <h2 className="text-3xl sm:text-5xl font-bold text-[#1D3E7D]">
                  {type[currentCategoryIndex]}
                </h2>
              </div>
              <button className="" onClick={nextCategory}>
                {/* Goes to next category */}
                <img src="/icons/hand-right.svg" alt="" />
              </button>
            </div>
          </div>

          <div className="relative flex flex-col justify-center items-center">
            {filteredContacts.map((item) => {
              return (
                <ContactCard
                  key={item.name}
                  content={{
                    patient: patient,
                    relationship: item?.relationship,
                    image: item?.profileImage,
                    name: item?.name,
                    phone: item?.phone,
                  }}
                />
              );
            })}
          </div>
        </div>
      </main>
    </MainAreaWrapper >
  );
};

export default Contact;



import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"; // Assuming there is an AdapterMoment for Moment.js
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { useQuery } from "@tanstack/react-query";
import { DayCalendarSkeleton } from "@mui/x-date-pickers";

import { listScheduleMonthApi } from "../HTTPS/ApiService";
import { usePatient } from "../context/PatientContext";
import ServerDay from "./Calendar/ServerDay";
import { convertLocalDateToUTCDateTime, getDateFromDate } from "../utils/chartUtils";

export default function MuiCalendar({ setSelectedDate, selectedDate }) {
  let startOfMonth = moment(selectedDate).startOf("month").format("YYYY-MM-DD");
  let endOfMonth = moment(selectedDate).endOf("month").format("YYYY-MM-DD");
  const { patient } = usePatient();

  const { data: highlightedDays, isLoading, refetch } = useQuery({
    queryKey: ["highlightedDays", patient?.id, startOfMonth, endOfMonth],
    queryFn: async () =>
      patient
        ? listScheduleMonthApi(patient?.id, convertLocalDateToUTCDateTime(startOfMonth), `${endOfMonth} 18:29:59`)
        : [],
    enabled: !!patient,
  });

  const handleMonthChange = (newDate) => {
    startOfMonth = newDate.startOf("month").format("YYYY-MM-DD");
    endOfMonth = newDate.endOf("month").format("YYYY-MM-DD");
    refetch(); // Refetch the query using the updated query key
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} className="">
      <DateCalendar
        value={selectedDate}
        onChange={(newDate) => setSelectedDate(newDate)}
        loading={isLoading}
        renderLoading={() => <DayCalendarSkeleton />}
        onMonthChange={(newDate) => handleMonthChange(newDate)}
        slots={{
          day: ServerDay,
        }}
        slotProps={{
          day: {
            highlightedDays: highlightedDays?.map((data) => getDateFromDate(data)),
          },
        }}
        sx={{
          "& .MuiPickersDay-root.Mui-selected": {
            bgcolor: "#0492C2 !important",
          },
        }}
        className="!w-auto !flex !justify-between md:!sticky md:!top-0"
      />
    </LocalizationProvider>
  );
}

import ReportWrapper from '../ReportWrapper'
import ReportTable from './ReportTable';

function ReportTableWrapper({ data }) {
  return (
    <ReportWrapper data={data} >
      <ReportTable data={data} />
    </ReportWrapper>
  );
}

export default ReportTableWrapper;

import React, { createContext, useContext, useState, useEffect } from "react";

const EventsContext = createContext();

export const useEvents = () => {
  return useContext(EventsContext);
}

export function EventsProvider({ children }) {
  const [events, setEvents] = useState(
    JSON.parse(localStorage.getItem("eventsData")) || []
  );

  useEffect(() => {
    localStorage.setItem("eventsData", JSON.stringify(events));
  }, [events]);

  return (
    <EventsContext.Provider value={{ events, setEvents }}>
      {children}
    </EventsContext.Provider>
  );
}

import React, { useState, useRef, useEffect } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import MessageSendBar from "../components/UI/MessageSendBar";
import MessengerService from "../servies/MessengerService";
// import { useUser } from "../context/UserContext";
import { ImageBaseUrl } from "../HTTPS/Api";
import { Avatar } from "@mui/material";
// import { stringAvatar } from "../hooks/convertAvatarInitital";

import moment from "moment";
import "moment-timezone";
import { CachedUserDetails } from "../utils/UserData";
import { stringAvatar } from "../hooks/convertAvatarInitital";
import { SendingChatCard } from "../components/MessageSkeletonCard";
import { useSearchParams } from "react-router-dom";
import UserAssignee from "../components/userAssignee";

// const WebcamComponent = () => <Webcam />;
const formattedTime = (time) => {
  const currentTime = moment();
  const parsedTime = moment.utc(time); // Parse time as UTC

  const userTimezone = moment.tz.guess(); // Get user's timezone

  // Convert the parsed time to the user's timezone
  parsedTime.tz(userTimezone);

  if (parsedTime.isSame(currentTime, "day")) {
    // If the timestamp is from today, display as "Now"
    return parsedTime.format("MMMM D, h:mmA");
  } else if (parsedTime.isSame(currentTime, "year")) {
    // If the timestamp is from this year, display the time
    return parsedTime.format("MMMM D, h:mmA");
  } else {
    // If the timestamp is from a different year, display the full date and time
    return parsedTime.format("MMMM D, YYYY, h:mmA");
  }
};
const PrivateMessage = () => {
  const [privateMessages, setPrivateMessages] = useState([]);
  const [previewMessageCard, setPreviewMessageCard] = useState(null);
  const currentDate = new Date();
  const currentMonth = new Intl.DateTimeFormat("en-US", {
    month: "short",
  }).format(currentDate);

  const messageRef = useRef();
  useEffect(() => {
    const msgRefresh = () => setPrivateMessages([...MessengerService.messages]);
    const msgRefreshInterval = setInterval(msgRefresh, 500);
    return () => clearInterval(msgRefreshInterval);
  }, [privateMessages]);

  const [queryParameters] = useSearchParams();
  const name = queryParameters.get("name");

  useEffect(() => {
    const scrollToBottom = async () => {
      // await MessengerService.fetchPatientFromStorage(true);
      // await MessengerService.getGroupMesageCount();
      if (messageRef && messageRef.current && privateMessages.length !== 0) {
        messageRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    scrollToBottom();
  }, [privateMessages.length]);

  const messagesByMonth = {};

  // Group messages by month
  privateMessages.forEach((msg) => {
    // Get the month from the message's time
    const messageMonth = new Date(msg.time).toLocaleString("en-US", {
      month: "short",
    });

    // Add message to the respective month array
    if (!messagesByMonth[messageMonth]) {
      messagesByMonth[messageMonth] = [];
    }
    messagesByMonth[messageMonth].push(msg);
  });

  return (
    <div className="h-screen">
      <div className="flex h-full overflow-hidden cover relative">
        {/* Side Menu  */}
        <Sidebar />
        <div className="flex flex-1 flex-col overflow-y-auto">
          {/* Navbar */}
          <Navbar />
          <UserAssignee />
          <main className="bg-white lg:mt-10 rounded-t-3xl">
            {/* Message Bar  */}

            {/* <MessageComp /> */}

            <div className="mx-auto  p-4 md:p-6 2xl:p-10">
              <div className="mx-auto">
                {/* <!-- Breadcrumb Start --> */}
                <div className="mb-6 flex flex-row items-center justify-between gap-3">
                  <h2 className="text-3xl sm:text-5xl font-bold text-[#1D3E7D]">
                    {name}
                  </h2>
                </div>

                {Object.entries(messagesByMonth).map(([month, messages]) => (
                  <div key={month} className="mt-6">
                    <div className="flex justify-center items-center relative">
                      <div className="border bg-white border-gray-400 rounded-full items-center px-7 sm:px-20 py-0 w-min z-10">
                        {month}
                      </div>
                      <span className="w-full h-[0.1px] absolute bg-gray-500 " />
                    </div>

                    {messages.map((msg, i) => {
                      const members = JSON.parse(
                        localStorage.getItem("communityMembers")
                      );
                      const msgSender = members.filter(
                        (user) => user.userId == msg.id
                      )[0];
                      msg.name = msgSender?.name;
                      msg.profileImage = msgSender?.profileImage;
                      return (
                        <div key={i}>
                          {msg?.id == CachedUserDetails()?.id ? (
                            <SenderChatCard
                              text={msg?.text}
                              profileImage={msg?.profileImage}
                              name={msg?.name}
                              time={msg?.time}
                              messageRef={messageRef}
                            />
                          ) : (
                            <ReceiverChatCard
                              text={msg?.text}
                              profileImage={msg?.profileImage}
                              name={msg?.name}
                              time={msg?.time}
                              messageRef={messageRef}
                            />
                          )}
                          {/* {console.log(msg)} */}
                        </div>
                      );
                    })}
                  </div>
                ))}

                {/* Send */}
                {/* <SendChatCard profileImage={"../images/user/Dummy-User.jpeg"} /> */}

                {/* Receive */}
                {/* <ReceiveChatCard
                  profileImage={"../images/user/Dummy-User.jpeg"}
                /> */}

                {previewMessageCard && (
                  <SendingChatCard
                    text={previewMessageCard?.content}
                    profileImage={previewMessageCard?.profileImage}
                    name={previewMessageCard?.name}
                    time={previewMessageCard?.time}
                    messageRef={messageRef}
                  />
                )}
              </div>
            </div>

            <div className="mt-10 sm:mt-0 sm:hidden">
              <div className="fixed sm:sticky w-full bottom-0">
                <MessageSendBar
                  isMessage={true}
                  messageRef={messageRef}
                  setPreviewMessageCard={setPreviewMessageCard}
                />
              </div>
            </div>

            <div className="fixed sm:sticky w-full bottom-0 hidden sm:inline-block">
              <MessageSendBar
                isMessage={true}
                messageRef={messageRef}
                setPreviewMessageCard={setPreviewMessageCard}
              />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default PrivateMessage;

const ReceiverChatCard = ({
  profileImage,
  text,
  image,
  time,
  name,
  messageRef,
}) => {
  return (
    <div className="flex gap-3 items-end my-5" ref={messageRef}>
      {/* Search Bar  */}

      <div className="">
        {/* User profile Image  */}
        <div className="flex items-center gap-4">
          <span className="-translate-y-5">
            <Avatar
              src={profileImage ? `${ImageBaseUrl}/${profileImage}` : ""}
              {...stringAvatar(name)}
              className="uppercase !text-base !h-10 !w-10 rounded-full"
            />
          </span>
        </div>
      </div>

      <div className="">
        {/* Note Box */}
        <div className="bg-[#4A4A68] text-white font-medium flex flex-col gap-1 rounded-y-xl w-full max-w-[12rem] xsm:max-w-[15rem] xxs:max-w-[18rem] xs:max-w-[22rem] md:w-full md:max-w-[44rem] h-auto p-5 rounded-2xl rounded-bl-none">
          <span className="mb-2 font-mono">{name}</span>
          {/* Description and Image  */}
          {image && (
            <div className="">
              <img
                src={`/${image}`}
                alt="Note_Image"
                className="object-contain w-64 h-auto rounded-2xl"
              />
            </div>
          )}
          {text ? `${text}` : ""}
        </div>

        {/* Date */}

        <span className="text-[12px] text-gray-500">{formattedTime(time)}</span>
      </div>
    </div>
  );
};

const SenderChatCard = ({
  profileImage,
  text,
  image,
  time,
  name,
  messageRef,
}) => {
  return (
    <div className="flex justify-end items-end" ref={messageRef}>
      <div className="flex gap-3 items-end my-5 ">
        {/* Search Bar  */}

        <div className="flex flex-col items-end">
          {/* Note Box */}
          <div className="bg-gray-200 text-[#4A4A68] font-semibold flex flex-col gap-1 rounded-y-xl w-full max-w-[12rem] xsm:max-w-[15rem] xxs:max-w-[18rem] xs:max-w-[22rem] md:w-full md:max-w-[44rem] h-auto p-5 rounded-2xl rounded-br-none">
            <span className="mb-2 font-mono">{name}</span>

            {/* Description and Image  */}
            {image && (
              <div className="">
                <img
                  src={`/${image}`}
                  alt="Note_Image"
                  className="object-contain w-64 h-auto rounded-2xl"
                />
              </div>
            )}
            {text ? (
              <span className="">
                {text.split("\n").map((line, index) => (
                  <div key={index} className="break-words">
                    {line}
                    {index < text.split("\n").length - 1 && <br />}{" "}
                    {/* Add <br> after each line except the last one */}
                  </div>
                ))}
              </span>
            ) : (
              ""
            )}
          </div>

          {/* Date */}

          <span className="text-[12px] text-gray-500">
            {formattedTime(time)}
          </span>
        </div>

        {/* User profile Image  */}
        <span className="-translate-y-5">
          <Avatar
            src={profileImage ? `${ImageBaseUrl}/${profileImage}` : ""}
            {...stringAvatar(name)}
            className="uppercase !text-base !h-10 !w-10 rounded-full"
          />
        </span>
      </div>
    </div>
  );
};

import React, { useState } from 'react';

function ChartsAndTables({ table, chart }) {
  const [toggle, setToggle] = useState(true)

  return (
    <div className='mx-auto w-full'>
      <div className='mt-4 mb-2 text-white font-bold text-center'>
        <button
          onClick={() => setToggle(true)}
          className={`tracking-wider uppercase py-3 px-2.5 rounded-l-lg ${toggle ? 'bg-accent' : 'bg-[#8B8B8B]'}`}
        >
          Chart
        </button>
        <button
          onClick={() => setToggle(false)}
          className={`tracking-wider uppercase py-3 px-2.5 rounded-r-lg ${!toggle ? 'bg-accent' : 'bg-[#8B8B8B]'}`}
        >
          Table
        </button>
      </div>
      {
        toggle
          ? chart
          : table
      }
    </div>
  );
}

export default ChartsAndTables;
import React from "react";
import { useRecoilValue } from "recoil";
import { checkListState } from "../../../recoil/report/checkListState";

const NextButton = ({ nextStep, title }) => {
  const checkList = useRecoilValue(checkListState);

  // Function to check if all arrays in checkList are empty
  const areAllArraysEmpty = (obj) => {
    return Object.values(obj).every(
      (arr) => Array.isArray(arr) && arr.length === 0
    );
  };

  return (
    <button
      onClick={nextStep}
      className={`flex items-center justify-center p-2 px-5 text-white font-medium rounded-lg h-12 uppercase text-sm xs:text-lg mt-5 border border-black ${
        areAllArraysEmpty(checkList) ? "bg-[#ED7117]/60" : "bg-[#ED7117]"
      }`}
      disabled={areAllArraysEmpty(checkList)}
    >
      {title || "Next"}
    </button>
  );
};

export default NextButton;

import ChartsAndTables from '../../Blocks/ChartsAndTables'
import ReportWrapper from '../ReportWrapper'
import LineGraph from './LineGraph'
import ReportTable from './ReportTable'

function LineGraphWrapper({ data }) {
  return (
    <ReportWrapper data={data} >
      <ChartsAndTables
        chart={<LineGraph data={data} />}
        table={<ReportTable data={data} />}
      />
    </ReportWrapper>
  )
}

export default LineGraphWrapper
import React, { useEffect, useState, useRef } from "react";
import { useRecoilValue } from "recoil";
import { stepState } from "../../../recoil/report/stepState";
import ReportToExport from "../Export/ReportToExport";
import { useReactToPrint } from "react-to-print";
import { usePatient } from "../../../context/PatientContext";
import PatientName from "../../PatientName";
import moment from "moment";

const DownloadAsPdf = () => {
  const currentStep = useRecoilValue(stepState);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const reportRef = useRef(null);
  const { patient } = usePatient();

  useEffect(() => {
    let timer;
    if (currentStep === 3) {
      timer = setTimeout(() => {
        setIsButtonEnabled(true);
      }, 4000);
    } else {
      setIsButtonEnabled(false);
    }

    return () => clearTimeout(timer);
  }, [currentStep]);

  const handlePrint = useReactToPrint({
    content: () => reportRef.current,
    documentTitle: `${patient?.fname + patient?.lname}_${moment().format(
      "DD-MM-YYYY"
    )}`,
    pageStyle: `
      @page {
        size: A4;
        margin: 5mm;
      }
      @media print {
        body {
          -webkit-print-color-adjust: exact;
        }
      }
    `,
  });

  return (
    <div>
      <div className="absolute invisible pointer-events-none">
        <div ref={reportRef}>
          <ReportToExport />
        </div>
      </div>

      {/* Share Icon */}
      {currentStep === 3 && (
        <button
          className={`bg-[#ED7117] flex items-center justify-center p-2 px-5 text-white font-medium rounded-lg h-12 uppercase text-sm xs:text-lg mt-5 border border-black gap-x-3 ${
            !isButtonEnabled ? "opacity-50" : ""
          }`}
          onClick={handlePrint}
          disabled={!isButtonEnabled}
        >
          {!isButtonEnabled ? "Generating..." : "Pdf"}
          <img
            src="./icons/download.svg"
            alt="Download as Pdf"
            className="w-5 h-5"
          />
        </button>
      )}
    </div>
  );
};

export default DownloadAsPdf;

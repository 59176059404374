import React from 'react';
import { stringAvatar } from '../hooks/convertAvatarInitital';
import { Avatar, AvatarGroup } from '@mui/material';
import { ImageBaseUrl } from '../HTTPS/Api';
import { useLocation } from 'react-router-dom';
import EditPatientProfileForm from '../components/Forms/EditPatientProfileForm';
import MainAreaWrapper from '../layout/MainAreaWrapper';

function EditPatientProfile() {
  const { state: { patient } } = useLocation();

  return (
    <MainAreaWrapper>

      <main className="mt-28 lg:mt-10">
        <div className="h-full rounded-t-3xl  bg-white">
          <div className="mx-auto  p-4 md:p-6 2xl:p-10">
            <div className="mb-6 flex flex-col gap-3 sm:flex-row sm:justify-between sm:first-letter:items-center">
              <div className="">
                <h2 className="text-3xl font-bold text-[#151E3D]">
                  Edit Profile
                </h2>
                {/* <p className="text-gray-600">Manage  information</p> */}
              </div>
            </div>

            {/* <!-- Content  --> */}
            <div className="">
              <div className="flex items-center gap-3">
                <AvatarGroup max={10} className="my-3">
                  {/* displaying the selected patient sepratly so it always come first */}
                  <Avatar
                    {...stringAvatar(`${patient.fname} ${patient.lname}`)}
                    src={patient?.profileImage ? `${ImageBaseUrl}/${patient?.profileImage}` : ""}
                    className="uppercase !text-base"
                  />
                </AvatarGroup>
              </div>
            </div>

            <EditPatientProfileForm patient={patient} />
          </div>
        </div>
        {/* <!-- ===== Main Content End ===== --> */}
      </main>
    </MainAreaWrapper>
  );
}

export default EditPatientProfile;
import VitalScoreDisplayCard from "../components/VitalScoreDisplayCard";
import HeartrateChart from "../components/Charts/HeartrateChart";
import { usePatient } from "../context/PatientContext";
import useLiveVitalQuery from "../hooks/useLiveVitalQuery";
import { fetchHeartrateVital } from "../HTTPS/VitalApis";
import { formatDate, formatTime, roundToOneDecimal } from "../utils/chartUtils";
import MainAreaWrapper from "../layout/MainAreaWrapper";
import { listVitalEventsApi } from "../HTTPS/ApiService";
import { useQuery } from "@tanstack/react-query";
import VitalEventSection from "../components/Blocks/VitalEventSection";
import ChartsAndTables from "../components/Blocks/ChartsAndTables";
import RangeBarTable from "../components/Charts/RangeBarTable";

const HeartRate = () => {
  const { patient } = usePatient();
  
  const { data: heartrateVital } = useLiveVitalQuery(
    "heartrateVital",
    fetchHeartrateVital
  );

  const vitalTypeId = 1  // 1 = Heartrate
  const { data: heartrateEvents } = useQuery({
    queryKey: ["heartrateEvents", patient?.id],
    queryFn: () => listVitalEventsApi(patient?.id, vitalTypeId),
    enabled: !!patient?.id,
    refetchOnWindowFocus: false,
    // Issue 0003 - More config of react query
  });
  
  return (
    <MainAreaWrapper>
      <main className="mt-28 lg:mt-0">
        <div className="mx-auto">
          {/* <!-- ===== Content Area Start ===== --> */}
          <div className="p-2 md:p-6 2xl:p-10">
            <div className="mb-6 gap-3 bg-gradient-to-b from-[#FAFCFEBF]/70 via-[#FAFCFEBF]/70 to-transparent rounded-t-3xl px-2 py-3">
              <div className="">
                <h1 className="text-[#1D3E7D] font-bold text-4xl">Heart Rate</h1>
                <h5 className="text-[#1D3E7D] font-bold">
                  {heartrateVital?.unit}
                </h5>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-between items-center p-2 md:p-6 2xl:p-10">
            <div className="flex justify-center items-center flex-wrap space-x-5 w-96">
              <VitalScoreDisplayCard
                stats={roundToOneDecimal(heartrateVital?.value)}
              />
              <p className="text-white font-semibold text-center text-sm mt-4">
                {heartrateVital?.date
                  ? `Updated on ${formatDate(
                    heartrateVital?.date
                  )} at ${formatTime(heartrateVital?.date)}`
                  : null}
              </p>
            </div>
            {/* Chart Box Starts */}
            <ChartsAndTables
              chart={<HeartrateChart events={heartrateEvents} />}
              table={<RangeBarTable />}
            />
            
          </div>
          {/* Chart Box Ends */}

          {/* whole vital event section */}
          <VitalEventSection
            events={heartrateEvents}
            vitalTypeId={vitalTypeId}
            vitalTitle="Heart Rate"
          />
          
        </div>
      </main>
      {/* <!-- ===== Main Content End ===== --> */}
    </MainAreaWrapper>
  );
};
export default HeartRate;

import React from "react";
import StepStatus from "../StepStatus";
import SubTitle from "../SubTitle";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { allCheckedSelector, checkListState, checkListGrouped, singleCheckedSelector } from "../../../recoil/report/checkListState";

const Step1 = () => {
  const checkList = useRecoilValue(checkListState);
  const [allChecked, setAllChecked] = useRecoilState(allCheckedSelector);
  const setSingleChecked = useSetRecoilState(singleCheckedSelector);

  const handleSelectAll = () => setAllChecked(prev => !prev);

  const handleCheckboxChange = (category, label) => setSingleChecked({ category, label });

  
  return (
    <div>
      <StepStatus
        currentStatus={1}
        currentTitle={"Select Information to Include"}
      />

      {/* Select All CheckBox  */}
      <div className="flex justify-end items-center">
        <FormControlLabel
          control={<Checkbox
            checked={allChecked}
            onClick={handleSelectAll}
          />}
          label={"Select All"}
        />
      </div>

      {/* All Checkboxes  */}
      {Object.keys(checkListGrouped).map((category, i) => (
        <div key={i} className="mt-7">
          <SubTitle title={category} />
          <FormGroup>
            {checkListGrouped[category].map((item, j) => (
              <FormControlLabel
                key={j}
                control={
                  <Checkbox
                    checked={checkList[category].includes(item.label)}
                    onChange={() => handleCheckboxChange(category, item.label)}
                  />}
                label={item?.label}
              />
            ))}
          </FormGroup>
        </div>
      ))}
    </div>
  );
};

export default Step1;

import React from 'react';
import AttachFileIcon from '../Icons/AttachFileIcon';

function AttachFilePopup({ setModal, formik }) {
  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    formik.setFieldValue('file', file);
    setModal(false);
  };
  
  return (
    <div className="bg-white w-44 h-max mb-3 rounded-2xl p-5 flex flex-col shadow-xl border border-gray-200 ml-2 justify-center items-start absolute bottom-14 z-20">
      <label
        htmlFor="File"
        className="flex items-center gap-2 cursor-pointer mb-3"
      >
        <AttachFileIcon />
        <input
          type="file"
          id="File"
          className="hidden"
          onChange={handleFileChange}
        />
        <span>Choose File</span>
      </label>
      <hr className="mb-2 w-full" />
      <label htmlFor="camera" className="flex items-center gap-2">
        <input type="button" className="hidden" /> {/* Modified input */}
        <span onClick={() => {
          formik.setFieldValue('file', null)
          setModal(false);
        }} >
          Cancel
        </span>
      </label>
    </div>
  );
}

export default AttachFilePopup;
// useEventManagement.js

import { useState, useEffect, useRef } from "react";
import {
  createEventApi,
  deleteEventApi,
  editEventApi,
  fetchEventsCategories,
  listEventsApi,
} from "../HTTPS/ApiService";
import { usePatient } from "../context/PatientContext";
import useToast from "./useToast";

const useEventManagement = () => {
  const { patient } = usePatient();
  const [error, setError] = useState(false);
  const { setOpenSuccessToast, setOpenErrorSuccessToast, setToastMessage } =
    useToast();

  // FOR EVENTS
  const [event, setEvent] = useState("");
  const [isImp, setIsImp] = useState(false);
  const [events, setEvents] = useState([]);
  const [eventDate, setEventDate] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [month, setMonth] = useState("");
  const [eventType, setEventType] = useState(null);
  const [editError, setEditError] = useState(null);
  const scrollRef = useRef();

  // Handle Select Categories
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [eventCategories, setEventCategories] = useState([]);

  useEffect(() => {
    fetchEventsCategories(patient?.id).then((data) => {
      setEventCategories(data);
    });
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  const getEventCategoryName = (type) => {
    const category = eventCategories.find((category) => category.id === type);
    return category ? category.text : "";
  };

  // To toggle Categories Modal
  const [categoriesModal, setCategoriesModal] = useState(false);

  const toggleCategoriesModal = () => {
    setCategoriesModal(!categoriesModal);
  };

  const [isLoading, setIsLoading] = useState(false);

  const createEvent = async () => {
    setIsLoading(true);

    if (
      !event.trim() ||
      !eventDate.trim() ||
      !eventTime.trim() ||
      selectedCategory === null
    ) {
      setError(true);
      setIsLoading(false);
      return; // Exit the function if the note is empty
    } else {
      setError(false);
    }

    setSelectedCategory(null);
    setOpenSuccessToast(true);
    setToastMessage("Activity Created");
    try {
      const eventData = {
        id: 1,
        text: event,
        evDate: eventDate,
        evTime: eventTime,
        recipientId: patient?.id,
        type: eventType,
        isImp: isImp,
      };

      await createEventApi(eventData);
      setEvent("");
      fetchEvents();
      if (scrollRef.current) {
        scrollRef.current.scroll({
          top: 0,
          behavior: "smooth",
        });
      }
      setEventDate("");
      setEventTime("");
      setIsImp(false);
      setCategoriesModal(!categoriesModal);
      setMonth(month);
      setIsLoading(false);
    } catch (error) {
      setEvent("Failed to create Event. Try to signIn again!");
      console.log("Error", error);
      setOpenErrorSuccessToast(true);
      setToastMessage("Failed to create Activity");
    }
  };

  // Handle Select Categories
  const [selectedEditCategory, setSelectedEditCategory] = useState(null);
  const [editEventCategories, setEditEventCategories] = useState([]);

  const [selectedEventForEdit, setSelectedEventForEdit] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);

  // Toggle Event Edit Modal
  const toggleEditModal = (event) => {
    setSelectedEventForEdit(event);
    setSelectedEditCategory(event?.type);
    setEditModalVisible(!editModalVisible);
  };

  useEffect(() => {
    fetchEventsCategories(patient?.id).then((data) => {
      setEditEventCategories(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editEvent = async (eventId, editedDate, editedTime, editedText) => {
    try {
      const existingEvent = events.find((event) => event.id === eventId);

      if (!existingEvent) {
        console.error("Event not found");
        return;
      }

      // Fetch event categories if not already fetched
      if (editEventCategories.length === 0) {
        const fetchedCategories = await fetchEventsCategories(patient?.id);
        setEditEventCategories(fetchedCategories);
      }

      // Find the category ID for the edited event
      const editedEventType = editEventCategories.find(
        (category) => category.text === selectedEditCategory
      )?.id;

      const editedEventData = {
        id: eventId,
        text: editedText,
        evDate: editedDate,
        evTime: editedTime,
        recipientId: patient?.id,
        type: editedEventType || existingEvent.type, // Use the existing category if not found
      };

      await editEventApi(editedEventData);
      fetchEvents();
      setOpenSuccessToast(true);
      setToastMessage("Edited Activity successfully");
    } catch (error) {
      console.error("Failed to edit event:", error);
      setOpenErrorSuccessToast(true);
      setToastMessage("Failed Editing Activity");
    }
  };

  const deleteEvent = async (eventId) => {
    try {
      await deleteEventApi(eventId);
      fetchEvents(); // Fetch updated notes after deletion
      setToastMessage("Activity Deleted successfully");
      setOpenSuccessToast(true);
    } catch (error) {
      console.error("Failed to delete event:", error);
      setToastMessage("Failed Deleting Activity");
      setOpenErrorSuccessToast(true);
    }
  };

  const deleteEventHandler = async (eventId) => {
    // Call the API or perform the necessary logic to edit the note
    await deleteEvent(eventId);
    fetchEvents();
  };

  const fetchEvents = async () => {
    try {
      const fetchedEvents = await listEventsApi(patient?.id);
      setEvents(fetchedEvents);
    } catch (error) {
      // Handle the error
      console.error("Failed to fetch events:", error);
    }
  };

  useEffect(() => {
    fetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);

  return {
    event,
    setEvent,
    events,
    eventDate,
    setEventDate,
    eventTime,
    setEventTime,
    month,
    setMonth,
    eventType,
    setEventType,
    editError,
    setEditError,
    selectedCategory,
    setSelectedCategory,
    eventCategories,
    setEventCategories,
    categoriesModal,
    toggleCategoriesModal,
    createEvent,
    isLoading,
    fetchEventsCategories,
    getEventCategoryName,
    toggleEditModal,
    editEvent,
    deleteEventHandler,
    editModalVisible,
    editEventCategories,
    selectedEditCategory,
    setSelectedEditCategory,
    selectedEventForEdit,
    scrollRef,
    error,
    fetchEvents,
    isImp,
    setIsImp,
  };
};

export default useEventManagement;

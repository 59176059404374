const getRandomColor = () => {
  // Define pastel colors
  const pastelColors = [
    "#FFB6C1", // LightPink
    "#FFA07A", // LightSalmon
    "#FFD700", // Gold
    "#FF69B4", // HotPink
    "#20B2AA", // LightSeaGreen
    "#87CEFA", // LightSkyBlue
    "#9370DB", // MediumPurple
    "#F08080", // LightCoral
    "#FF6347", // Tomato
    "#00FF7F", // SpringGreen
  ];

  return pastelColors[Math.floor(Math.random() * pastelColors.length)];
};

const getColorForName = (name) => {
  const storedColors = JSON.parse(localStorage.getItem("avatarColors")) || {};
  if (!storedColors[name]) {
    storedColors[name] = getRandomColor();
    localStorage.setItem("avatarColors", JSON.stringify(storedColors));
  }
  return storedColors[name];
};

export const stringAvatar = (name) => {
  const words = name?.split(" ").filter((word) => !/\s/.test(word));
  const firstWord = words?.length > 0 ? words[0][0] : "";
  const lastWord = words?.length > 1 ? words[words?.length - 1][0] : "";

  const backgroundColor = getColorForName(`${firstWord}${lastWord}`);

  return {
    sx: {
      bgcolor: backgroundColor,
    },
    children: `${firstWord}${lastWord}`,
  };
};

import { useEffect } from 'react';
import moment from 'moment';
import { MonthCalendar } from '@mui/x-date-pickers'

import { endDateWithTimeSelector, startDateState, startDateWithTimeSelector } from '../../../recoil/report/dateRangeState'
import { useRecoilValue, useSetRecoilState } from 'recoil'

function MonthPicker() {
  const startDate = useRecoilValue(startDateState);
  const setStartDateTime = useSetRecoilState(startDateWithTimeSelector)
  const setEndDateTime = useSetRecoilState(endDateWithTimeSelector)

  useEffect(() => {
    setStartDateTime(moment(startDate).startOf('month').format('YYYY-MM-DD'))
    setEndDateTime(moment(startDate).endOf('month').format('YYYY-MM-DD'))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MonthCalendar
      value={moment(startDate)}
      onChange={val => {
        setStartDateTime(val.startOf("month").format("YYYY-MM-DD"))
        setEndDateTime(val.endOf("month").format("YYYY-MM-DD"))
      }}
    />
  )
}

export default MonthPicker
import { useEffect } from "react";

const MonthSelector = ({
  selectedMonth,
  handleMonthClick,
  setSelectedDays,
  setSelectedMonth,
}) => {
  const months = [
    {
      id: "01",
      month: "January",
    },
    {
      id: "02",
      month: "Febuary",
    },
    {
      id: "03",
      month: "March",
    },
    {
      id: "04",
      month: "April",
    },
    {
      id: "05",
      month: "May",
    },
    {
      id: "06",
      month: "June",
    },
    {
      id: "07",
      month: "July",
    },
    {
      id: "08",
      month: "August",
    },
    {
      id: "09",
      month: "September",
    },
    {
      id: "10",
      month: "October",
    },
    {
      id: "11",
      month: "November",
    },
    {
      id: "12",
      month: "December",
    },
  ];

  setSelectedDays("");
  return (
    <div className="flex flex-wrap sm:w-64">
      {months.map((item, index) => (
        <div
          key={item?.id}
          className={`${
            selectedMonth.includes(item?.id)
              ? "bg-indigo-700 text-white"
              : "bg-gray-200"
          } rounded-full p-1 px-2.5 text-xs cursor-pointer m-1 font-semibold`}
          onClick={() => handleMonthClick(item?.id)}
        >
          {item?.month.substring(0, 3)}{" "}
          {/* Display only the first 3 letters of the month */}
        </div>
      ))}
    </div>
  );
};

export default MonthSelector;

import React, { useState } from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoutes = (props) => {
  const { Component } = props;

  const [redirectUrl, setRedirectUrl] = useState(() => {
    const hasSignedIn = localStorage.getItem("hasSignedIn");
    if (!hasSignedIn) {

      localStorage.removeItem("hasSignedIn");
      localStorage.clear();
      return "/signin";

    } else {
      const loginInfo = JSON.parse(localStorage.getItem("user"));
      const now = (new Date()).getTime();

      if (loginInfo?.forcePwdChange) {
        return "/reset/password";
      }
      else if (now > loginInfo?.expiry) {
        
        localStorage.removeItem("hasSignedIn");
        localStorage.clear();

        return "/signin";
      }
      else {
        return "";
      }
    }
  });

  return redirectUrl !== "" ? <Navigate to={redirectUrl} /> : <Component />;
};

export default ProtectedRoutes;

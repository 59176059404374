import React from "react";
import EditIcon from "../Icons/EditIcon";
import { useUser } from "../../context/UserContext";

function VitalTableCard({
  twoValue = false,
  onClick = () => {},
  valOne,
  valTwo,
  date,
  time,
  createdBy,
}) {
  const { user, userDetails } = useUser();

  return (
    <div className="bg-white py-2.5 px-3 mb-2 rounded-lg flex justify-between items-center max-w-lg xl:max-w-xl mx-auto gap-y-2 xl:gap-y-0 flex-wrap">
      <span className="text-customBlue w-32">{date}</span>
      <span className="text-customBlue mr-2">{time}</span>
      <span className="text-xl font-bold text-customBlue w-14">{valOne}</span>
      {twoValue && (
        <span className="text-xl font-bold text-customBlue">{valTwo}</span>
      )}
      <button
        disabled={
          user?.role === "Viewer" ||
          (user?.role === "Kare Giver" && userDetails?.id !== createdBy)
        }
        className={`${
          user?.role === "Viewer" ||
          (user?.role === "Kare Giver" && userDetails?.id !== createdBy)
            ? "opacity-30"
            : ""
        }`}
        onClick={onClick}
      >
        <EditIcon />
      </button>
    </div>
  );
}

export default VitalTableCard;

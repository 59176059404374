import { useRecoilValue } from "recoil";

import StepStatus from "../StepStatus";
import ButtonGroup from "../ButtonGroup";
import WeekPicker from "../Calendars/WeekCalendar";
import CustomDateRangePicker from "../Calendars/CustomDateRangePicker";
import DayPicker from "../Calendars/DayPicker";
import MonthPicker from "../Calendars/MonthPicker";
import { activeButtonState } from "../../../recoil/report/activeButtonState";

const Step2 = () => {
  const activeButton = useRecoilValue(activeButtonState);

  return (
    <div>
      <StepStatus currentStatus={2} currentTitle={"Select Date"} />

      <ButtonGroup />

      <div className="mt-5 flex justify-center items-center overflow-hidden">
        {activeButton === "day" && <DayPicker />}
        {activeButton === "week" && <WeekPicker />}
        {activeButton === "month" && <MonthPicker />}
        {activeButton === "range" && <CustomDateRangePicker />}
      </div>
    </div>
  );
};

export default Step2;

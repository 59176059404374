import { useQuery } from "@tanstack/react-query";

import VitalEventSection from "../components/Blocks/VitalEventSection";
import OxygenChart from "../components/Charts/OxygenChart";
import VitalScoreDisplayCard from "../components/VitalScoreDisplayCard";
import { usePatient } from "../context/PatientContext";
import useLiveVitalQuery from "../hooks/useLiveVitalQuery";
import { listVitalEventsApi } from "../HTTPS/ApiService";
import { fetchOxygenVital } from "../HTTPS/VitalApis";
import MainAreaWrapper from "../layout/MainAreaWrapper";
import { formatDate, formatTime, roundToOneDecimal } from "../utils/chartUtils";
import ChartsAndTables from "../components/Blocks/ChartsAndTables";
import LineChartTable from "../components/Charts/LineChartTable";

const OxygenLevel = () => {
  const { patient } = usePatient();

  const { data: oxygenVital } = useLiveVitalQuery(
    "oxygenVital",
    fetchOxygenVital,
  );

  const vitalTypeId = 4; // 4 = Oxygen
  const { data: oxygenEvents } = useQuery({
    queryKey: ["oxygenEvents", patient?.id],
    queryFn: () => listVitalEventsApi(patient?.id, vitalTypeId),
    enabled: !!patient?.id,
    refetchOnWindowFocus: false,
    // Issue 0003 - More config of react query
  });

  return (
    <MainAreaWrapper>
      <main className="mt-28 lg:mt-0">
        <div className="">
          <div className="mx-auto">
            {/* <!-- ===== Content Area Start ===== --> */}
            <div className="p-2 md:p-6 2xl:p-10">
              <div className="mb-6 gap-3 bg-gradient-to-b from-[#FAFCFEBF]/70 via-[#FAFCFEBF]/70 to-transparent rounded-t-3xl px-2 py-3">
                <div className="">
                  <h1 className="text-[#1D3E7D] font-bold text-4xl">
                    Oxygen Level
                  </h1>
                  <h5 className="text-[#1D3E7D] font-bold">
                    {oxygenVital?.unit}
                  </h5>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-between items-center p-2 md:p-6 2xl:p-10">
            <div className="flex justify-center items-center flex-wrap space-x-5 w-96">
              {/* vital score display */}
              <VitalScoreDisplayCard
                stats={roundToOneDecimal(oxygenVital?.value)}
              />
              <p className="text-white font-semibold text-center text-sm mt-4">
                {oxygenVital?.date
                  ? `Updated on ${formatDate(
                    oxygenVital?.date
                  )} at ${formatTime(oxygenVital?.date)}`
                  : null}
              </p>
            </div>

            {/* Chart Box Starts */}
            
            <ChartsAndTables
              chart={<OxygenChart events={oxygenEvents} />}
              table={<LineChartTable /> }
            />
            {/* Chart Box Ends */}
          </div>

          {/* whole vital event section */}
          <VitalEventSection
            events={oxygenEvents}
            vitalTypeId={vitalTypeId}
            vitalTitle="Oxygen Level"
          />
        </div>
      </main>
    </MainAreaWrapper>
  );
};
export default OxygenLevel;

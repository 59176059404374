import { useEffect } from "react";

const WeekDaySelector = ({
  selectedDays,
  handleDayClick,
  setSelectedMonth,
  setSelectedDays,
}) => {
  const days = [
    {
      id: "0",
      day: "S",
    },
    {
      id: "1",
      day: "M",
    },
    {
      id: "2",
      day: "T",
    },
    {
      id: "3",
      day: "W",
    },
    {
      id: "4",
      day: "T",
    },
    {
      id: "5",
      day: "F",
    },
    {
      id: "6",
      day: "S",
    },
  ];
  
  setSelectedMonth("");
  return (
    <div className="flex">
      {days.map((item, index) => (
        <div
          key={item?.id}
          className={`${
            selectedDays.includes(item?.id)
              ? "bg-indigo-700 text-white"
              : "bg-gray-200"
          } rounded-full p-1 px-2.5 text-xs cursor-pointer mx-1 font-semibold`}
          onClick={() => handleDayClick(item?.id)}
        >
          {item?.day}
        </div>
      ))}
    </div>
  );
};

export default WeekDaySelector;

import { useSetRecoilState } from "recoil";
import { stepState } from "../recoil/report/stepState";

const useStep = () => {
  const setCurrentStep = useSetRecoilState(stepState);

  const nextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, 3));
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const goToStep = (step) => {
    if (step >= 1 && step <= 3) {
      setCurrentStep(step);
    }
  };

  return {
    nextStep,
    prevStep,
    goToStep,
  };
};

export default useStep;

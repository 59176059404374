import api from './AxiosAPI';

const ApiReturnJson = (method, endpoint, requestData = "") => {
  if (method === "GET") {
    return api.get(endpoint + requestData);
  }
  else if (method === "POST-FORMDATA") {
    const headers = {
      "content-type": "multipart/form-data"
    };
    return api.post(endpoint, requestData, { headers: headers });
  }
  else {
    const jsonData = JSON.stringify(requestData);

    const headers = {
      "Content-Type": "application/json"
    };
    return api.post(endpoint, jsonData, { headers: headers });
  }
};

export default ApiReturnJson;
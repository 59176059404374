import React from "react";
import { Link } from "react-router-dom";
import { fetchCommunity } from "../HTTPS/ApiService";
import { useState, useEffect } from "react";
import { ImageBaseUrl } from "../HTTPS/Api";
import { usePatient } from "../context/PatientContext";
import { Avatar } from "@mui/material";
import { stringAvatar } from "../hooks/convertAvatarInitital";
import MessengerService from "../servies/MessengerService";
import {
  getOneToOneChannel,
  getUnreadMessages
} from "../api/messaging-api";
import { useUser } from "../context/UserContext";
import MainAreaWrapper from "../layout/MainAreaWrapper";
import CommunityCard from "../components/UI/CommunityCard";

const Community = () => {
  const [community, setCommunity] = useState([]);
  const [unreadCount, setUnreadCount] = useState([]);
  const { patient } = usePatient();
  const { userDetails } = useUser();
  useEffect(() => {
    if (patient) {
      fetchCommunity(patient?.id).then((data) => {
        localStorage.setItem("communityMembers", JSON.stringify(data));
        setCommunity(data);
        initUnreadMessages();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);

  function initUnreadMessages() {
    const currentUser = JSON.parse(localStorage.getItem("userDetails"));
    const patient = JSON.parse(localStorage.getItem("selectedPatient"));
    let unreadCount = 0;
    let communityMembers =
      JSON.parse(localStorage.getItem("communityMembers")) ?? [];
    let unreadMessages = [];
    communityMembers?.forEach(async (element) => {
      getOneToOneChannel(element?.userId, currentUser.id, patient.id).then(
        async (data) => {
          unreadCount = await getUnreadMessages(
            data.channelName,
            currentUser.id
          );
          unreadMessages.push({
            userId: element?.userId,
            unreadCount: unreadCount.unread,
            patientId: patient.id,
          });
        }
      );
    });
    setTimeout(() => setUnreadCount(unreadMessages), 500);

    setTimeout(() => initUnreadMessages(), 30000);
  }

  const image = userDetails?.profileImage;

  const [messages, setMessages] = useState([]);
  useEffect(() => {
    // destruturing the array: the component was not refreshing for new messages, maybe due to the shallow comparision of the "MessengerService.messages" thus we are destructuring for a time being
    const msgRefresh = () => setMessages([...MessengerService.messages]);
    const msgRefreshInterval = setInterval(msgRefresh, 1000);

    // clearing the interval
    return () => clearInterval(msgRefreshInterval);
  }, []);

  const [, setNewMessageCount] = useState("-");
  useEffect(() => {
    const newMessages = messages.filter((message) => message.isNew);
    setNewMessageCount(newMessages.length);
  }, [messages]);

  return (
    <MainAreaWrapper>
      <main className="h-full mt-28 lg:mt-10">
        <div className="bg-white mx-auto  p-4 md:p-6 2xl:p-10 rounded-t-3xl">
          <div className="mx-auto sm:px-10">
            {/* <!-- Breadcrumb Start --> */}
            <div className="mb-6 flex flex-row items-center justify-between gap-3">
              <h2 className="text-3xl sm:text-5xl font-bold text-[#1D3E7D]">
                Community
              </h2>
            </div>
          </div>

          <div className="sm:px-10">
            <button className="flex items-center gap-4">
              <Link className="rounded-full" to="/myProfile">
                <Avatar
                  src={image ? `${ImageBaseUrl}/${image}` : ""}
                  {...stringAvatar(`${userDetails?.fname} ${userDetails?.lname}`)}
                  className="z-10 overflow-hidden rounded-full object-contain !w-16 !h-16 xxs:!h-20 xxs:!w-20"
                />
              </Link>
              <span className="text-sm xxs:ext-base sm:text-xl max-w-sm text-start">
                View and Communicate with people related to the care
                recipient
              </span>
            </button>
          </div>

          <div className="sm:p-5">
            {/* Cards */}
            {community.map((item, i) => {
              let count = unreadCount.filter(
                (ele) =>
                  ele.userId === item?.userId && ele.patientId === patient.id
              );
              count = count.length > 0 ? count[0].unreadCount : null;
              return (
                <div key={i}>
                  <CommunityCard
                    content={{
                      id: item?.userId,
                      patient: patient,
                      relationship: item?.relationship,
                      image: item?.profileImage,
                      name: item?.name,
                      mobile: item?.mobile,
                      newMessageCount: count,
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </main>
    </MainAreaWrapper >
  );
};

export default Community;
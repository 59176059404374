import React from 'react';

function VitalEventModelContent({ vitalTitle, children }) {
  return (
    <>
      <div className="absolute z-50 rounded-xl flex flex-col sm:h-auto overflow-y-auto sm:overflow-auto py-20 sm:p-0 h-screen justify-center items-center inset-0">
        <h1 className="text-white text-xl md:text-2xl lg:text-3xl font-semibold mb-10 font-mono px-10 text-center">
          Enter Vital ({vitalTitle}) Related Observations or Activities:
        </h1>
        {children}
      </div>
    </>
  );
}

export default VitalEventModelContent;
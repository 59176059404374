import React from 'react';
import ReactApexChart from "react-apexcharts";

import { fetchWeigthGraphData } from '../../HTTPS/GraphApis';
import { extractChartItemsWithMatchingEventDatesForRangeBarGraphs } from '../../utils/chartUtils';
import { groupByDateForLineGraph, localisingTimestamp, processGroupedDataForWeightChart } from '../../utils/reuseableFunc';
import useVitalChartQuery from '../../hooks/useVitalChartQuery';
import { usePatient } from '../../context/PatientContext';


function WeightChart({ events }) {
  const numberOfDaysToFetch = 35;
  const numberOfDaysToShow = 7;
  const { patient } = usePatient();

  const id = "weight";
  const chartName = patient.fname + "_" + id

  // calling chart data
  const { data: chartData, isFetched } = useVitalChartQuery(
    "weigthGraph",
    fetchWeigthGraphData,
    numberOfDaysToFetch
  );

  // Check if chartData is available before rendering the chart
  if (!isFetched || !(chartData?.length > 0)) {
    return <div className="w-full p-1 bg-black/40 rounded-lg"></div>;
  }

  const localizedChartData = localisingTimestamp(chartData, 'date')

  const groupedByDate = groupByDateForLineGraph(localizedChartData)

  const processedData = processGroupedDataForWeightChart(groupedByDate, 'weight', numberOfDaysToShow)

  return (
    <>
      <div className="w-full mt-10 border-8 border-black/40 rounded-lg">
        <ReactApexChart
          type="bar"
          series={[
            {
              name: 'weight',
              type: 'bar',
              data: processedData,
            },
            {
              name: 'event',
              type: 'scatter',
              data: extractChartItemsWithMatchingEventDatesForRangeBarGraphs(events, processedData),
            },
          ]}
          width="100%"
          height="380"
          options={{
            chart: {
              id,
              parentHeightOffset: 0,
              toolbar: {
                show: true,
                export: {
                csv: {
                  filename: chartName,
                  headerCategory: "Date",
                },
                svg: {
                  filename: chartName
                },
                png: {
                  filename: chartName
                }
              }
              },
              foreColor: "#FAFCFE", // color of the legend's text
              background: 'rgba(0, 0, 0, 0.4)',
            },
            colors: ['#FFFA7C'], // color of the bars
            dataLabels: {
              enabled: false,
            },
            markers: { // this is for dot on the graph; alternative of dataLabels
              size: 8,
              strokeWidth: 0,
              colors: '#ED7117ff', // adding alpha values to disable default opacity
            },
            tooltip: { // tooltip setting when we hover over the charts dots
              enabled: false,
            },
            legend: { // this is for name of the graphs at the buttom
              show: false
            },
            fill: {
              opacity: 1 // remove opacity of the bars
            },
            grid: { // bg grid lines of the graph
              borderColor: "rgba(236, 241, 244, 0.4)",
            },
            plotOptions: {
              bar: { // related with range bars
                columnWidth: '70%',
                horizontal: false,
                borderRadius: '4',
              },
            },
            xaxis: {
              type: 'category',
              axisTicks: { show: false },
              labels: {
                style: {
                  colors: "#FAFCFE",
                  fontFamily: "Poppins, Helvetica, Arial, sans-serif",
                  fontWeight: 500,
                },
              },
            },
            yaxis: {
              type: 'numeric',
              labels: {
                style: {
                  colors: "#FAFCFE",
                  fontFamily: "Poppins, Helvetica, Arial, sans-serif",
                  fontWeight: 500,
                },
              },
            }
          }}
        />
      </div>
    </>
  );
}

export default WeightChart;
import { Avatar, AvatarGroup, Box } from "@mui/material";
import { ImageBaseUrl } from "../HTTPS/Api";
import { useEffect, useRef, useState } from "react";
import {
  EditMyProfileApi,
  UploadMyProfileImageApi,
  fetchRelationships,
  fetchUserDetails,
} from "../HTTPS/ApiService";
import { useNavigate } from "react-router-dom";
import { Select } from "@mui/base/Select";
import { Option } from "@mui/base/Option";
import { PatternFormat } from "react-number-format";
import { stringAvatar } from "../hooks/convertAvatarInitital";
import { CachedUser, CachedUserDetails } from "../utils/UserData";
import { useUser } from "../context/UserContext";
import { usePatient } from "../context/PatientContext";
import MainAreaWrapper from "../layout/MainAreaWrapper";
import { useToastContext } from "../context/ToastContext";

const EditMyProfile = () => {
  const navigate = useNavigate();
  const { userDetails, setUserDetails } = useUser();
  const { patient, patientList } = usePatient();
  const [relationship, setRelationships] = useState([]);
  const { setOpenSuccessToast, setOpenErrorSuccessToast, setToastMessage } =
    useToastContext();

  // Combine both useEffects into one
  useEffect(() => {
    const fetchDataAndSetRelationshipId = async () => {
      try {
        const response = await fetchUserDetails(CachedUser()?.accessToken);
        const data = await fetchRelationships();
        setUserDetails(response);
        setRelationships(data);

        const relationshipId =
          patientList.find((item) => patient?.id === item?.id)?.relationship ||
          null;

        setUserDetails((prevUserDetails) => ({
          ...prevUserDetails,
          relationship: relationshipId,
          recipientId: patient?.id,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataAndSetRelationshipId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientList]);

  const [errors, setErrors] = useState({
    fname: "",
    mname: "",
    lname: "",
    phone: "",
    address1: "",
    address2: "",
    relationship: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    profileImage: "",
  });

  const inputRefs = {
    fname: useRef(null),
    lname: useRef(null),
    phone: useRef(null),
    address1: useRef(null),
    address2: useRef(null),
    relationship: useRef(null),
    city: useRef(null),
    state: useRef(null),
    country: useRef(null),
    zipcode: useRef(null),
  };

  const validateField = (fieldName, fieldValue) => {
    if (!fieldValue) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `Please enter ${
          fieldName === "fname" ? "First" : "Last"
        } name`,
      }));
      inputRefs[fieldName].current.scrollIntoView({
        marginTop: 20,
        behavior: "smooth",
      });
      return false;
    }
    return true;
  };

  const validatePhone = (phone) => {
    if (!phone) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: "Please enter valid phone number",
      }));
      inputRefs["phone"].current.scrollIntoView({
        behavior: "smooth",
      });
      return false;
    } else {
      const phoneDigits = phone.replace(/\D/g, ""); // Remove non-digits
      if (phoneDigits.length < 10) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone: "Please enter a valid phone number with at least 10 digits",
        }));
        inputRefs["phone"].current.scrollIntoView({
          behavior: "smooth",
        });
        return false;
      }
    }
    return true;
  };

  const validateForm = () => {
    let isValid = true;

    isValid = validateField("fname", userDetails.fname) && isValid;
    isValid = validateField("lname", userDetails.lname) && isValid;
    isValid = validatePhone(userDetails.mobile) && isValid;

    // Add more validations for other fields...

    return isValid;
  };

  // Function to handle profile image change
  const [selectedImage, setSelectedImage] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
    if (selectedFile.size > maxSizeInBytes) {
      alert("Please select a file smaller than 2 MB.");
      setSelectedImage(null);
      return;
    } else {
      setSelectedImage(selectedFile);
    }

    // Check if the file type is allowed (e.g., PNG or JPG)
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (!allowedTypes.includes(selectedFile.type)) {
      alert("Please select a valid file type (PNG or JPG).");
      setSelectedImage(null);
      return;
    } else {
      setSelectedImage(selectedFile);
    }
  };

  const handleSave = async () => {
    try {
      if (!validateForm()) {
        // Form is not valid, don't proceed with the API call
        if (scrollRef.current) {
          // scrollRef.current.scroll({
          //   top: 0,
          //   behavior: "smooth",
          // });
        }
        return;
      }

      const data = {
        id: CachedUserDetails()?.id,
        file: selectedImage,
      };

      if (selectedImage) {
        await UploadMyProfileImageApi(data);
      }

      setUserDetails((prevUserDetails) => ({
        ...prevUserDetails,
        profileImage: selectedImage, // Assuming 'file' is the property to store the image in userDetails
        recipientId: patient?.id,
      }));

      // Save the updated user details
      const response = await EditMyProfileApi({
        ...userDetails,
        recipientId: patient?.id,
      });
      console.log("RESPONSE IS", response);
      console.log("UserDetails IS", userDetails);

      setSelectedImage(""); // Clear the image preview after updating
      navigate("/myProfile");
      setOpenSuccessToast(true);
      setToastMessage("My Profile Edited Successfully");
    } catch (error) {
      console.error("Unexpected error:", error);
      setOpenErrorSuccessToast(true);
      setToastMessage("Failed to Edit My Profile");
    }
  };

  const scrollRef = useRef();
  const array = [userDetails?.fname, userDetails?.lname];
  const name = array.join(" ");

  return (
    <MainAreaWrapper>
      {/* <!-- ===== Main Content Start ===== --> */}
      <main className="mt-28 lg:mt-10 sm:px-5">
        <div className="h-full rounded-t-3xl  bg-white">
          <div className="mx-auto  p-4 md:p-6 2xl:p-10">
            <div className="mb-6 flex flex-col gap-3 sm:flex-row sm:justify-between sm:first-letter:items-center">
              <div className="">
                <h2 className="text-3xl font-bold text-[#151E3D]">
                  Edit My Profile
                </h2>
                {/* <p className="text-gray-600">Manage  information</p> */}
              </div>
            </div>

            {/* <!-- Content  --> */}
            <div className="">
              <div className="flex items-center gap-3">
                <AvatarGroup max={10} className="my-3">
                  {/* displaying the selected patient sepratly so it always come first */}
                  <Avatar
                    {...stringAvatar(name)}
                    src={
                      userDetails?.profileImage
                        ? `${ImageBaseUrl}/${userDetails?.profileImage}`
                        : ""
                    }
                    className="uppercase !text-base"
                  />
                </AvatarGroup>
              </div>
            </div>

            {/* <!-- Details  --> */}
            <div className="pt-5" ref={inputRefs.fname}>
              <h1 className="font-bold  text-[#4A4A68]">
                First Name <span className="text-[#ff0000]">*</span>
              </h1>
              <input
                name="fname"
                type="text"
                value={userDetails?.fname}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, fname: e.target.value })
                }
                className="w-3/4 h-11  text-base rounded-xl border-2 border-[#4A4A68] px-5 py-4 mt-2"
                placeholder="Enter Here"
              />
            </div>
            {errors.fname && (
              <p className="text-red-500 px-1">{errors.fname}</p>
            )}

            <div className="pt-5">
              <h1 className="font-bold  text-[#4A4A68]">Middle Name</h1>
              <input
                name="mname"
                type="text"
                value={userDetails?.mname}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, mname: e.target.value })
                }
                className="w-3/4 h-11  text-base rounded-xl border-2 border-[#4A4A68] px-5 py-4 mt-2"
                placeholder="Enter Here"
              />
            </div>

            <div className="pt-5" ref={inputRefs.lname}>
              <h1 className="font-bold  text-[#4A4A68]">
                Last Name <span className="text-[#ff0000]">*</span>
              </h1>
              <input
                name="lname"
                type="text"
                value={userDetails?.lname}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, lname: e.target.value })
                }
                className="w-3/4 h-11  text-base rounded-xl border-2 border-[#4A4A68] px-5 py-4 mt-2"
                placeholder="Enter Here"
              />
            </div>
            {errors.lname && (
              <p className="text-red-500 px-1">{errors.lname}</p>
            )}

            <div className="pt-5" ref={inputRefs.phone}>
              <h1 className="font-bold text-[#4A4A68]">
                Phone <span className="text-[#ff0000]">*</span>
              </h1>
              <PatternFormat
                format="(###) #### ###"
                allowEmptyFormatting
                mask="_"
                value={userDetails?.mobile}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, mobile: e.target.value })
                }
                minLength={10}
                className="w-3/4 h-11  text-base rounded-xl border-2 border-[#4A4A68] px-5 py-4 mt-2"
                placeholder="Enter Here"
              />
            </div>
            {errors.phone && (
              <p className="text-red-500 px-1">{errors.phone}</p>
            )}

            <div className="pt-5">
              <h1 className="font-bold text-[#4A4A68]">
                Relationship <span className="text-[#ff0000]">*</span>
              </h1>
              <Select
                value={userDetails?.relationship}
                onChange={(e, v) => {
                  setUserDetails((prevUserDetails) => ({
                    ...prevUserDetails,
                    relationship: v,
                  }));
                }}
                className="w-3/4 h-11 text-base rounded-xl border-2 border-[#4A4A68] px-5 mt-2 self-start text-start"
              >
                <Box className="bg-gray-100 rounded-xl w-96 mt-2  py-3">
                  {relationship.map((item, i) => {
                    return (
                      <Option
                        key={i}
                        className="cursor-pointer p-2 px-5 hover:bg-gray-200"
                        value={item?.id}
                      >
                        {item.text}
                      </Option>
                    );
                  })}
                </Box>
              </Select>
            </div>

            <div className="pt-5">
              <h1 className="font-bold text-[#4A4A68]">Address 1</h1>
              <input
                type="text"
                value={userDetails?.address1}
                onChange={(e) =>
                  setUserDetails({
                    ...userDetails,
                    address1: e.target.value,
                  })
                }
                className="w-3/4 h-11  text-base rounded-xl border-2 border-[#4A4A68] px-5 py-4 mt-2"
                placeholder="Enter Here"
                rows={3}
              />
            </div>
            {errors.address1 && (
              <p className="text-red-500 px-1">{errors.address1}</p>
            )}

            <div className="pt-5">
              <h1 className="font-bold text-[#4A4A68]">Address 2</h1>
              <input
                type="text"
                value={userDetails?.address2}
                onChange={(e) =>
                  setUserDetails({
                    ...userDetails,
                    address2: e.target.value,
                  })
                }
                className="w-3/4 h-11  text-base rounded-xl border-2 border-[#4A4A68] px-5 py-4 mt-2"
                placeholder="Enter Here"
                rows={3}
              />
            </div>
            {errors.address2 && (
              <p className="text-red-500 px-1">{errors.address2}</p>
            )}

            <div className="pt-5">
              <h1 className="font-bold text-[#4A4A68]">City</h1>
              <input
                type="text"
                value={userDetails?.city}
                onChange={(e) =>
                  setUserDetails({
                    ...userDetails,
                    city: e.target.value,
                  })
                }
                className="w-3/4 h-11  text-base rounded-xl border-2 border-[#4A4A68] px-5 py-4 mt-2"
                placeholder="Enter Here"
                rows={3}
              />
            </div>
            {errors.city && <p className="text-red-500 px-1">{errors.city}</p>}

            <div className="pt-5">
              <h1 className="font-bold text-[#4A4A68]">State</h1>
              <input
                type="text"
                value={userDetails?.state}
                onChange={(e) =>
                  setUserDetails({
                    ...userDetails,
                    state: e.target.value,
                  })
                }
                className="w-3/4 h-11  text-base rounded-xl border-2 border-[#4A4A68] px-5 py-4 mt-2"
                placeholder="Enter Here"
                rows={3}
              />
            </div>
            {errors.state && (
              <p className="text-red-500 px-1">{errors.state}</p>
            )}

            <div className="pt-5">
              <h1 className="font-bold text-[#4A4A68]">Country</h1>
              <input
                type="text"
                value={userDetails?.country}
                onChange={(e) =>
                  setUserDetails({
                    ...userDetails,
                    country: e.target.value,
                  })
                }
                className="w-3/4 h-11  text-base rounded-xl border-2 border-[#4A4A68] px-5 py-4 mt-2"
                placeholder="Enter Here"
                rows={3}
              />
            </div>
            {errors.country && (
              <p className="text-red-500 px-1">{errors.country}</p>
            )}

            <div className="pt-5">
              <h1 className="font-bold text-[#4A4A68]">Zip Code</h1>
              <input
                type="text"
                value={userDetails?.zipcode}
                onChange={(e) =>
                  setUserDetails({
                    ...userDetails,
                    zipcode: e.target.value,
                  })
                }
                className="w-3/4 h-11  text-base rounded-xl border-2 border-[#4A4A68] px-5 py-4 mt-2"
                placeholder="Enter Here"
                rows={3}
              />
            </div>
            {errors.zipcode && (
              <p className="text-red-500 px-1">{errors.zipcode}</p>
            )}

            <div className="pt-5">
              <h1 className="font-bold text-[#4A4A68]">Profile Image</h1>

              {/* File input for selecting a new profile image */}
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="w-3/4 text-base px-5mt-2"
              />

              {/* Display selected image preview */}
              {selectedImage && (
                <div className="mt-3">
                  {/* <img
                        src={URL.createObjectURL(selectedImage)}
                        alt="Selected Profile"
                        className="rounded-full w-16 h-16 object-cover"
                      /> */}
                  <Avatar
                    {...stringAvatar(name)}
                    src={URL.createObjectURL(selectedImage)}
                    className="uppercase !text-base"
                  />
                </div>
              )}
            </div>

            <br />
            <div>
              <button
                className="rounded-lg bg-[#ED7117] px-4 py-1.5 text-xl text-white border border-[#4A4A68] mt-2"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </main>
    </MainAreaWrapper>
  );
};

export default EditMyProfile;

import moment from 'moment';
import { atom, selector } from 'recoil';
import { convertLocalDateTimeToUTCDateTime } from '../../utils/chartUtils';

export const startDateState = atom({
  key: 'startDateState',
  default: moment().format("YYYY-MM-DD")
})

export const endDateState = atom({
  key: 'endDateState',
  default: moment().format("YYYY-MM-DD")
})

export const startDateWithTimeSelector = selector({
  key: 'startDateWithTimeSelector',
  get: ({ get }) => {
    const startDate = get(startDateState)
    return convertLocalDateTimeToUTCDateTime(`${startDate}T00:00:00`)
  },
  set: ({ set }, newValue) => {
    set(startDateState, newValue)
  }
})

export const endDateWithTimeSelector = selector({
  key: 'endDateWithTimeSelector',
  get: ({ get }) => {
    const endDate = get(endDateState)
    return convertLocalDateTimeToUTCDateTime(`${endDate}T23:59:59`)
  },
  set: ({ set }, newValue) => {
    set(endDateState, newValue)
  }
})

// export const weekWithTimeSelector = selector({
//   key: 'endDateWithTimeSelector',
//   get: ({ get }) => {
//     const startDate = get(startDateState)
//     const endDate = get(endDateState)
//     return [`${startDate}T00:00:01`, `${endDate}T23:59:59`]
//   },
//   set: ({ set }, moment) => {
//     // Start of the week
//     const startOfWeek = moment.startOf('week').format('YYYY-MM-DD');

//     // End of the week
//     const endOfWeek = moment.endOf('week').format('YYYY-MM-DD');
//   }
// })
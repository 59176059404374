import { Avatar } from "@mui/material";
import { ImageBaseUrl } from "../../HTTPS/Api";
import { stringAvatar } from "../../hooks/convertAvatarInitital";
import PatientName from "../PatientName";

const ContactCard = (props) => {
  const { name, image, relationship, patient, phone } = props.content;

  return (
    <>
      <div className="shadow-lg flex justify-between items-center p-5 rounded-xl bg-gray-100 my-2 w-full">
        {/* Logo  */}
        <div className="flex items-center gap-5">
          <button className="flex items-center gap-4">
            <span className="">
              <Avatar
                src={image ? `${ImageBaseUrl}/${image}` : ""}
                {...stringAvatar(name)}
                className="h-10 w-10 sm:!h-16 sm:!w-16 rounded-full uppercase !text-base xs:!text-lg"
              />
            </span>
          </button>
          {/* Content  */}
          <div className="">
            <h2 className="text-[0.8rem] xxs:text-sm sm:text-xl font-bold">
              {name}
            </h2>
            <h4 className="text-[#8C8CA1] text-[0.7rem] xxs:text-sm font-semibold">
              {relationship && ["Others", "Other"].includes(relationship) ? (
                relationship
              ) : (
                <>
                  <PatientName patient={patient} />
                  's {relationship}
                </>
              )}
            </h4>
          </div>
        </div>
        {/* Icons  */}
        <div className="flex gap-3 items-center">
          <span className=""></span>
          <div className="bg-[#F89739] p-2 px-3.5 rounded-full ">
            <a href={`tel:${phone}`}>
              <svg
                width="16"
                height="28"
                viewBox="0 0 16 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.0841 2.71871V1.69267C13.0841 1.12496 12.6301 0.666626 12.0678 0.666626C11.5055 0.666626 11.0516 1.12496 11.0516 1.69267V2.71871H2.92164C1.79707 2.71871 0.88916 3.63538 0.88916 4.77079V25.2812C0.88916 26.4166 1.79707 27.3333 2.92164 27.3333H13.0789C14.2035 27.3333 15.1114 26.4166 15.1114 25.2812V4.77079C15.1114 3.63538 14.2035 2.71871 13.0841 2.71871ZM4.95413 25.2812H2.92164V23.2291H4.95413V25.2812ZM4.95413 21.177H2.92164V19.125H4.95413V21.177ZM4.95413 17.0781H2.92164V15.026H4.95413V17.0781ZM9.01909 25.2812H6.98661V23.2291H9.01909V25.2812ZM9.01909 21.177H6.98661V19.125H9.01909V21.177ZM9.01909 17.0781H6.98661V15.026H9.01909V17.0781ZM13.0841 25.2812H11.0516V23.2291H13.0841V25.2812ZM13.0841 21.177H11.0516V19.125H13.0841V21.177ZM13.0841 17.0781H11.0516V15.026H13.0841V17.0781ZM13.0841 11.9479C13.0841 12.5156 12.6301 12.9739 12.0678 12.9739H3.93788C3.3756 12.9739 2.92164 12.5156 2.92164 11.9479V5.79683C2.92164 5.22913 3.3756 4.77079 3.93788 4.77079H12.0627C12.6249 4.77079 13.0789 5.22913 13.0789 5.79683V11.9479H13.0841Z"
                  fill="#FAFCFE"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactCard;
import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Avatar } from '@mui/material';
import GradeOutlinedIcon from "@mui/icons-material/GradeOutlined";
import GradeIcon from "@mui/icons-material/Grade";

import { getCorrectDateTimeString, getDayFromDate, getMonthFromDate, getTimeFromDate } from '../../utils/chartUtils';
import { ImageBaseUrl } from '../../HTTPS/Api';
import { stringAvatar } from '../../hooks/convertAvatarInitital';
import EditIcon from '../Icons/EditIcon';
import HandBagIcon from '../Icons/HandBagIcon';
import { useUser } from '../../context/UserContext';
import { deleteVitalEventApi, toggleVitalEvent } from '../../HTTPS/ApiService';
import { usePatient } from '../../context/PatientContext';
import { useToastContext } from '../../context/ToastContext';

function VitalEventFunc({ item, setEditActivityOpen, setActivityToBeEdited }) {
  const [clamp, setClamp] = useState(false);
  const queryClient = useQueryClient();
  const { patient } = usePatient();
  const { user, userDetails } = useUser();
  const { setOpenSuccessToast, setOpenErrorSuccessToast, setToastMessage } = useToastContext();


  const { mutate: onDelete } = useMutation({
    mutationFn: async (id) => await deleteVitalEventApi(id),
    onSuccess: () => {
      queryClient.invalidateQueries(["temperatureEvents", patient?.id]);
      setToastMessage("Vital Activity Deleted Successfully");
      setOpenSuccessToast(true);
    },
    onError: () => {
      setToastMessage("Failed Deleting Vital Activity");
      setOpenErrorSuccessToast(true);
    },
  });

  const { mutate: toggleImp } = useMutation({
    mutationFn: async ({ id, isImp }) => await toggleVitalEvent(id, !isImp),
    onSuccess: () => {
      queryClient.invalidateQueries(["temperatureEvents", patient?.id]);
    },
    onError: () => { },
  });

  const onDeleteHandler = (id) => {
    const userConfirmed = window.confirm(
      "Are you sure you want to delete the Activity?"
    );
    if (userConfirmed) {
      // Call the onDelete function passed from the parent component
      onDelete(id);
    }
  }

  return (
    <div>
      <div
        className="flex space-x-2 sm:space-x-4 w-full h-full bg-[#151E3D]/60 my-2 rounded-xl"
      >
        <div className="bg-[#0492C2] flex flex-col p-2 rounded-xl justify-center items-center min-w-[5rem]">
          <span className="text-white text-xl sm:text-2xl font-bold">
            {getDayFromDate(getCorrectDateTimeString(item.evDate, item.evTime))}
          </span>
          <span className="text-white text-sm">
            {getMonthFromDate(getCorrectDateTimeString(item.evDate, item.evTime))}
          </span>
          <span className="text-white text-[13px]">
            {getTimeFromDate(getCorrectDateTimeString(item.evDate, item.evTime))}
          </span>
        </div>
        <div className="flex flex-wrap sm:flex-nowrap justify-between items-center w-full">
          {/* User Event Text And Image  */}
          <div className="flex items-center space-x-2 py-5">
            <span className="min-w-max">
              <Avatar
                src={
                  item?.profileImage
                    ? `${ImageBaseUrl}/${item?.profileImage}`
                    : ""
                }
                {...stringAvatar(item?.user)}
                className="w-10 h-10 rounded-full uppercase !text-base"
              />
            </span>

            <div className="">
              {/* Event Desc  */}
              <span
                className={`text-start text-[13px] sm:text-base line-clamp-2 text-white max-w-[5rem] xs:max-w-[10rem] sm:max-w-xl md:max-w-full after:text-red-500 ${clamp ? "line-clamp-none" : ""
                  }`}
                onClick={() => setClamp((prev) => !prev)}
              >
                {item.text}
              </span>
            </div>
          </div>

          <div className="flex pr-2 space-x-2 sm:px-3 sm:space-x-3">
            <span
              onClick={() => toggleImp({ id: item.id, isImp: item.isImp })}
              className="text-gray-500 flex justify-center items-center space-x-2 cursor-pointer"
            >
              {item.isImp ? (
                <GradeIcon className="text-[#ED7117] !text-4xl" />
              ) : (
                <GradeOutlinedIcon className="text-[#ED7117] !text-4xl" />
              )}
            </span>

            {/* Edit Button */}
            <button
              onClick={() => {
                setActivityToBeEdited(item)
                setEditActivityOpen(true)
              }}
              disabled={
                user?.role === "Viewer" ||
                (user?.role === "Kare Giver" &&
                  userDetails?.id !== item?.userId)
              }
              className={`${user?.role === "Viewer" ||
                (user?.role === "Kare Giver" &&
                  userDetails?.id !== item?.userId)
                ? "opacity-30"
                : ""
                }`}
            >
              <span className="w-5 h-5 sm:w-6 sm:h-6">
                <EditIcon stroke="#fff" />
              </span>

            </button>
            {/* Delete Edit BUtton  */}
            <button
              onClick={() => onDeleteHandler(item.id)}
              disabled={
                user?.role === "Viewer" ||
                (user?.role === "Kare Giver" &&
                  userDetails?.id !== item?.userId)
              }
              className={`${user?.role === "Viewer" ||
                (user?.role === "Kare Giver" &&
                  userDetails?.id !== item?.userId)
                ? "opacity-30"
                : ""
                }`}
            >
              <span className="w-5 h-5 sm:w-6 sm:h-6">
                <HandBagIcon />
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="flex items-center space-x-2 mb-2 ">
        {/* <span className="text-black text-[12px]">{item?.user}</span>
              <span className="text-[12px] text-gray-500">
                {formattedTimeStamp(item?.createdDate)}
              </span> */}
      </div>
    </div>
  );
}

export default VitalEventFunc;
// api.js
export const BE_URL = process.env.REACT_APP_MK_ADMIN_API;

export const URL = process.env.REACT_APP_MK_FRONT_API;
//export const URL = "https://localhost:7064";
export const BASE_URL = `${URL}/api/1`;
export const ImageBaseUrl = `${URL}`;

export const loginEndpoint = `${BASE_URL}/login`;
export const googleLoginEndpoint = `${BASE_URL}/auth/google/login`;
export const refreshTokenEndpoint = `${BASE_URL}/refresh-token`;
export const resetPasswordEndpoint = `${BASE_URL}/reset-password`;
export const patientListEndpoint = `${BASE_URL}/kare-recipient/assigned`;

export const createNoteEndpoint = `${BASE_URL}/journal/create-note`;
export const listNoteEndpoint = `${BASE_URL}/journal/list-note`;
export const EditNoteEndpoint = `${BASE_URL}/journal/edit-note`;
export const DeleteNoteEndpoint = `${BASE_URL}/journal/delete-note`;
export const listEventEndpoint = `${BASE_URL}/journal/list-event`;
export const createEventEndpoint = `${BASE_URL}/journal/create-event`;
export const EditEventEndpoint = `${BASE_URL}/journal/edit-event`;
export const DeleteEventEndpoint = `${BASE_URL}/journal/delete-event`;
export const ToggleNoteEndpoint = `${BASE_URL}/journal/toggle-note-important`;
export const ToggleEventEndpoint = `${BASE_URL}/journal/toggle-event-important`;

export const listVitalEventEndpoint = `${BASE_URL}/vital-event/list`;
export const createVitalEventEndpoint = `${BASE_URL}/vital-event/create`;
export const EditVitalEventEndpoint = `${BASE_URL}/vital-event/edit`;
export const DeleteVitalEventEndpoint = `${BASE_URL}/vital-event/delete`;
export const ToggleVitalEventEndpoint = `${BASE_URL}/vital-event/toggle-event-important`;
export const listVitalTypeEndpoint = `${BASE_URL}/vital-type/list`;

export const listVitalEndpoint = `${BASE_URL}/vital/provider/tryvital/list`;

export const fetchContactsEndpoint = `${BASE_URL}/contact/contacts`;
export const fetchCommunityEndpoint = `${BASE_URL}/contact/community`;

export const fetchEventsCategoriesEndpoint = `${BASE_URL}/mastervalue/events`;
export const fetchRelationshipsEndpoint = `${BASE_URL}/mastervalue/relationships`;

// For Patients
export const patientDetailsEndpoint = `${BASE_URL}/kare-recipient/details`;
export const EditProfileEndpoint = `${BASE_URL}/kare-recipient/update`;
export const UploadPatientProfileImageEndpoint = `${BASE_URL}/kare-recipient/profile-image`;

// For Me
export const userDetailsEndpoint = `${BASE_URL}/user-details`;
export const EditMyProfileEndpoint = `${BASE_URL}/user-profile-update`;
export const UploadMyProfileImageEndpoint = `${BASE_URL}/profile-image`;

// Calendar APIS
export const CalendarScheduleCreateEndpoint = `${BASE_URL}/calendar-schedule/create`;
export const CalendarScheduleEditEndpoint = `${BASE_URL}/calendar-schedule/edit`;
export const CalendarScheduleSelectEndpoint = `${BASE_URL}/calendar-schedule/select`;
export const CalendarScheduleDeleteEndpoint = `${BASE_URL}/calendar-schedule/delete`;
export const CalendarScheduleListDayEndpoint = `${BASE_URL}/calendar-schedule/daily`;
export const CalendarScheduleListMonthEndpoint = `${BASE_URL}/calendar-schedule/monthly`;

// graph apis
export const fetchSleepGraphDataEndpoint = `${BASE_URL}/graph/sleep`;
export const fetchBloodPressureGraphDataEndpoint = `${BASE_URL}/graph/blood-pressure`;
export const fetchOxygenGraphDataEndpoint = `${BASE_URL}/graph/oxygen`;
export const fetchTemperatureGraphDataEndpoint = `${BASE_URL}/graph/temperature`;
export const fetchHeartrateGraphDataEndpoint = `${BASE_URL}/graph/heart-rate`;
export const fetchRespiratoryRateGraphDataEndpoint = `${BASE_URL}/graph/respiratory-rate`;
export const fetchGlucoseGraphDataEndpoint = `${BASE_URL}/graph/glucose`;
export const fetchWeigthGraphDataEndpoint = `${BASE_URL}/graph/weight`;

// report 
export const fetchReportNoteEndpoint = `${BASE_URL}/report/note`;
export const fetchReportEventEndpoint = `${BASE_URL}/report/event`;
export const fetchReportBloodPressureEndpoint = `${BASE_URL}/report/vital/bloodpressure`;
export const fetchReportSleepEndpoint = `${BASE_URL}/report/vital/sleep`;
export const fetchReportOtherVitalEndpoint = `${BASE_URL}/report/vital/other`;
export const fetchReportVitalEventEndpoint = `${BASE_URL}/report/vital-event`;

// live vital apis; Dashboard apis
export const fetchHeartrateVitalDataEndpoint = `${BASE_URL}/dashboard/heart-rate`;
export const fetchBloodPressureVitalDataEndpoint = `${BASE_URL}/dashboard/blood-pressure`;
export const fetchSleepVitalDataEndpoint = `${BASE_URL}/dashboard/sleep`;
export const fetchOxygenVitalDataEndpoint = `${BASE_URL}/dashboard/oxygen`;
export const fetchRespiratoryRateVitalDataEndpoint = `${BASE_URL}/dashboard/respiratory-rate`;
export const fetchTemperatureVitalDataEndpoint = `${BASE_URL}/dashboard/temperature`;
export const fetchGlucoseVitalDataEndpoint = `${BASE_URL}/dashboard/glucose`;
export const fetchWeigthVitalDataEndpoint = `${BASE_URL}/dashboard/weight`;

export const fetchEventsCountEndpoint = `${BASE_URL}/dashboard/events`;
export const fetchEventsListEndpoint = `${BASE_URL}/dashboard/event-list`;

export const fetchReminderCountDataEndpoint = `${BASE_URL}/dashboard/reminders/count`;
export const fetchReminderListDataEndpoint = `${BASE_URL}/dashboard/reminders/list`;
export const reminderCreateEventDataEndpoint = `${BASE_URL}/dashboard/reminders/create-event`;

// manual add vital API
export const postManualAddVitalsDataEndpoint = `${process.env.REACT_APP_MK_ADMIN_API}/api/1/vital/provider/tryvital`;

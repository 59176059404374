import { useToastContext } from "../context/ToastContext";

const useToast = () => {
  const {
    openSuccessToast,
    openErrorSuccessToast,
    setOpenSuccessToast,
    setOpenErrorSuccessToast,
    toastMessage,
    setToastMessage,
  } = useToastContext();

  return {
    openSuccessToast,
    openErrorSuccessToast,
    setOpenSuccessToast,
    setOpenErrorSuccessToast,
    toastMessage,
    setToastMessage,
  };
};

export default useToast;

import { useQuery } from "@tanstack/react-query";

import { usePatient } from "../context/PatientContext";
import VitalScoreDisplayCard from "../components/VitalScoreDisplayCard";
import GlucoseChart from "../components/Charts/GlucoseChart";
import { fetchGlucoseVital } from "../HTTPS/VitalApis";
import useLiveVitalQuery from "../hooks/useLiveVitalQuery";
import { formatDate, formatTime, roundToOneDecimal } from "../utils/chartUtils";
import MainAreaWrapper from "../layout/MainAreaWrapper";
import VitalEventSection from "../components/Blocks/VitalEventSection";
import { listVitalEventsApi } from "../HTTPS/ApiService";
import ChartsAndTables from "../components/Blocks/ChartsAndTables";
import BarChartTable from "../components/Charts/BarChartTable";

function Glucose() {
  const { patient } = usePatient();

  const { data: glucoseVital } = useLiveVitalQuery(
    "glucoseVital",
    fetchGlucoseVital
  );

  const vitalTypeId = 7  // 7 = Glucose
  const { data: glucoseEvents } = useQuery({
    queryKey: ["glucoseEvents", patient?.id],
    queryFn: () => listVitalEventsApi(patient?.id, vitalTypeId),
    enabled: !!patient?.id,
    refetchOnWindowFocus: false,
    // Issue 0003 - More config of react query
  });

  return (
    <MainAreaWrapper>
      <main className="mt-28 lg:mt-0">
        <div className="mx-auto ">
          <div className="p-2 md:p-6 2xl:p-10">
            {/* <!-- ===== Content Area Start ===== --> */}
            <div className="mb-6 gap-3 bg-gradient-to-b from-[#FAFCFEBF]/70 via-[#FAFCFEBF]/70 to-transparent rounded-t-3xl px-2 py-3">
              <div className="">
                <h1 className="text-[#1D3E7D] font-bold text-4xl">Glucose</h1>
                <h5 className="text-[#1D3E7D] font-bold">mmol/L</h5>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-between items-center p-2 md:p-6 2xl:p-10">
            <div className="flex justify-center items-center flex-wrap space-x-5 w-96">
              {/* vital score display */}
              <VitalScoreDisplayCard
                stats={roundToOneDecimal(glucoseVital?.value)}
              />
              <p className="text-white font-semibold text-center text-sm mt-4">
                {glucoseVital?.date
                  ? `Updated on ${formatDate(
                    glucoseVital?.date
                  )} at ${formatTime(glucoseVital?.date)}`
                  : null}
              </p>
            </div>
            {/* Chart Box Starts */}
            <ChartsAndTables
              chart={<GlucoseChart events={glucoseEvents} />}
              table={<BarChartTable />}
            />
          </div>
          {/* Chart Box Ends */}

          {/* whole vital event section */}
          <VitalEventSection
            events={glucoseEvents}
            vitalTypeId={vitalTypeId}
            vitalTitle="Glucose"
          />
        </div>
      </main>
      {/* <!-- ===== Main Content End ===== --> */}
    </MainAreaWrapper>
  );
}

export default Glucose;

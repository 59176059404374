import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import {
  formatDate,
  formatTime,
  getCorrectDateTimeString,
} from "../../../utils/chartUtils";
import { useState } from "react";

function ReportTable({ data }) {
  // For Pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const noLocalizationOn = ["heart rate", "blood pressure", "sleep", "weight", "oxygen level", "breathing rate", "temperature", "glucose"];
  const isDateLocalized = !noLocalizationOn.includes(data.label.toLowerCase());

  // modifying date-time and localizing it as well
  // also modifying the titles of the table
  const modData = data?.res?.map((item, i) => {
    const date = item.evDate || item.ntDate || item.date;
    const time = item.evTime || item.ntTime || item.date?.split("T").at(1);

    // Create the base object with conditional properties
    return {
      Date: formatDate(getCorrectDateTimeString(date, time), isDateLocalized),
      Description: item?.text,
      // blood pressure related
      ...(('diamin' in item) && { 'Diastolic Min': item.diamin }), // Conditionally add
      ...(('diamax' in item) && { 'Diastolic Max': item.diamax }), // Conditionally add
      ...(('diaavg' in item) && { 'Diastolic Avg': item.diaavg }), // Conditionally add
      ...(('diamedian' in item) && { 'Diastolic Median': item.diamedian }), // Conditionally add
      ...(('sysmin' in item) && { 'Systolic Min': item.sysmin }), // Conditionally add
      ...(('sysmax' in item) && { 'Systolic Max': item.sysmax }), // Conditionally add
      ...(('sysavg' in item) && { 'Systolic Avg': item.sysavg }), // Conditionally add
      ...(('sysmedian' in item) && { 'Systolic Median': item.sysmedian }), // Conditionally add

      ...item, // Spread the original properties
      ...(('isImp' in item) && { Starred: item.isImp }), // Conditionally add Starred
      ...(!item.date && { Time: formatTime(getCorrectDateTimeString(date, time)) }), // Conditionally add Time
      ...(item?.typeName && { "Category": item.typeName }), // Conditionally add "Category"
      ...(item?.user && { "Created By": item.user }), // Conditionally add "Created By"
    };
  });

  const tableKeys = Object.keys(modData.at(0));

  // finding the keys who values are not significant
  const emptyKeys = tableKeys.filter((key) =>
    modData.every((item) => item[key] === undefined || item[key] === null)
  );

  let titleToRemove = [
    // blood pressure related
    "diamin",
    "diamax",
    "diaavg",
    "diamedian",
    'sysmin',
    'sysmax',
    'sysavg',
    'sysmedian',

    "id",
    "date",
    "createdDate",
    "recipientId",
    "userId",
    "vitalTypeId",
    "evDate",
    "evTime",
    "ntDate",
    "ntTime",
    "text",
    "user",
    "type",
    "typeName",
    "isImp",
    "profileImage",
    "attachment",
  ].concat(emptyKeys); // titles which we don't want to show in the table

  const tableTitle = tableKeys.filter(
    (title) => !titleToRemove.includes(title)
  );

  // Calculate the slice of data to display based on current page and rows per page
  const startIndex = currentPage * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const displayedData = modData.slice(startIndex, endIndex) || [];

  // Handle pagination change
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0); // Reset to the first page when changing rows per page
  };

  return (
    <>
      <TableContainer
        component={Paper}
        className="!shadow-xl shadow-blue-500/20 !bg-gray-200"
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className="bg-gray-300">
            <TableRow>
              {tableTitle.map((item) => (
                <TableCell className="!text-[#151E3D99]/[95%] !border-b !border-gray-600 !font-bold !text-lg">
                  {item.charAt(0).toUpperCase() + item.slice(1)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedData.map((item, i) => (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className="hover:!bg-gray-300"
              >
                {tableTitle.map((key) =>
                  key === "Starred" ? (
                    <TableCell className="!text-black">
                      {Boolean(item[key]) ? "Yes" : "No"}
                    </TableCell>
                  ) : (
                    <TableCell className="!text-black max-w-[28rem] !min-w-[8rem]">
                      {Boolean(item[key]) ? item[key] : "NA"}
                    </TableCell>
                  )
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={modData.length} // Total pages based on items per page
        page={currentPage} // Current page
        onPageChange={handlePageChange} // Handle page change
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleRowsPerPageChange}
        showFirstButton={true}
        showLastButton={true}
      // rowsPerPageOptions={[5, 10, 25, 50, 100]}
      />
    </>
  );
}

export default ReportTable;

import { Badge } from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers';
import moment from "moment";
import React from 'react'

function ServerDay({ highlightedDays = [], day, outsideCurrentMonth, ...other }) {

  const isSelected = !outsideCurrentMonth && highlightedDays.some((date) => moment(date).isSame(day, "day"));

  return (
    <Badge
      key={day.toString()}
      overlap="circular"
      badgeContent={
        isSelected
          ? <span className="bg-[#ED7117] rounded-full p-1"></span>
          : undefined
      }
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
}

export default ServerDay
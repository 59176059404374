import React, { useEffect, useState } from "react";
import { ImageBaseUrl } from "../HTTPS/Api";
import { fetchPatientList } from "../HTTPS/ApiService";
import { Avatar, Badge, Box, Typography } from "@mui/material";
import { usePatient } from "../context/PatientContext";
import PatientName from "./PatientName";
import { stringAvatar } from "../hooks/convertAvatarInitital";
import { Link, useNavigate } from "react-router-dom";

const UserAssignee = () => {
  const {
    patient,
    setPatient,
    patientList,
    setPatientList,
    updateSelectedPatient,
  } = usePatient();

  useEffect(() => {
    if (patient) {
      (async () => {
        try {
          const list = await fetchPatientList();
          setPatientList(list);
        } catch (error) {
          console.error("Error fetching patient details:", error);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);

  const navigate = useNavigate();
  const currentIndex = patientList.findIndex((item) => item.id === patient.id);
  // Separate the current patient from the rest
  const currentPatient = currentIndex !== -1 ? patientList[currentIndex] : null;

  // const patientSelectHandler = async (selectedPatient) => {
  //   try {
  //     await updateSelectedPatient(selectedPatient);
  //     setPatient(selectedPatient);
  //   } catch (error) {
  //     console.error("Error updating default patient:", error);
  //   }
  // };

  // const [patientAssignedModal, setPatientAssignedModal] = useState(false);

  // const togglePatientAssignedModal = () => {
  //   setPatientAssignedModal(!patientAssignedModal);
  // };

  return (
    <div
      className="flex items-center"
      // style={{ marginTop: "5rem" }}
    >
      <div className="relative ">
        <div className="dropdown">
          <span className="flex items-center gap-4 rounded-full cursor-pointer">
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              className="z-0"
              badgeContent={
                <Link to="/allPatients" className="bg-white p-0.5 rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      stroke="#101010"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                      strokeWidth="1.5"
                      d="M19.92 8.95l-6.52 6.52c-.77.77-2.03.77-2.8 0L4.08 8.95"
                    ></path>
                  </svg>
                </Link>
              }
            >
              {patientList?.map((item, i) => {
                const array = [item?.fname, item?.lname];
                const name = array.join(" ");
                return (
                  patient?.id === item?.id && (
                    <button
                      key={i}
                      onClick={() => {
                        navigate("/viewPatientDetails", {
                          state: { patient: currentPatient },
                        });
                      }}
                    >
                      <Avatar
                        key={item?.id}
                        src={
                          item?.profileImage
                            ? `${ImageBaseUrl}/${item?.profileImage}`
                            : ""
                        }
                        className="rounded-full object-contain uppercase !text-base"
                        sx={{
                          width: 32,
                          height: 32,
                        }}
                        {...stringAvatar(name)}
                      />
                    </button>
                  )
                );
              })}
            </Badge>
          </span>

          {/* dropdown content */}
          {/* {patientAssignedModal && (
            <ul
              tabIndex={0}
              className="dropdown-content absolute top-16 left-0 border shadow pt-4 px-5 w-52 flex-col rounded-2xl bg-white z-[999999]"
            >
              {patientList?.length !== 0
                ? patientList?.map((ptn, i) => {
                    const array = [ptn?.fname, ptn?.lname];
                    const name = array.join(" ");
                    return (
                      <Box
                        key={i}
                        className="flex justify-start items-center mb-4"
                        onClick={(e) => patientSelectHandler(ptn)}
                      >
                        <span
                          onClick={togglePatientAssignedModal}
                          className="flex items-center"
                        >
                          <Avatar
                            src={
                              ptn?.profileImage
                                ? `${ImageBaseUrl}/${ptn?.profileImage}`
                                : ""
                            }
                            {...stringAvatar(name)}
                            className="cursor-pointer uppercase !text-base"
                          />

                          <Typography className="cursor-pointer" ml={1.5}>
                            <span className="capitalize">
                              <PatientName patient={ptn} />
                            </span>
                          </Typography>
                        </span>
                      </Box>
                    );
                  })
                : null}
            </ul>
          )} */}
        </div>
      </div>

      {patient ? (
        <h2 className="text-lg font-bold text-white capitalize ml-3 hidden sm:flex">
          <PatientName patient={patient} /> &nbsp;
        </h2>
      ) : (
        <h2 className="text-lg font-bold text-white invisible"> </h2>
      )}
    </div>
  );
};

export default UserAssignee;

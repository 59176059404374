import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import { roundToOneDecimal } from '../../../utils/chartUtils';
import { usePatient } from '../../../context/PatientContext';

function LineGraph({ data }) {
  const { patient } = usePatient();

  const id = data.label.replace(' ', '');
  const chartName = patient.fname + "_" + id
  const dataMod = data.res.map(item => ({ ...item, date: moment(item.date).valueOf() }));

  // dynamically developing the graphs data
  const linesToRemove = ['date', 'median', 'diamedian', 'sysmedian', 'diaavg', 'sysavg'];
  const dataLines = Object.keys(data.res.at(0)).filter(line => !linesToRemove.includes(line));

  const seriesData = dataLines.map(lineName => ({
    name: lineName,
    type: 'line',
    data: dataMod.map(item => ([item.date, roundToOneDecimal(item[lineName])]))
  }));

  return (
    <div className='w-full border-8 border-[#151E3D]/90 rounded-xl'>
      <ReactApexChart
        type="line"
        series={seriesData}
        width="100%"
        height="380"
        options={{
          chart: {
            id,
            parentHeightOffset: 0,
            toolbar: {
              show: true,
              export: {
                csv: {
                  filename: chartName,
                  headerCategory: "Date",
                },
                svg: {
                  filename: chartName
                },
                png: {
                  filename: chartName
                }
              }
            },
            foreColor: "#FAFCFE", // color of the legend's text
            background: 'rgba(21, 30, 61, 0.9)',
          },
          stroke: {
            width: 3,
            // curve: "smooth",
            // colors: [], // colors the sleep lines
          },
          dataLabels: {
            enabled: false,
          },
          markers: { // this is for dot on the graph
            size: [0, 0, 0],
            strokeWidth: 0,
            colors: ['#ED7117ff'],
          },
          tooltip: { // tooltip setting when we hover over the charts dots
            enabled: false,
          },
          legend: { // this is for name of the graphs at the bottom
            markers: {
              // eslint-disable-next-line no-sparse-arrays
              fillColors: [, , , , '#ED7117ff']
            }
          },
          grid: { // bg grid lines of the graph
            borderColor: "rgba(236, 241, 244, 0.4)",
          },
          xaxis: {
            type: "datetime",
            axisTicks: { show: true },
            labels: {
              style: {
                colors: "#FAFCFE",
                fontFamily: "Poppins, Helvetica, Arial, sans-serif",
                fontWeight: 500,
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                colors: "#FAFCFE",
                fontFamily: "Poppins, Helvetica, Arial, sans-serif",
                fontWeight: 500,
              },
            },
          },
        }}
      />
    </div>
  );
}

export default LineGraph;
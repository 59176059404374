import React, { createContext, useContext, useState, useEffect } from "react";
import MessengerService from "../servies/MessengerService";
import { fetchCommunity } from "../HTTPS/ApiService";

const PatientContext = createContext();

export const usePatient = () => {
  return useContext(PatientContext);
};

export const PatientProvider = ({ children }) => {
  const [patient, setPatient] = useState(null);
  const [patientList, setPatientList] = useState([]);

  useEffect(() => {
    const storedPatient = localStorage.getItem("selectedPatient");
    if (storedPatient) {
      setPatient(JSON.parse(storedPatient));
      MessengerService.setSelectedPatient(storedPatient.id);
      if (storedPatient?.id) {
        fetchCommunity(storedPatient?.id).then((data) => {
          localStorage.setItem("communityMembers", JSON.stringify(data));
        });
      }
    }
  }, []);

  const updatePatientList = (newPatient) => {
    const patientListCopy = [...patientList];
    for (let i = 0; i < patientListCopy.length; i++) {
      if (patientListCopy[i].id === newPatient.id) {
        patientListCopy[i] = newPatient;
        break; // Once replaced, no need to continue looping
      }
    }
    setPatientList(patientListCopy);
  };

  const updateSelectedPatient = (selectedPatient) => {
    MessengerService.setSelectedPatient(selectedPatient.id);
    setPatient(selectedPatient);
    // Store the selected patient in localStorage
    localStorage.setItem("selectedPatient", JSON.stringify(selectedPatient));
  };

  return (
    <PatientContext.Provider
      value={{
        patient,
        setPatient,
        updateSelectedPatient,
        patientList,
        setPatientList,
        updatePatientList,
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};

import { Button, Divider } from "@mui/material";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { 
  resetPasswordUser,
  signOutUser
} from "../HTTPS/ApiService";

const ResetPassword = () => {
  
  const currentPasswordRef = useRef();
  const newPasswordRef = useRef();
  const confirmNewPasswordRef = useRef();

  const onLogout = () => {
    signOutUser();
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Get entered email and password
    const enteredCurrentPassword = currentPasswordRef.current.value.toLowerCase();
    const enteredNewPassword = newPasswordRef.current.value.toLowerCase();
    const enteredConfirmNewPassword = confirmNewPasswordRef.current.value.toLowerCase();

    try {
      // Login user and fetch details
      await resetPasswordUser(enteredCurrentPassword, enteredNewPassword);
      signOutUser();
    } catch (error) {
      // Handle sign-in errors
      console.error("Error while signing in:", error);      
    }    
}

  return (
    <div className="cover">
      <div className="mx-auto px-4 py-16 sm:px-6 lg:px-8 flex h-screen items-center">
        <div className="mx-auto w-full sm:w-1/2 xl:w-1/3">
          <Link
            className="flex flex-shrink-0 items-center justify-center mx-auto"
            to="/"
          >
            <img src="/images/logo/logo.png" alt="Logo" className="h-20 w-48" />
          </Link>

          <form
            action=""
            className="mb-0 mt-6 space-y-4 rounded-lg shadow-lg p-4 sm:p-6 lg:p-8 bg-white"
            onSubmit={handleSubmit}
          >
            <p className="text-center text-[#1d3e7d] text-4xl font-bold">
              Reset Password
            </p>

            <div className="space-y-4">
              <div>
                <label htmlFor="currentPassword" className="sr-only">
                  Current Password
                </label>

                <div className="relative">
                  <input        
                    type="password"           
                    ref={currentPasswordRef}
                    id="currentPassword"
                    className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                    placeholder="Enter Current Password"
                    required
                  />                 
                </div>
                {/* {errorMessage && (
                <p className="text-red-500 px-1">{errorMessage}</p>
              )} */}
              </div>
            </div>

            <div>
              <label htmlFor="newPassword" className="sr-only">
                New Password
              </label>

              <div className="relative">
                <input                  
                  type="password"
                  ref={newPasswordRef}
                  id="newPassword"
                  className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                  placeholder="Enter New Password"
                  required
                />
              </div>
              {/* {errorMessage && (
                <p className="text-red-500 px-1">{errorMessage}</p>
              )} */}
            </div>

            <div>
              <label htmlFor="confirmPassword" className="sr-only">
                Confirm Password
              </label>

              <div className="relative">
                <input
                  type="password"
                  ref={confirmNewPasswordRef}
                  id="confirmPassword"
                  className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                  placeholder="Confirm New Password"
                  required
                />               
              </div>
              {/* {errorMessage && (
                <p className="text-red-500 px-1">{errorMessage}</p>
              )} */}
            </div>

            <button type="submit"
              className={`block w-full bg-indigo-600 rounded-lg active-bg-indigo-800 px-5 py-3 text-sm font-medium text-white`}>
              Reset Password
            </button>

            <Button
              onClick={() => { onLogout() }}
              className="flex items-end justify-end text-end text-indigo-700 text-sm"
            >
              <span className="text-gray-400">Logout instead? &nbsp;</span>
              Click here
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

import React from "react";

export const SendingChatCard = ({
  profileImage,
  messageRef,
  text,
  image,
  time,
  name,
  formattedTime,
}) => {
  return (
    <div className="flex justify-end items-end" ref={messageRef}>
      <div className="flex gap-3 items-end my-5 ">
        {/* Search Bar  */}

        <div className="flex flex-col items-end">
          {/* Note Box */}
          <div className="bg-gray-200 text-[#4A4A68] font-semibold flex flex-col gap-1 rounded-y-xl w-full max-w-[12rem] xxs:max-w-[44rem] xs:max-w-[30rem] md:w-full md:max-w-[44rem] h-auto p-5 rounded-2xl rounded-br-none">
            <span className="mb-2 font-mono">{name}</span>

            {/* Description and Image  */}
            {image && (
              <div className="">
                <img
                  src={`/${image}`}
                  alt="Note_Image"
                  className="object-contain w-64 h-auto rounded-2xl"
                />
              </div>
            )}
            {text ? (
              <span className="max-h-32">
                {text.split("\n").map((line, index) => (
                  <div key={index} className="break-words">
                    {line}
                    {index < text.split("\n").length - 1 && <br />}{" "}
                    {/* Add <br> after each line except the last one */}
                  </div>
                ))}
              </span>
            ) : (
              ""
            )}
          </div>

          {/* Date */}

          <span className="text-[12px] text-gray-500">Sending...</span>
        </div>

        {/* User profile Image  */}
        <span className="-translate-y-5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M22 12c0 5.52-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2s10 4.48 10 10Z"
              stroke="#101010"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="m15.71 15.18-3.1-1.85c-.54-.32-.98-1.09-.98-1.72v-4.1"
              stroke="#101010"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </span>
      </div>
    </div>
  );
};

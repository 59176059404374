import React from "react";

const PatientName = ({ patient }) => {
  const array = [patient?.fname, patient?.mname, patient?.lname];
  // console.log(array);
  const name = array.join(" ");
  window.currentPatientId = patient?.id;
  // MessengerService.setSelectedPatient(patient?.id);
  return <>{patient && name}</>;
};

export default PatientName;

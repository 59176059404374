import React from "react";
import { useState } from "react";
import { Dropdown } from "@mui/base/Dropdown";
import { MenuButton } from "@mui/base/MenuButton";
import { Menu } from "@mui/base/Menu";
import { MenuItem } from "@mui/base/MenuItem";

const SearchBar = ({
  searchTerm,
  handleSearch,
  setSearchTerm,
  handleNoteFilterClick,
  handleClearFilter,
  handleEventFilterClick,
  filterNoteType,
  filterEventType,
}) => {
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);

  const toggleSearchBar = () => setIsSearchBarVisible(!isSearchBarVisible);

  return (
    <div className="flex flex-row justify-end items-end xs:items-end w-full xs:mt-0">
      <div className="flex flex-col justify-end items-end xs:flex-row">
        <div
          className={`bg-white shadow-xl p-2 sm:p-3 lg:p-4 w-full sm:w-64 lg:w-96 rounded-xl border justify-end xs:justify-normal space-x-3 items-center h-14 mb-2 sm:mb-0 ${
            isSearchBarVisible ? "flex" : "hidden"
          }`}
        >
          <input
            type="text"
            placeholder="Search here..."
            className="w-full rounded-xl p-2 outline-none"
            value={searchTerm}
            onChange={handleSearch}
          />

          <button onClick={() => setSearchTerm("")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10ZM9.17 14.83l5.66-5.66M14.83 14.83 9.17 9.17"
                stroke="#101010"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </button>
        </div>

        <button
          className="flex items-center p-3 rounded-full bg-[#1F456E] w-fit mx-2"
          onClick={() => toggleSearchBar(!isSearchBarVisible)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-7 h-7"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M11.5 21a9.5 9.5 0 1 0 0-19 9.5 9.5 0 0 0 0 19ZM22 22l-2-2"
              stroke="#fff"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </button>
      </div>

      <div className="flex">
        <Dropdown className="flex items-center flex-wrap space-y-2 xs:space-y-0 justify-end space-x-2">
          <MenuButton className="p-3 rounded-full bg-gray-300 w-fit mr-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M3.35 2h8.9c.74 0 1.35.61 1.35 1.35v1.48c0 .54-.34 1.21-.67 1.55l-2.9 2.56c-.4.34-.67 1.01-.67 1.55v2.9c0 .4-.27.94-.61 1.15l-.94.61c-.88.54-2.09-.07-2.09-1.15v-3.57c0-.47-.27-1.08-.54-1.42l-2.56-2.7c-.34-.34-.61-.94-.61-1.35V3.41C2 2.61 2.61 2 3.35 2Z"
                stroke="#101010"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M2 12v3c0 5 2 7 7 7h6c5 0 7-2 7-7V9c0-3.12-.78-5.08-2.59-6.1-.51-.29-1.53-.51-2.46-.66M13 13h5M11 17h7"
                stroke="#101010"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </MenuButton>
          <Menu className="bg-white z-50 p-3 rounded-xl border px-5">
            <MenuItem
              className={`cursor-pointer mt-2 ${
                (filterNoteType || filterEventType) === "past"
                  ? "font-bold"
                  : ""
              }`}
              onClick={() =>
                (handleEventFilterClick || handleNoteFilterClick)("past")
              }
            >
              Past
            </MenuItem>
            <MenuItem
              className={`cursor-pointer mt-2 ${
                (filterNoteType || filterEventType) === "yesterday"
                  ? "font-bold"
                  : ""
              }`}
              onClick={() =>
                (handleEventFilterClick || handleNoteFilterClick)("yesterday")
              }
            >
              Yesterday
            </MenuItem>
            <MenuItem
              className={`cursor-pointer mt-2 ${
                (filterNoteType || filterEventType) === "today"
                  ? "font-bold"
                  : ""
              }`}
              onClick={() =>
                (handleEventFilterClick || handleNoteFilterClick)("today")
              }
            >
              Today
            </MenuItem>
            <MenuItem
              className={`cursor-pointer mt-2 ${
                (filterNoteType || filterEventType) === "isImp"
                  ? "font-bold"
                  : ""
              }`}
              onClick={() => {
                console.log("first");
                (handleEventFilterClick || handleNoteFilterClick)("isImp");
              }}
            >
              Is Important
            </MenuItem>
            <MenuItem
              className={`cursor-pointer mt-2 `}
              onClick={() => handleClearFilter()}
            >
              Clear All FIlters
            </MenuItem>
          </Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default SearchBar;

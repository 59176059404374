import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Avatar, AvatarGroup } from "@mui/material";
import { ImageBaseUrl } from "../HTTPS/Api";
import { stringAvatar } from "../hooks/convertAvatarInitital";
import { CachedUser } from "../utils/UserData";
import { useUser } from "../context/UserContext";
import {
  fetchPatientList,
  fetchRelationships,
  fetchUserDetails,
} from "../HTTPS/ApiService";
import { usePatient } from "../context/PatientContext";
import MainAreaWrapper from "../layout/MainAreaWrapper";

const MyProfile = () => {
  const { patientList, patient, setPatientList } = usePatient();
  const { userDetails, setUserDetails } = useUser();
  const array = [userDetails?.fname, userDetails?.mname, userDetails?.lname];
  const name = array.join(" ");
  const [relationshipData, setRelationsipData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchUserDetails(CachedUser()?.accessToken);
        const data = await fetchRelationships();
        const list = await fetchPatientList();
        setPatientList(list);
        setRelationsipData(data);
        setUserDetails(response);
        console.log("Response is", userDetails);
      } catch (error) {
        console.error("Error fetching User Details:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRelationshipNameById = (id) => {
    const relationship = relationshipData.find((item) => item?.id === id);
    return relationship ? relationship?.text : "NA";
  };
  return (
    <MainAreaWrapper>
      <main className="mt-28 lg:mt-10">
        <div className="h-full rounded-t-3xl bg-white">
          <div className="mx-auto  p-4 md:p-6 2xl:p-10">
            <div className="mb-6 flex flex-col gap-3 sm:flex-row sm:justify-between sm:items-center   ">
              <div className="">
                <h2 className="text-3xl font-bold text-[#1D3E7D]">{name}</h2>
                {/* <p className="text-gray-600">Manage your information</p> */}
              </div>

              {/* <div>
                <Link
                  to="/editMyProfile"
                  className="rounded-lg bg-[#ED7117] border border-black px-4 py-1.5 text-xl text-white uppercase"
                >
                  Edit
                </Link>
              </div> */}
            </div>

            {/* <!-- Content  --> */}
            <div className="">
              <div className="flex items-center gap-3">
                <AvatarGroup max={10} className="my-3">
                  <Avatar
                    {...stringAvatar(name)}
                    className="!text-base uppercase"
                    src={
                      userDetails?.profileImage
                        ? `${ImageBaseUrl}/${userDetails?.profileImage}`
                        : ""
                    }
                  />
                </AvatarGroup>
              </div>
            </div>

            {/* <!-- Details  --> */}
            <div className="pt-5">
              <h1 className="font-bold text-[#4A4A68]">Name</h1>
              <p className="" id="Profile_name">
                {name}
              </p>
            </div>

            <div className="pt-5">
              <h1 className="font-bold text-[#4A4A68]">Phone</h1>
              <p className="" id="Profile_number">
                {userDetails?.mobile}
              </p>
            </div>

            <div className="pt-5">
              <h1 className="font-bold text-[#4A4A68]">Relationship</h1>
              <p className="" id="Profile_gender">
                {patientList.map((item) => {
                  return (
                    patient?.id === item?.id && (
                      <>{getRelationshipNameById(item?.relationship)}</>
                    )
                  );
                })}
              </p>
            </div>

            {/* <div className="pt-5">
              <h1 className="font-bold text-[#4A4A68]">Address 1</h1>
              <p className="" id="Profile_location">
                {userDetails?.address1 || "NA"}
              </p>
            </div>

            <div className="pt-5">
              <h1 className="font-bold text-[#4A4A68]">Address 2</h1>
              <p className="" id="Profile_location">
                {userDetails?.address2 || "NA"}
              </p>
            </div>

            <div className="pt-5">
              <h1 className="font-bold text-[#4A4A68]">City</h1>
              <p className="" id="Profile_location">
                {userDetails?.city || "NA"}
              </p>
            </div>

            <div className="pt-5">
              <h1 className="font-bold text-[#4A4A68]">State</h1>
              <p className="" id="Profile_location">
                {userDetails?.state || "NA"}
              </p>
            </div>

            <div className="pt-5">
              <h1 className="font-bold text-[#4A4A68]">Zip Code</h1>
              <p className="" id="Profile_location">
                {userDetails?.zipcode || "NA"}
              </p>
            </div>

            <div className="pt-5">
              <h1 className="font-bold text-[#4A4A68]">Country</h1>
              <p className="" id="Profile_location">
                {userDetails?.country || "NA"}
              </p>
            </div> */}
          </div>
        </div>
      </main>
    </MainAreaWrapper>
  );
};
export default MyProfile;

import ReactDOM from "react-dom/client";
import { Routes, Route } from "react-router-dom";
import { SidebarProvider } from "./context/sidebarcontext";
import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Calendar from "./pages/Calendar";
import Journal from "./pages/Journal";
import Community from "./pages/Community";
import Contact from "./pages/Contact";
import Report from "./pages/Report";
import Setting from "./pages/setting";
import Sleep from "./pages/Sleep";
import SignIn from "./pages/Signin";
import Message from "./pages/Message";
import Reminder from "./pages/Reminder";
import BloodPressure from "./pages/BloodPressure";
import HeartRate from "./pages/Heartrate";
import OxygenLevel from "./pages/Oxygenlevel";
import BreathRate from "./pages/BreathRate";
import MyProfile from "./pages/MyProfile";

import { EventsProvider } from "./context/EventsContext";
import { UserProvider } from "./context/UserContext";
import { HashRouter } from "react-router-dom";
import { PatientProvider } from "./context/PatientContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Glucose from "./pages/Glucose";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import privateMessage from "./pages/privateMessage";
import Temperature from "./pages/Temperature";
import Weight from "./pages/Weight";
import PatientProfile from "./pages/PatientProfile";
import EditPatientProfile from "./pages/EditPatientProfile";
import EditMyProfile from "./pages/EditMyProfile";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Events from "./pages/Events";
// import MessengerService from "./servies/MessengerService";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ResetPassword from "./pages/resetPassword";
import HealthData from "./pages/HealthData";
import AllPatients from "./pages/AllPatients";
import ViewPatientDetails from "./pages/ViewPatientDetails";
import { ToastProvider } from "./context/ToastContext";
import { RecoilRoot } from "recoil";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));

window.onpopstate = function name(params) {
  // MessengerService.getGroupMesageCount();
};
window.onpushstate = function name(params) {
  // MessengerService.getGroupMesageCount();
};

root.render(
  <GoogleOAuthProvider clientId="317688044317-1a2u2njegnbb02c939t53j85f6qf9ggl.apps.googleusercontent.com">
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <UserProvider>
          <PatientProvider>
            <EventsProvider>
              <SidebarProvider>
                <ToastProvider>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <HashRouter basename="/">
                      <Routes>
                        <Route path="/signin" element={<SignIn />} />
                        <Route
                          path="/reset/password"
                          element={<ResetPassword />}
                        />
                        <Route
                          exact
                          path="/"
                          element={<ProtectedRoutes Component={App} />}
                        />
                        <Route
                          path="/calendar"
                          element={<ProtectedRoutes Component={Calendar} />}
                        />
                        <Route
                          path="/healthData"
                          element={<ProtectedRoutes Component={HealthData} />}
                        />
                        <Route
                          path="/allPatients"
                          element={<ProtectedRoutes Component={AllPatients} />}
                        />
                        <Route
                          path="/viewPatientDetails"
                          element={
                            <ProtectedRoutes Component={ViewPatientDetails} />
                          }
                        />
                        <Route
                          path="/patientProfile"
                          element={<ProtectedRoutes Component={PatientProfile} />}
                        />
                        <Route
                          path="/editPatientProfile"
                          element={
                            <ProtectedRoutes Component={EditPatientProfile} />
                          }
                        />
                        <Route
                          path="/journal"
                          element={<ProtectedRoutes Component={Journal} />}
                        />
                        <Route
                          path="/community"
                          element={<ProtectedRoutes Component={Community} />}
                        />
                        <Route
                          path="/contact"
                          element={<ProtectedRoutes Component={Contact} />}
                        />
                        <Route
                          path="/report"
                          element={<ProtectedRoutes Component={Report} />}
                        />
                        <Route
                          path="/setting"
                          element={<ProtectedRoutes Component={Setting} />}
                        />
                        <Route
                          path="/myProfile"
                          element={<ProtectedRoutes Component={MyProfile} />}
                        />
                        <Route
                          path="/editMyProfile"
                          element={<ProtectedRoutes Component={EditMyProfile} />}
                        />
                        <Route
                          path="/message"
                          element={<ProtectedRoutes Component={Message} />}
                        />
                        <Route
                          path="/privatemessage"
                          element={<ProtectedRoutes Component={privateMessage} />}
                        />
                        <Route
                          path="/reminder"
                          element={<ProtectedRoutes Component={Reminder} />}
                        />
                        <Route
                          path="/events"
                          element={<ProtectedRoutes Component={Events} />}
                        />

                        {/* charts route starts */}
                        <Route
                          path="/heart-rate"
                          element={<ProtectedRoutes Component={HeartRate} />}
                        />
                        <Route
                          path="/blood-pressure"
                          element={<ProtectedRoutes Component={BloodPressure} />}
                        />
                        <Route
                          path="/sleep"
                          element={<ProtectedRoutes Component={Sleep} />}
                        />
                        <Route
                          path="/oxygen-level"
                          element={<ProtectedRoutes Component={OxygenLevel} />}
                        />
                        <Route
                          path="/breath-rate"
                          element={<ProtectedRoutes Component={BreathRate} />}
                        />
                        <Route
                          path="/temprature"
                          element={<ProtectedRoutes Component={Temperature} />}
                        />
                        <Route
                          path="/glucose"
                          element={<ProtectedRoutes Component={Glucose} />}
                        />
                        <Route
                          path="/weight"
                          element={<ProtectedRoutes Component={Weight} />}
                        />
                        {/* charts route ends */}
                      </Routes>
                    </HashRouter>
                  </LocalizationProvider>
                </ToastProvider>
              </SidebarProvider>
            </EventsProvider>
          </PatientProvider>
        </UserProvider>
      </RecoilRoot>
    </QueryClientProvider>
  </GoogleOAuthProvider>
);

reportWebVitals();

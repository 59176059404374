import { useFormik } from "formik";
import { editPatientProfileValidation } from "../../utils/validations";
import { PatternFormat } from "react-number-format";
import { Avatar } from "@mui/material";
import { stringAvatar } from "../../hooks/convertAvatarInitital";
import {
  EditProfileApi,
  UploadPatientProfileImageApi,
} from "../../HTTPS/ApiService";
import { useNavigate } from "react-router-dom";
import useToast from "../../hooks/useToast";


function EditPatientProfileForm({ patient }) {
  const { setOpenSuccessToast, setToastMessage } = useToast();

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      fname: patient.fname,
      mname: patient.mname,
      lname: patient.lname,
      email: patient.email,
      phone: patient.phone,
      image: null,
    },
    validationSchema: editPatientProfileValidation,
    onSubmit: async (values) => {
      const updatedPatient = { ...patient, ...values };
      await EditProfileApi(updatedPatient);
      await UploadPatientProfileImageApi({
        id: patient.id,
        file: values.image,
      });
      navigate("/viewPatientDetails", {
        state: { patient: updatedPatient },
        replace: true,
      });
      setOpenSuccessToast(true);
      setToastMessage("Profile Edited Successfully");
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* fname */}
      <div className="pt-5">
        <label htmlFor="fname" className="block font-bold text-[#4A4A68]">
          First Name <span className="text-red-500">*</span>
        </label>
        <input
          id="fname"
          type="text"
          name="fname"
          value={formik.values.fname}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className="w-3/4 h-11 mt-2 text-base rounded-xl border-2 border-gray-300 px-5 py-4"
          placeholder="Enter Here"
        />
        {/* Display validation error message if exists */}
        {formik.touched.fname && formik.errors.fname && (
          <p className="text-red-500 px-1">{formik.errors.fname}</p>
        )}
      </div>

      {/* mname */}
      <div className="pt-5">
        <label htmlFor="mname" className="block font-bold text-[#4A4A68]">
          Middle Name
        </label>
        <input
          id="mname"
          type="text"
          name="mname"
          value={formik.values.mname}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className="w-3/4 h-11 mt-2 text-base rounded-xl border-2 border-gray-300 px-5 py-4"
          placeholder="Enter Here"
        />
        {/* Display validation error message if exists */}
        {formik.touched.mname && formik.errors.mname && (
          <p className="text-red-500 px-1">{formik.errors.mname}</p>
        )}
      </div>

      {/* lname */}
      <div className="pt-5">
        <label htmlFor="lname" className="block font-bold text-[#4A4A68]">
          Last Name <span className="text-red-500">*</span>
        </label>
        <input
          id="lname"
          type="text"
          name="lname"
          value={formik.values.lname}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className="w-3/4 h-11 mt-2 text-base rounded-xl border-2 border-gray-300 px-5 py-4"
          placeholder="Enter Here"
        />
        {/* Display validation error message if exists */}
        {formik.touched.lname && formik.errors.lname && (
          <p className="text-red-500 px-1">{formik.errors.lname}</p>
        )}
      </div>

      {/* email */}
      <div className="pt-5">
        <label htmlFor="email" className="block font-bold text-[#4A4A68]">
          Email 
        </label>
        <input
          id="email"
          type="text"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className="w-3/4 h-11 mt-2 text-base rounded-xl border-2 border-gray-300 px-5 py-4"
          placeholder="Enter Here"
        />
        {/* Display validation error message if exists */}
        {formik.touched.email && formik.errors.email && (
          <p className="text-red-500 px-1">{formik.errors.email}</p>
        )}
      </div>

      {/* phone */}
      <div className="pt-5">
        <label htmlFor="phone" className="block font-bold text-[#4A4A68]">
          Phone 
        </label>
        <PatternFormat
          id="phone"
          type="text"
          name="phone"
          format="(###) #### ###"
          mask="_"
          minLength={10}
          value={formik.values.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className="w-3/4 h-11 mt-2 text-base rounded-xl border-2 border-gray-300 px-5 py-4"
          placeholder="Enter Here"
        />
        {/* Display validation error message if exists */}
        {formik.touched.phone && formik.errors.phone && (
          <p className="text-red-500 px-1">{formik.errors.phone}</p>
        )}
      </div>

      <div className="pt-5">
        <label htmlFor="image" className="font-bold text-[#4A4A68]">
          Profile Image
        </label>{" "}
        <br />
        <input
          id="image"
          name="image"
          type="file"
          accept="image/*"
          onChange={(event) => {
            const selectedFile = event.target.files[0];
            formik.setFieldValue("image", selectedFile);
          }}
          className="w-3/4 text-base mt-2"
        />
        {/* Display selected image preview */}
        {formik.values.image && (
          <div className="mt-3">
            <Avatar
              {...stringAvatar(formik.values.fname + " " + formik.values.lname)}
              src={URL.createObjectURL(formik.values.image)}
              className="uppercase !text-base"
            />
          </div>
        )}
      </div>

      <button
        type="submit"
        className="rounded-lg bg-[#ED7117] px-4 py-1.5 text-xl text-white border border-[#4A4A68] mt-2"
      >
        Save
      </button>

    </form>
  );
}

export default EditPatientProfileForm;

import { useQuery } from '@tanstack/react-query';
import { usePatient } from '../context/PatientContext';
import { convertDate, convertLocalDateToUTCDateTime, getCurrentDate } from '../utils/chartUtils';


function useSevenDayQuery(cacheName, queryFn) {
  const { patient } = usePatient();

  const response = useQuery({
    queryKey: [cacheName, patient?.id],
    queryFn: () => queryFn(
      patient?.id,
      convertLocalDateToUTCDateTime(getCurrentDate()), // present date
      convertLocalDateToUTCDateTime(convertDate(new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000))) // seven days after date
    ),
    enabled: !!patient?.id,
    // refetchOnWindowFocus: false,
    // staleTime: 30 * 1000,
    // Issue 0003 - More config of react query
  });

  return response;
}

export default useSevenDayQuery
import React from "react";
import { Avatar } from "@mui/material";
import "moment-timezone";
import { formattedTime } from "../../utils/chartUtils";
import { ImageBaseUrl } from "../../HTTPS/Api";
import { stringAvatar } from "../../hooks/convertAvatarInitital";


const ReceiverChatCard = ({
  profileImage,
  messageRef,
  text,
  image,
  time,
  name,
}) => {
  return (
    <div className="flex gap-3 items-end my-5" ref={messageRef}>
      {/* Search Bar  */}

      <div className="">
        {/* User profile Image  */}
        <div className="flex items-center gap-4">
          <div className="">
            <span className="-translate-y-5">
              <Avatar
                src={profileImage ? `${ImageBaseUrl}/${profileImage}` : ""}
                {...stringAvatar(name)}
                className="uppercase !text-base !h-10 !w-10 rounded-full"
              />
            </span>
          </div>
        </div>
      </div>
      <div className="">
        {/* Note Box */}
        <div className="bg-[#4A4A68] text-white font-medium flex flex-col gap-1 rounded-y-xl w-full max-w-[12rem] xsm:max-w-[15rem] xxs:max-w-[18rem] xs:max-w-[22rem] md:w-full md:max-w-[44rem] h-auto p-5 rounded-2xl rounded-bl-none">
          <span className="mb-2 font-mono">{name}</span>
          {/* Description and Image  */}
          {image && (
            <div className="">
              <img
                src={`/${image}`}
                alt="Note_Image"
                className="object-contain w-64 h-auto rounded-2xl"
              />
            </div>
          )}
          {text ? (
            <span className="">
              {text.split("\n").map((line, index) => (
                <div key={index} className="break-words">
                  {line}
                  {index < text.split("\n").length - 1 && <br />}{" "}
                  {/* Add <br> after each line except the last one */}
                </div>
              ))}
            </span>
          ) : (
            ""
          )}
        </div>

        {/* Date */}

        <span className="text-[12px] text-gray-500">{formattedTime(time)}</span>
      </div>
    </div>
  );
};

export default ReceiverChatCard
import React from "react";

const VitalScoreDisplayCard = ({ stats, bp = false }) => {
  return (
    <div className="flex justify-center items-center">
      <img
        src="/images/sleep-stats-bg.svg"
        alt="bg"
        className="scale-110 absolute"
      />

      <div
        className={`bg-[#f9f9f9] rounded-full w-36 h-36 border-8 border-[#53C5EA] flex items-center justify-center text-3xl text-[#1D3E7D] font-bold z-0 outline-none`}
      >
        {
          bp
            ? stats ? stats : "— —" 
            : stats ? stats : "—"
        }
      </div>
    </div>
  );
};

export default VitalScoreDisplayCard;

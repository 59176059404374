import { useQuery } from "@tanstack/react-query";

import TemperatureChart from "../components/Charts/TemperatureChart";
import { usePatient } from "../context/PatientContext";
import { fetchTemperatureVital } from "../HTTPS/VitalApis";
import useLiveVitalQuery from "../hooks/useLiveVitalQuery";
import TempAddAndDisplayLiveVital from "../components/Blocks/TempAddAndDisplayLiveVital";
import MainAreaWrapper from "../layout/MainAreaWrapper";
import ChartsAndTables from "../components/Blocks/ChartsAndTables";
import TemperatureTable from "../components/Charts/TemperatureTable";
import { listVitalEventsApi } from "../HTTPS/ApiService";
import VitalEventSection from "../components/Blocks/VitalEventSection";

function Temperature() {
  const { patient } = usePatient();
  const { data: temperatureVital } = useLiveVitalQuery(
    "temperatureVital",
    fetchTemperatureVital
  );
  
  const vitalTypeId = 6  // 6 = Temperature
  const { data: temperatureEvents } = useQuery({
    queryKey: ["temperatureEvents", patient?.id],
    queryFn: () => listVitalEventsApi(patient?.id, vitalTypeId),
    enabled: !!patient?.id,
    refetchOnWindowFocus: false,
    // Issue 0003 - More config of react query
  });

  return (
    <MainAreaWrapper>
      <main className="mt-28 lg:mt-0">
        <div className="mx-auto">
          <div className="p-2 md:p-6 2xl:p-10">
            {/* <!-- ===== Content Area Start ===== --> */}
            <div className="mb-6 gap-3 bg-gradient-to-b from-[#FAFCFEBF]/70 via-[#FAFCFEBF]/70 to-transparent rounded-t-3xl px-2 py-3">
              <div className="">
                <h1 className="text-[#1D3E7D] font-bold text-4xl">
                  Temperature
                </h1>
                <h5 className="text-[#1D3E7D] font-bold">
                  {temperatureVital?.unit}
                </h5>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-between items-center p-2 md:p-6 2xl:p-10">
            <TempAddAndDisplayLiveVital temperatureVital={temperatureVital} />

            {/* Chart and Table */}
            <ChartsAndTables
              chart={<TemperatureChart events={temperatureEvents} />}
              table={<TemperatureTable />}
            />
            {/* Chart Box Starts */}
          </div>

          {/* whole vital event section */}
          <VitalEventSection
            events={temperatureEvents}
            vitalTypeId={vitalTypeId}
            vitalTitle="Temperature"
          />

        </div>
      </main>
      {/* <!-- ===== Main Content End ===== --> */}
    </MainAreaWrapper>
  );
}

export default Temperature;

import React from "react";
import { usePatient } from "../context/PatientContext";
import { listVitalEventsApi } from "../HTTPS/ApiService";
import { fetchBloodPressureVital } from "../HTTPS/VitalApis";
import BloodPressureChart from "../components/Charts/BloodPressureChart";
import useLiveVitalQuery from "../hooks/useLiveVitalQuery";
import BpAddAndDisplayLiveVital from "../components/Blocks/BpAddAndDisplayLiveVital";
import MainAreaWrapper from "../layout/MainAreaWrapper";
import ChartsAndTables from "../components/Blocks/ChartsAndTables";
import BloodPressureTable from "../components/Charts/BloodPressureTable";
import { useQuery } from "@tanstack/react-query";
import VitalEventSection from "../components/Blocks/VitalEventSection";

const BloodPressure = () => {
  const { patient } = usePatient();

  const { data: bloodPressureVital } = useLiveVitalQuery(
    "bloodPressureVital",
    fetchBloodPressureVital
  );

  const vitalTypeId = 2  // 2 = bloodPressure
  const { data: bloodPressureEvents } = useQuery({
    queryKey: ["bloodPressureEvents", patient?.id],
    queryFn: () => listVitalEventsApi(patient?.id, vitalTypeId),
    enabled: !!patient?.id,
    refetchOnWindowFocus: false,
    // Issue 0003 - More config of react query
  });

  return (
    <MainAreaWrapper>
      <main className="mt-28 lg:mt-0">
        <div className="mx-auto">
          {/* <!-- ===== Content Area Start ===== --> */}

          <div className="p-2 md:p-6 2xl:p-10">
            <div className="mb-6 gap-3 bg-gradient-to-b from-[#FAFCFEBF]/70 via-[#FAFCFEBF]/70 to-transparent rounded-t-3xl px-2 py-3">
              <div className="">
                <h1 className="text-[#1D3E7D] font-bold text-4xl">
                  Blood Pressure
                </h1>
                <h5 className="text-[#1D3E7D] font-bold">
                  {bloodPressureVital?.unit}
                </h5>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-between items-center p-2 md:p-6 2xl:p-10">
            <BpAddAndDisplayLiveVital bloodPressureVital={bloodPressureVital} />

            {/* Chart Box Starts */}

            <ChartsAndTables
              chart={<BloodPressureChart events={bloodPressureEvents} />}
              table={<BloodPressureTable />}
            />
          </div>

          {/* whole vital event section */}
          <VitalEventSection
            events={bloodPressureEvents}
            vitalTypeId={vitalTypeId}
            vitalTitle="Blood Pressure"
          />
          
        </div>
      </main>
      {/* <!-- ===== Main Content End ===== --> */}
    </MainAreaWrapper>
  );
};
export default BloodPressure;

import { Box, MenuItem, Modal, Select, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { renderRepeatContent } from "../utilities/utilitiesFunctions";

const CustomizeScheduleModal = ({
  selectedDays,
  selectedEndOption,
  selectedMonth,
  setOccurrences,
  setOpen,
  setRepeats,
  setSchedule,
  setSelectedDays,
  setSelectedEndOption,
  setSelectedMonth,
  setSwitchOption,
  schedule,
  switchOption,
  open,
}) => {
  const handleMonthClick = (index) => {
    // Check if the day is already selected
    if (selectedMonth.includes(index.toString())) {
      // If selected, remove it from the array to deselect
      setSelectedMonth((prevSelectedMonth) =>
        prevSelectedMonth
          .split(",")
          .filter((month) => month !== index.toString())
          .join(",")
      );
    } else {
      // If not selected, add it to the array to select
      setSelectedMonth((prevSelectedMonth) =>
        prevSelectedMonth ? `${prevSelectedMonth},${index}` : `${index}`
      );
    }
  };

  const handleDayClick = (index) => {
    // Check if the day is already selected
    if (selectedDays.includes(index.toString())) {
      // If selected, remove it from the array to deselect
      setSelectedDays((prevSelectedDays) =>
        prevSelectedDays
          .split(",")
          .filter((day) => day !== index.toString())
          .join(",")
      );
    } else {
      // If not selected, add it to the array to select
      setSelectedDays((prevSelectedDays) =>
        prevSelectedDays ? `${prevSelectedDays},${index}` : `${index}`
      );
    }
  };

  const currentDayOfWeek = new Date().getDay();
  const currentMonthOfYear = new Date().getMonth() + 1; // Months are zero-indexed in JavaScript

  // Function to add leading zeros to single-digit months
  const addLeadingZeros = (month) => {
    return month < 10 ? "0" + month : month.toString();
  };

  // Set default selected days for the current week
  useEffect(() => {
    if (selectedDays === "" && schedule?.repeat == 1 && schedule?.option == 1) {
      setSelectedDays(currentDayOfWeek.toString());
      // setSelectedMonth("");
    }

    if (selectedMonth === "" && schedule?.repeat == 1 && schedule?.option == 2) {
      setSelectedMonth(addLeadingZeros(currentMonthOfYear));
      // setSelectedDays("");
    }
  }, [switchOption, schedule?.repeat]);

  const toggleEndOption = (e) => {
    setSelectedEndOption(e.target.value);
  };

  const handleRepeatsChange = (e) => {
    // This doesnt allow the number to go below zero and sets max digits to 99 which is 2 digits
    const newValue = Math.max(
      0,
      Math.min(99, parseInt(e.target.value, 10) || 0)
    );

    setRepeats(newValue);
  };

  const handleOccurrencesChange = (e) => {
    // This doesnt allow the number to go below zero and sets max digits to 99 which is 2 digits
    const newValue = Math.max(
      0,
      Math.min(99, parseInt(e.target.value, 10) || 0)
    );
    setOccurrences(newValue);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen((prev) => !prev)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="flex justify-center items-center h-full px-5"
    >
      <Box className="bg-white z-50 p-5 rounded-2xl relative">
        <button
          className=" absolute top-5 right-5"
          onClick={() => setOpen((prev) => !prev)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-8 h-8"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10ZM9.17 14.83l5.66-5.66M14.83 14.83 9.17 9.17"
              stroke="#101010"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </button>

        <Typography id="modal-modal-title" variant="h6" component="h2">
          Custom recurrence
        </Typography>

        <div className="flex flex-wrap mt-5 space-x-5">
          {/* Repeats  */}
          <div className="flex space-x-4 items-center">
            <span>Repeat every:</span>
            <input
              type="number"
              defaultValue={1}
              className="p-2 bg-gray-200 rounded-lg w-14 outline-none"
              value={schedule?.repeat}
              onChange={(e) => {
                handleRepeatsChange(e);
                setSchedule((prevSchedule) => ({
                  ...prevSchedule,
                  repeat: e.target.value,
                }));
              }}
              min={1}
              max={365}
            />
          </div>

          {/* Option  */}
          <Select
            variant="outlined"
            defaultValue={0}
            value={switchOption}
            onChange={(e) => {
              setSwitchOption(e.target.value);
              setSchedule((prevSchedule) => ({
                ...prevSchedule,
                option: e.target.value,
              }));
              if (schedule?.option === 0 || 3) {
                setSelectedDays("");
                setSelectedMonth("");
              }
            }}
            className="my-2 !rounded-xl w-20 sm:w-32 bg-gray-200 h-10 !border-b-0"
            sx={{
              "& .Mui-focused, & .MuiOutlinedInput-notchedOutline": {
                border: "0px !important",
              },
            }}
          >
            <MenuItem
              className="cursor-pointer p-2 px-5 hover:bg-gray-200"
              value={0}
            >
              {schedule?.repeat > 1 ? "days" : "day"}
            </MenuItem>
            <MenuItem
              className="cursor-pointer p-2 px-5 hover:bg-gray-200"
              value={1}
            >
              {schedule?.repeat > 1 ? "weeks" : "week"}
            </MenuItem>
            <MenuItem
              className="cursor-pointer p-2 px-5 hover:bg-gray-200"
              value={2}
            >
              {schedule?.repeat > 1 ? "months" : "month"}
            </MenuItem>
            <MenuItem
              className="cursor-pointer p-2 px-5 hover:bg-gray-200"
              value={3}
            >
              {schedule?.repeat > 1 ? "years" : "year"}
            </MenuItem>
          </Select>
        </div>

        <div className="mt-5">
          {schedule?.repeat > 1
            ? ""
            : renderRepeatContent({
                switchOption,
                selectedDays,
                setSelectedDays,
                handleDayClick,
                setSelectedMonth,
                selectedMonth,
                handleMonthClick,
              })}

          <div className="">
            <span>Ends</span>

            {/* END NEVER */}
            <div className="flex space-x-2 mt-4">
              <input
                type="radio"
                id="Never"
                name="endOption"
                className="w-4 border-2"
                value="Never"
                checked={selectedEndOption === "Never"}
                onChange={(e) => {
                  toggleEndOption(e);
                  setSchedule((prevSchedule) => ({
                    ...prevSchedule,
                    endNever: true,
                    endOn: null,
                    endAfter: 1,
                  }));
                  setOccurrences(null);
                }}
              />
              <label htmlFor="Never">Never</label>
            </div>

            {/* ENDON  */}
            <div className="flex justify-between mt-4 items-center">
              <div className="flex space-x-2">
                <input
                  type="radio"
                  id="On"
                  name="endOption"
                  className="w-4 border-2"
                  value="On"
                  checked={selectedEndOption === "On"}
                  onChange={(e) => {
                    toggleEndOption(e);
                    setSchedule((prevSchedule) => ({
                      ...prevSchedule,
                      endNever: false,
                      endAfter: 1,
                    }));
                    setOccurrences(null);
                  }}
                />
                <label htmlFor="On">On</label>
              </div>

              <input
                type="date"
                className={`bg-gray-200 text-sm rounded-lg w-fit p-2 outline-none ${
                  selectedEndOption !== "On" ? "opacity-50" : ""
                }`}
                disabled={selectedEndOption !== "On"}
                defaultValue={schedule?.date}
                value={schedule?.endOn}
                onChange={(e) => {
                  setSchedule((prevSchedule) => ({
                    ...prevSchedule,
                    endOn: e.target.value,
                  }));
                }}
                min={schedule?.date}
              />
            </div>
            {/* ENDAFTER  */}
            <div className="flex justify-between mt-4 items-center">
              <div className="flex space-x-2">
                <input
                  type="radio"
                  id="After"
                  name="endOption"
                  className="w-4 border-2"
                  value="After"
                  checked={selectedEndOption === "After"}
                  onChange={(e) => {
                    toggleEndOption(e);
                    setSchedule((prevSchedule) => ({
                      ...prevSchedule,
                      endOn: null,
                      endNever: false,
                    }));
                  }}
                />
                <label htmlFor="After">After</label>
              </div>

              <div
                className={`flex space-x-2 items-center p-2 bg-gray-200 rounded-lg ${
                  selectedEndOption !== "After" ? "opacity-40" : ""
                }`}
              >
                <input
                  type="number"
                  defaultValue={1}
                  className="outline-none w-10 bg-transparent text-sm"
                  disabled={selectedEndOption !== "After"}
                  value={schedule?.endAfter}
                  onChange={(e) => {
                    handleOccurrencesChange(e);
                    setSchedule((prevSchedule) => ({
                      ...prevSchedule,
                      endAfter: e.target.value,
                    }));
                  }}
                  min={1}
                  max={365}
                />
                <span className="">occurences</span>
              </div>
            </div>
            <div className="flex justify-end items-end mt-7">
              <button
                className="bg-indigo-700 active:bg-indigo-800 p-2 px-5 rounded-xl text-white"
                onClick={() => setOpen((prev) => !prev)}
              >
                Okay
              </button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default CustomizeScheduleModal;

import { useQuery } from "@tanstack/react-query";

import { usePatient } from "../context/PatientContext";
import VitalScoreDisplayCard from "../components/VitalScoreDisplayCard";
import BreathRateChart from "../components/Charts/BreathRateChart";
import { fetchRespiratoryRateVital } from "../HTTPS/VitalApis";
import useLiveVitalQuery from "../hooks/useLiveVitalQuery";
import { formatDate, formatTime, roundToOneDecimal } from "../utils/chartUtils";
import MainAreaWrapper from "../layout/MainAreaWrapper";
import VitalEventSection from "../components/Blocks/VitalEventSection";
import { listVitalEventsApi } from "../HTTPS/ApiService";
import ChartsAndTables from "../components/Blocks/ChartsAndTables";
import RangeBarTable from "../components/Charts/RangeBarTable";

function BreathRate() {
  const { patient } = usePatient();

  const { data: respiratoryRateVital } = useLiveVitalQuery(
    "respiratoryRateVital",
    fetchRespiratoryRateVital
  );

  const vitalTypeId = 5; // 5 = RespiratoryRate
  const { data: respiratoryRateEvents } = useQuery({
    queryKey: ["respiratoryRateEvents", patient?.id],
    queryFn: () => listVitalEventsApi(patient?.id, vitalTypeId),
    enabled: !!patient?.id,
    refetchOnWindowFocus: false,
    // Issue 0003 - More config of react query
  });
  return (
    <MainAreaWrapper>
      <main className="mt-28 lg:mt-0">
        <div className="mx-auto ">
          <div className="p-2 md:p-6 2xl:p-10">
            {/* <!-- ===== Content Area Start ===== --> */}
            <div className="mb-6 gap-3 bg-gradient-to-b from-[#FAFCFEBF]/70 via-[#FAFCFEBF]/70 to-transparent rounded-t-3xl px-2 py-3">
              <div className="">
                <h1 className="text-[#1D3E7D] font-bold text-4xl">
                  Breathing Rate
                </h1>
                <h5 className="text-[#1D3E7D] font-bold">
                  {respiratoryRateVital?.unit}
                </h5>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-between items-center p-2 md:p-6 2xl:p-10">
            <div className="flex justify-center items-center flex-wrap space-x-5 w-96">
              {/* vital score display */}
              <VitalScoreDisplayCard
                stats={roundToOneDecimal(respiratoryRateVital?.value)}
              />
              <p className="text-white font-semibold text-center text-sm mt-4">
                {respiratoryRateVital?.date
                  ? `Updated on ${formatDate(
                    respiratoryRateVital?.date
                  )} at ${formatTime(respiratoryRateVital?.date)}`
                  : null}
              </p>
            </div>

            {/* Chart Box Starts */}
            <ChartsAndTables
              chart={<BreathRateChart events={respiratoryRateEvents} />}
              table={<RangeBarTable />}
            />
            
          </div>
          {/* Chart Box Ends */}

          {/* whole vital event section */}
          <VitalEventSection
            events={respiratoryRateEvents}
            vitalTypeId={vitalTypeId}
            vitalTitle="Breathing Rate"
          />          
        </div>
      </main>
      {/* <!-- ===== Main Content End ===== --> */}
    </MainAreaWrapper>
  );
}

export default BreathRate;

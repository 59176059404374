/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { usePatient } from "../context/PatientContext";
import { listScheduleDayApi } from "../HTTPS/ApiService";
import { CachedUserDetails } from "../utils/UserData";

const useSchedules = (
  selectedDate = null,
  selectedInterval = null,
  repeats = null,
  switchOption = null,
  occurrences = null,
  selectedDays = "",
  selectedMonth = "",
  setSelectedDays,
  setSelectedMonth
) => {
  const { patient } = usePatient();

  const getCurrentDate = () => {
    const today = new Date();
    const [year, month, day] = [
      today.getFullYear(),
      String(today.getMonth() + 1).padStart(2, "0"),
      String(today.getDate()).padStart(2, "0"),
    ];
    return `${year}-${month}-${day}`;
  };

  const [currentDate, setCurrentDate] = useState(getCurrentDate());
  const [currentTime, setCurrentTime] = useState(
    new Date().toTimeString().split(" ")[0].slice(0, 5)
  );

  // Function to update date and time when the modal opens
  const updateDateTime = () => {
    setCurrentDate(getCurrentDate());
    setCurrentTime(new Date().toTimeString().split(" ")[0].slice(0, 5));
  };

  const [schedule, setSchedule] = useState({
    id: 0,
    name: "",
    date: selectedDate,
    time: currentTime,
    interval: selectedInterval,
    repeat: repeats,
    option: switchOption,
    endNever: false,
    endOn: null,
    endAfter: occurrences,
    repeatOn:
      selectedDays || selectedMonth ? selectedDays || selectedMonth : "",
    assignies: [],
    recipientId: patient?.id,
    createdBy: CachedUserDetails()?.id,
  });

  useEffect(() => {
    updateDateTime();
  }, [currentDate, currentTime]);

  useEffect(() => {
    if (selectedMonth && schedule?.repeat != 1) {
      setSchedule((prev) => ({
        ...prev,
        repeatOn: "",
      }));
      setSelectedDays("");
    }

    if (setSelectedDays && schedule?.repeat != 1) {
      setSchedule((prev) => ({
        ...prev,
        repeatOn: "",
      }));
      setSelectedMonth("");
    }

  }, [schedule?.repeatOn, schedule?.repeat]);
  const [scheduleList, setScheduleList] = useState([]);

  //   console.log("Selected Date in Schedule", selectedDate);

  const fetchSchedules = async () => {
    if (patient) {
      try {
        const fetchedSchedules = await listScheduleDayApi(
          patient?.id,
          selectedDate
        );
        setScheduleList(fetchedSchedules);
        // console.log("Date", selectedDate);
      } catch (error) {
        // Handle the error
        // console.log("Date", selectedDate);
        console.log("Failed to fetch Schedules:", error);
      }
    }
  };

  useEffect(() => {
    fetchSchedules();
  }, [patient, selectedDate]);

  return {
    schedule,
    setSchedule,
    scheduleList,
    setScheduleList,
    fetchSchedules,
  };
};

export default useSchedules;

import React from "react";

function AddEventPlusIconBtn() {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_249_1354)">
        <circle cx="40" cy="36" r="36" fill="#ED7117" />
        <path
          d="M52 38.2588H42.4941V48H37.5059V38.2588H28V33.7412H37.5059V24H42.4941V33.7412H52V38.2588Z"
          fill="#FAFCFE"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_249_1354"
          x="0"
          y="0"
          width="80"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_249_1354"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_249_1354"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default AddEventPlusIconBtn;

import * as React from "react";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { endDateWithTimeSelector, startDateState, startDateWithTimeSelector } from "../../../recoil/report/dateRangeState";

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "isSelected" && prop !== "isHovered",
})(({ theme, isSelected, isHovered, day }) => ({
  borderRadius: 0,
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(isHovered && {
    backgroundColor: theme.palette.primary[theme.palette.mode],
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary[theme.palette.mode],
    },
  }),
  ...(day.day() === 0 && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(day.day() === 6 && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
}));

const isInSameWeek = (dayA, dayB) => {
  if (dayB == null) {
    return false;
  }

  return dayA.isSame(dayB, "week");
};

function Day(props) {
  const { day, selectedDay, hoveredDay, ...other } = props;

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isSelected={isInSameWeek(day, selectedDay)}
      isHovered={isInSameWeek(day, hoveredDay)}
    />
  );
}

export default function WeekPicker() {
  const startDate = useRecoilValue(startDateState);
  const setStartDateTime = useSetRecoilState(startDateWithTimeSelector)
  const setEndDateTime = useSetRecoilState(endDateWithTimeSelector)

  React.useEffect(() => {
    setStartDateTime(moment(startDate).startOf('week').format('YYYY-MM-DD'))
    setEndDateTime(moment(startDate).endOf('week').format('YYYY-MM-DD'))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateCalendar
        value={moment(startDate)}
        onChange={(newValue) => {
          // setValue(newValue)
          setStartDateTime(newValue.startOf('week').format('YYYY-MM-DD'))
          setEndDateTime(newValue.endOf('week').format('YYYY-MM-DD'))
        }}
        showDaysOutsideCurrentMonth
        displayWeekNumber
        slots={{ day: Day }}
        slotProps={{
          day: (ownerState) => ({
            selectedDay: moment(startDate),
            // hoveredDay,
            // onPointerEnter: () => setHoveredDay(ownerState.day),
            // onPointerLeave: () => setHoveredDay(null),
          }),
        }}
      />
    </LocalizationProvider>
  );
}

import React, { useState } from "react";
import moment from "moment/moment";
import { Avatar } from "@mui/material";

import { ImageBaseUrl } from "../../HTTPS/Api";
import { stringAvatar } from "../../hooks/convertAvatarInitital";
import { getCorrectDateTimeString, getDayFromDate, getMonthFromDate, getTimeFromDate } from "../../utils/chartUtils";

function JournalEvent({ item }) {
  const [clamp, setClamp] = useState(false);

  return (
    <>
      <div className="flex space-x-2 sm:space-x-4 w-full h-full bg-[#001640] mt-2 rounded-xl">
        <div className="bg-[#0492C2] flex flex-col p-2 rounded-xl justify-center items-center min-w-[5rem]">
          <span className="text-white text-xl sm:text-2xl font-bold">
            {getDayFromDate(getCorrectDateTimeString(item.evDate, item.evTime))}
          </span>
          <span className="text-white text-sm">
            {getMonthFromDate(getCorrectDateTimeString(item.evDate, item.evTime))}
          </span>
          <span className="text-white text-[13px]">
            {getTimeFromDate(getCorrectDateTimeString(item.evDate, item.evTime))}
          </span>
        </div>

        <div className="flex flex-wrap sm:flex-nowrap justify-between items-center w-full">
          {/* User Event Text And Image  */}
          <div className="py-3">
            <div className="flex items-center space-x-2 pt-3 pb-2">
              <span className="min-w-max">
                <Avatar
                  src={item?.profileImage ? `${ImageBaseUrl}/${item?.profileImage}` : ""}
                  {...stringAvatar(item?.user)}
                  className="w-10 h-10 rounded-full uppercase !text-base"
                />
              </span>

              <div className="">
                {/* Event Category  */}
                <span className="font-bold text-white sm:text-base">
                  {item.type}
                </span>

                {/* Event Desc  */}
                <span
                  className={`text-start text-[13px] sm:text-base line-clamp-2 text-white max-w-[5rem] xs:max-w-[10rem] sm:max-w-xl md:max-w-full after:text-red-500 ${clamp ? "line-clamp-none" : ""
                    }`}
                  onClick={() => setClamp((prev) => !prev)}
                >
                  {item.text}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Date */}
      <div className="flex items-center space-x-2 mb-2 ">
        <span className="text-black text-[12px]">{item?.user}</span>
        <span className="text-[12px] text-gray-500">
          {formattedTimeStamp(item?.createdDate)}
        </span>
      </div>
    </>
  );
}

const formattedTimeStamp = (time) => {
  const currentTime = moment();
  const parsedTime = moment.utc(time); // Parse time as UTC

  const userTimezone = moment.tz.guess(); // Get user's timezone

  // Convert the parsed time to the user's timezone
  parsedTime.tz(userTimezone);

  if (parsedTime.isSame(currentTime, "day")) {
    // If the timestamp is from today, display as "Now"
    return parsedTime.format("MMMM D, h:mmA");
  } else if (parsedTime.isSame(currentTime, "year")) {
    // If the timestamp is from this year, display the time
    return parsedTime.format("MMMM D, h:mmA");
  } else {
    // If the timestamp is from a different year, display the full date and time
    return parsedTime.format("MMMM D, YYYY, h:mmA");
  }
};

export default JournalEvent;

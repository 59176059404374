import React from 'react'
import { Modal } from '@mui/material';

function ReuseableModel({children, open, setOpen}) {
  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="flex justify-center items-center h-full"
      >
        {children}
      </Modal>
    </>
  )
}

export default ReuseableModel
import React from "react";

const PrevButton = ({ prevStep, text = 'Previous' }) => {
  return (
    <button
      onClick={prevStep}
      className="bg-[#ED7117] flex items-center justify-center p-2 px-5 text-white font-medium rounded-lg h-12 uppercase text-sm xs:text-lg mt-5 border border-black"
    >
      {text}
    </button>
  );
};

export default PrevButton;

import React from 'react'

function ReportWrapper({ data, children }) {
  return (
    <div className="mt-8">
      <h3 className="text-2xl font-bold text-[#151E3D99]/[95%] mb-3">{data.label}</h3>
      {
        (data?.res?.length === 0)
        ? <pre>No data available</pre> 
        : children
      }
    </div>
  )
}

export default ReportWrapper
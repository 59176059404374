import React from "react";
import { useRecoilValue } from "recoil";
import { stepState } from "../../../recoil/report/stepState";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

function RenderSteps() {
  const currentStep = useRecoilValue(stepState);

  switch (currentStep) {
    case 1:
      return <Step1 />;
    case 2:
      return <Step2 />;
    case 3:
      return <Step3 />;
    default:
      return null;
  }
}

export default RenderSteps;

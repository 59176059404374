import React, { useState } from "react";
import parse from "html-react-parser";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { EditProfileApi } from "../../HTTPS/ApiService";
import EditIcon from "../Icons/EditIcon";
import useToast from "../../hooks/useToast";
import { useUser } from "../../context/UserContext";

function ViewPatientDetailsComp({ patient }) {
  const { user, userDetails } = useUser();
  const [isEditing, setIsEditing] = useState(false);
  const { setOpenSuccessToast, setOpenErrorSuccessToast, setToastMessage } =
    useToast();

  const labels = ["about", "routines", "preferences", "medications"];

  const options = Object.entries(patient)
    .filter(([key, value]) => labels.includes(key))
    .map(([key, value], index) => ({
      value: index + 1, // You can adjust the value as needed
      label: key.replace(/\b\w/g, (char) => char.toUpperCase()), // Convert key to title case
      content: value || "No information available",
    }));

  const [selectedValue, setSelectedValue] = useState(options[0].value);
  const [content, setContent] = useState(options[0].content);

  const handleShowDetails = (e) => {
    setSelectedValue(e.target.value);
    setContent(
      options.find((option) => option.value === e.target.value)?.content
    );
  };

  const handleUpdateProfile = async () => {
    const label = options.find((o) => o.value === selectedValue).label;
    patient[label.toLowerCase()] = content;
    await EditProfileApi(patient);
    setOpenSuccessToast(true);
    setToastMessage("Information Saved Successfully");
  };

  return (
    <>
      <FormControl fullWidth className="mt-5">
        <InputLabel id="demo-simple-select-label">Details</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedValue}
          label="Details"
          onChange={handleShowDetails}
        >
          {options.map((item) => (
            <MenuItem value={item.value}>{item.label}</MenuItem>
          ))}
        </Select>
      </FormControl>

      {isEditing ? (
        <div className="mt-5 h-full">
          <ReactQuill value={content} onChange={setContent} className="z-50" />
          <div className="flex space-x-2 items-center mt-3">
            <button
              // type="submit"
              className="bg-[#ED7117] flex items-center justify-center p-2 px-3 text-white font-medium rounded-lg h-12 uppercase"
              onClick={() => {
                handleUpdateProfile();
                setIsEditing(false);
              }}
            >
              Save
            </button>
            <button
              onClick={() => {
                setIsEditing(false);
                setContent(
                  () => options.find((o) => o.value === selectedValue).content
                );
              }}
              className="text-[#ff0000]"
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className="mt-5 h-full">
          <div className="font-medium text-[#1D3E7D] RTA">{parse(content)}</div>

          <button
            onClick={() => setIsEditing(true)}
            disabled={user?.role === "Viewer" || user?.role === "Kare Giver"}
            className={`mt-3 ${
              user?.role === "Viewer" || user?.role === "Kare Giver"
                ? "!opacity-30"
                : ""
            }`}
          >
            <EditIcon />
          </button>
        </div>
      )}
    </>
  );
}

export default ViewPatientDetailsComp;

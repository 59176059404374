import MonthSelector from "../MonthSelector";
import WeekDaySelector from "../WeekDaySelector";

const renderRepeatContent = ({
  switchOption,
  selectedDays,
  setSelectedDays,
  handleDayClick,
  setSelectedMonth,
  selectedMonth,
  handleMonthClick,
}) => {
  switch (switchOption) {
    case 0:
      setSelectedDays("");
      setSelectedMonth("");
      return;
    case 1:
      return (
        <div className="flex flex-col mb-5">
          <span className="mb-2">Repeat On:</span>
          <WeekDaySelector
            selectedDays={selectedDays}
            setSelectedDays={setSelectedDays}
            handleDayClick={handleDayClick}
            setSelectedMonth={setSelectedMonth}
          />
        </div>
      );
    case 2:
      return (
        <div className="flex flex-col mb-5">
          <span className="mb-2">Repeat On:</span>
          <MonthSelector
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            handleMonthClick={handleMonthClick}
            setSelectedDays={setSelectedDays}
          />
        </div>
      );
    case 3:
      setSelectedDays("");
      setSelectedMonth("");
      return;
    default:
      return 0;
  }
};

const disablePastDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  let month = today.getMonth() + 1; // Months are zero-based
  let day = today.getDate();

  // Add leading zero if month or day is a single digit
  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;

  return `${year}-${month}-${day}`;
};

// ALL EXPORTS HERE
export { renderRepeatContent, disablePastDate };

import React from "react";

const StepStatus = ({ currentStatus, currentTitle }) => {
  return (
    <h1 className="text-customLightBlue text-2xl font-bold my-4">
      Step {currentStatus} of 2: {currentTitle}
    </h1>
  );
};

export default StepStatus;

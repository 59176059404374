import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { fetchCommunity } from "../../HTTPS/ApiService";
import { usePatient } from "../../context/PatientContext";
import UncheckedIcon from "../Icons/UncheckedIcon";
import CheckedIcon from "../Icons/CheckedIcon";

const CommunitySelect = ({ schedule, setSchedule }) => {
  const [community, setCommunity] = useState([]);
  const [communityLoaded, setCommunityLoaded] = useState(false);
  const [selectedAssignees, setSelectedAssignees] = useState([]);

  const { patient } = usePatient();

  const fetchAssignees = async () => {
    try {
      if (patient) {
        const response = await fetchCommunity(patient?.id);
        const sortedCommunity = response.sort((a, b) =>
          a.name.localeCompare(b.name)
        ); // Sort the community array by name
        setCommunity(sortedCommunity);

        if (!schedule.assignies || schedule.assignies.length == 0) {
          // Set all community members as default assignees
          const allCommunityMemberIds = sortedCommunity.map(
            (member) => member.userId
          );
          setSchedule((prevSchedule) => ({
            ...prevSchedule,
            assignies: allCommunityMemberIds,
          }));
        }
        else {
          var selected = sortedCommunity.filter(x => schedule.assignies.includes(x.userId));

          setSelectedAssignees(selected) 
        }
        setCommunityLoaded(true);
        console.log("Assignees are", patient?.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (patient) {
      fetchAssignees();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);

  const handleSelectChange = (_, selectedOptions) => {
    if (selectedOptions.length === 0) {
      // If no options selected, set all community members as default assignees
      const allCommunityMemberIds = community.map((member) => member.userId);
      setSchedule((prevSchedule) => ({
        ...prevSchedule,
        assignies: allCommunityMemberIds,
      }));
    } else {
      // Assuming selectedOptions is an array of objects and you want to store an array of ids in assignies
      const selectedIds = selectedOptions.map((option) => option.userId);
      console.log(selectedIds);

      setSchedule((prevSchedule) => ({
        ...prevSchedule,
        assignies: selectedIds,
      }));
    }
  };

  // const selectedAssignees = community.map((item) => item?.name);
  // console.log("SELECTEDDD", selectedAssignees);

  // useEffect(() => {
  //   console.log("Assignies are", schedule?.assignies);
  // }, [schedule]);
  return (
    <>
      {communityLoaded && <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={community}
        disableCloseOnSelect
        defaultValue={selectedAssignees}
        getOptionLabel={(option) => option?.name}
        onChange={(event, selectedOptions) => {
          handleSelectChange(event, selectedOptions);
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={UncheckedIcon}
              checkedIcon={CheckedIcon}
              style={{ marginRight: 2 }}
              checked={selected}
            />
            {option?.name}
          </li>
        )}
        renderInput={(params) => {
          // console.log(params);
          return (
            <TextField
              {...params}
              className=""
              placeholder={
                !params.InputProps?.startAdornment ? "Assignees" : ""
              }
            />
          );
        }}
        className="my-2 !rounded-xl xs:w-96 md:w-80 lg:w-96 bg-white text-black !h-auto !flex !flex-row !text-sm"
        sx={{
          "& .Mui-focused, & .MuiOutlinedInput-notchedOutline": {
            border: "0px !important",
          },
        }}
        limitTags={2}
      />}
    </>
  );
};

export default CommunitySelect;

import React from "react";
import { Link } from "react-router-dom";

import DashVitalCard from "../UI/DashVitalCard";
import useLiveVitalQuery from "../../hooks/useLiveVitalQuery";
import * as API from "../../HTTPS/VitalApis";
import { usePatient } from "../../context/PatientContext";
import { useQuery } from "@tanstack/react-query";
import { listScheduleDayApi } from "../../HTTPS/ApiService";

function DashboardCards() {
  const { patient } = usePatient();

  const { data: eventsCount } = useQuery({
    queryKey: ["eventsCount", patient?.id],
    queryFn: () =>
      API.fetchEventsCount(
        patient?.id,
        new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000), // seven days before date
        new Date() // present date
      ),
    enabled: !!patient?.id,
    // refetchOnWindowFocus: false,
    // staleTime: 5 * 1000,
  });

  // Rube api calling Start; should get removed in future or atleast
  const { data: CalendarScheduleList } = useQuery({
    queryKey: ["CalendarScheduleList", patient?.id],
    queryFn: () =>
      listScheduleDayApi(
        patient?.id,
        new Date(), // present date
        new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000) // seven days after date
      ),
    enabled: !!patient?.id,
    // refetchOnWindowFocus: false,
    // staleTime: 30 * 1000,
  });
  // Rube api calling END

  // Issue 0002 - We can create a batch request using useQueries hook
  const { data: heartrateVital } = useLiveVitalQuery(
    "heartrateVital",
    API.fetchHeartrateVital
  );
  const { data: bloodPressureVital } = useLiveVitalQuery(
    "bloodPressureVital",
    API.fetchBloodPressureVital
  );
  const { data: sleepVital } = useLiveVitalQuery(
    "sleepVital",
    API.fetchSleepVital
  );
  const { data: oxygenVital } = useLiveVitalQuery(
    "oxygenVital",
    API.fetchOxygenVital
  );
  const { data: respiratoryRateVital } = useLiveVitalQuery(
    "respiratoryRateVital",
    API.fetchRespiratoryRateVital
  );
  const { data: temperatureVital } = useLiveVitalQuery(
    "temperatureVital",
    API.fetchTemperatureVital
  );
  const { data: glucoseVital } = useLiveVitalQuery(
    "glucoseVital",
    API.fetchGlucoseVital
  );
  const { data: weigthVital } = useLiveVitalQuery(
    "weigthVital",
    API.fetchWeigthVital
  );

  return (
    <>
      {/* reminder */}
      {/* <Link
        to="/reminder"
        className="bg-white rounded-3xl w-40 sm:w-52 sm:h-40 p-3 shadow-[0_10px_10px_rgb(0,0,0,0.2)]"
      >
        <DashVitalCard
          title="Reminders"
          icon="/icons/icon-10.svg"
          liveVital={{
            value: CalendarScheduleList?.length,
          }}
          text={false}
        />
      </Link> */}

      {/* events */}
      {/* <Link
        to="/events"
        className="bg-white rounded-3xl w-40 sm:w-52 sm:h-40 p-3 shadow-[0_10px_10px_rgb(0,0,0,0.2)]"
      >
        <DashVitalCard
          title="Events"
          icon="/icons/icon-9.svg"
          liveVital={{
            value: eventsCount?.value,
          }}
          text={false}
        />
      </Link> */}

      {/* heartrate */}
      <Link
        to="/heart-rate"
        className="bg-white rounded-3xl w-40 sm:w-52 sm:h-40 p-3 shadow-[0_10px_10px_rgb(0,0,0,0.2)]"
      >
        <DashVitalCard
          title="Heart Rate"
          icon="/icons/icon-1.svg"
          liveVital={heartrateVital}
        />
      </Link>

      {/* blood pressure */}
      <Link
        to="/blood-pressure"
        className="bg-white rounded-3xl w-40 sm:w-52 sm:h-40 p-3 shadow-[0_10px_10px_rgb(0,0,0,0.2)]"
      >
        <DashVitalCard
          title="Blood Pressure"
          icon="/icons/icon-2.svg"
          liveVital={bloodPressureVital}
          bp={true}
        />
      </Link>

      {/* sleep */}
      <Link
        to="/sleep"
        className="bg-white rounded-3xl w-40 sm:w-52 sm:h-40 p-3 shadow-[0_10px_10px_rgb(0,0,0,0.2)]"
      >
        <DashVitalCard
          title="Sleep"
          icon="/icons/icon-8.svg"
          liveVital={sleepVital}
        />
      </Link>

      {/* oxygen level */}
      <Link
        to="/oxygen-level"
        className="bg-white rounded-3xl w-40 sm:w-52 sm:h-40 p-3 shadow-[0_10px_10px_rgb(0,0,0,0.2)]"
      >
        <DashVitalCard
          title="Oxygen Level"
          icon="/icons/icon-3.svg"
          liveVital={oxygenVital}
        />
      </Link>

      {/* breath rate */}
      <Link
        to="/breath-rate"
        className="bg-white rounded-3xl w-40 sm:w-52 sm:h-40 p-3 shadow-[0_10px_10px_rgb(0,0,0,0.2)]"
      >
        <DashVitalCard
          title="Breathing Rate"
          icon="/icons/icon-4.svg"
          liveVital={respiratoryRateVital}
        />
      </Link>

      {/* temprature */}
      <Link
        to="/temprature"
        className="bg-white rounded-3xl w-40 sm:w-52 sm:h-40 p-3 shadow-[0_10px_10px_rgb(0,0,0,0.2)]"
      >
        <DashVitalCard
          title="Temperature"
          icon="/icons/icon-5.svg"
          liveVital={temperatureVital}
        />
      </Link>

      {/* glucose */}
      <Link
        to="/glucose"
        className="bg-white rounded-3xl w-40 sm:w-52 sm:h-40 p-3 shadow-[0_10px_10px_rgb(0,0,0,0.2)]"
      >
        <DashVitalCard
          title="Glucose"
          icon="/icons/icon-6.svg"
          liveVital={glucoseVital}
        />
      </Link>

      {/* weight */}
      <Link
        to="/weight"
        className="bg-white rounded-3xl w-40 sm:w-52 sm:h-40 p-3 shadow-[0_10px_10px_rgb(0,0,0,0.2)]"
      >
        <DashVitalCard
          title="Weight"
          icon="/icons/icon-7.svg"
          liveVital={weigthVital}
        />
      </Link>
    </>
  );
}

export default DashboardCards;

import { atom, selector } from 'recoil';
import { checkListRaw } from '../../constant';


export const checkListGrouped = checkListRaw.reduce((acc, { category, label, api }) => {
  if (!acc[category]) {
    acc[category] = [];
  }
  acc[category].push({ label, api });
  return acc;
}, {});

// =======================================

export const checkListState = atom({
  key: 'checkListState',
  default: Object.keys(checkListGrouped).reduce((acc, key) => { // emptying the array
    acc[key] = [];
    return acc;
  }, {}),
});

export const allCheckedSelector = selector({
  key: 'allCheckedSelector',
  get: ({ get }) => {
    const checkList = get(checkListState);
    return Object.keys(checkList).every(
      categoryItems =>
        checkList[categoryItems].length === checkListGrouped[categoryItems].length
    );
  },
  set: ({ set }, newValue) => {
    const checkList = checkListGrouped;
    const updatedCheckList = Object.keys(checkList).reduce((acc, category) => {
      acc[category] = newValue
        ? checkList[category].map(item => item.label)
        : [];
      return acc;
    }, {});
    set(checkListState, updatedCheckList);
  },
});


export const singleCheckedSelector = selector({
  key: 'singleCheckedSelector',
  get: ({ get }) => get(checkListState),
  set: ({ set, get }, { category, label }) => {
    const checkList = get(checkListState);
    const isChecked = checkList[category].includes(label);
    const updatedCategoryItems = isChecked
      ? checkList[category].filter(item => item !== label)
      : [...checkList[category], label];
    set(checkListState, { ...checkList, [category]: updatedCategoryItems });
  }
})
import React from "react";
import { disablePastDate } from "../utilities/utilitiesFunctions";
import { MenuItem, Select } from "@mui/material";
import CommunitySelect from "../CommunitySelect";
import moment from "moment";

const Inputs = ({
  selectedSchedule,
  schedule,
  setSchedule,
  setSelectedInterval,
  dateError,
  setHasEdited,
}) => {
  return (
    <div className="">
      <div className="flex items-center justify-between sm:justify-start flex-wrap w-full">
        <div className="flex flex-col w-full sm:w-auto mr-2 relative">
          <label htmlFor="Date" className="text-white">
            Start Date
          </label>

          <input
            type="date"
            id="Date"
            value={schedule?.date}
            onInput={(e) => {
              setHasEdited(true);
              setSchedule((prevSchedule) => ({
                ...prevSchedule,
                date: e.target.value,
              }));
            }}
            className={`border p-2 py-3.5 my-2 rounded-xl xs:w-96 md:w-80 lg:w-96 outline-none`}
            min={disablePastDate()}
            required
          />
        </div>

        <div className="flex flex-col w-full sm:w-auto mr-2">
          <label htmlFor="Time" className="text-white">
            Time
          </label>

          <input
            type="time"
            id="Time"
            value={schedule?.time}
            onInput={(e) => {
              setSchedule((prevSchedule) => ({
                ...prevSchedule,
                time: e.target.value,
              }));
            }}
            className="border p-2 py-3.5 my-2 rounded-xl xs:w-96 md:w-80 lg:w-96 outline-none"
            required
          />
        </div>

        {/* INTERVAL  */}
        <div className="hidden flex-col w-full sm:w-auto mr-2">
          <label htmlFor="Time" className="text-white">
            Interval
          </label>
          <Select
            value={schedule?.interval || 0}
            onChange={(e, v) => {
              setSelectedInterval(e.target.value);
              setSchedule((prevSchedule) => ({
                ...prevSchedule,
                interval: e.target.value,
              }));
            }}
            sx={{
              "& .Mui-focused, & .MuiOutlinedInput-notchedOutline": {
                border: "0px !important",
              },
            }}
            className="my-2 !rounded-xl xs:w-96 md:w-80 lg:w-96 bg-white text-black !h-auto"
          >
            <MenuItem
              className="cursor-pointer p-2 px-5 hover:bg-gray-200"
              value={0}
            >
              No Interval
            </MenuItem>
            <MenuItem
              className="cursor-pointer p-2 px-5 hover:bg-gray-200"
              value={1}
            >
              Every 2 hours
            </MenuItem>
            <MenuItem
              className="cursor-pointer p-2 px-5 hover:bg-gray-200"
              value={2}
            >
              Every 3 hours
            </MenuItem>
            <MenuItem
              className="cursor-pointer p-2 px-5 hover:bg-gray-200"
              value={3}
            >
              Every 4 hours
            </MenuItem>
            <MenuItem
              className="cursor-pointer p-2 px-5 hover:bg-gray-200"
              value={4}
            >
              Every 5 hours
            </MenuItem>
            <MenuItem
              className="cursor-pointer p-2 px-5 hover:bg-gray-200"
              value={5}
            >
              Every 6 hours
            </MenuItem>
            <MenuItem
              className="cursor-pointer p-2 px-5 hover:bg-gray-200"
              value={6}
            >
              Every 7 hours
            </MenuItem>
            <MenuItem
              className="cursor-pointer p-2 px-5 hover:bg-gray-200"
              value={7}
            >
              Every 8 hours
            </MenuItem>
          </Select>
        </div>

        {/* ASSIGNEES  */}
        <div className="flex flex-col w-full sm:w-auto mr-2">
          <label htmlFor="Time" className="text-white">
            Assign Notification
          </label>
          <div className="flex flex-col relative">
            <CommunitySelect schedule={schedule} setSchedule={setSchedule} />

            {/* {!schedule?.assignies.length && (
      <p className="text-gray-100 font-semibold mt-2 absolute -bottom-5">
        Pleaese Select atleast 1 Assignee
      </p>
    )} */}
          </div>
        </div>
      </div>

      {dateError && (
        <span className="text-gray-100 text-lg font-semibold">{dateError}</span>
      )}
    </div>
  );
};

export default Inputs;

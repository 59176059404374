import React from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';

function MainAreaWrapper({ children, observationsRef = null }) {
  return (
    <div className="cover">
      <div className="flex h-screen overflow-hidden">
        <Sidebar />
        {/* NavBar wapper */}
        <div ref={observationsRef} className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden h-screen">
          <Navbar />
          {children}
        </div>
      </div>
    </div>
  );
}

export default MainAreaWrapper;